import React from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useGetSearchParams } from 'hooks/useGetSearchParams';
import { Search } from 'modules/search';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useSearchResultDataQuery } from 'services/search';
import { MeasureFilterViewContextProvider } from 'modules/app/MeasureFilterViewContext';

const SearchPage = () => {
  const intl = useIntl();
  const { partner, reporterId, productId } = useGetSearchParams();

  const { data, isLoading, isFetching, isError, isSuccess } =
    useSearchResultDataQuery(
      partner && reporterId && productId
        ? { partner, reporterId, productId }
        : skipToken
    );

  return (
    <>
      <Helmet
        title={intl.formatMessage({
          id: 'application.title.search',
          defaultMessage: 'Search',
        })}
      />
      <MeasureFilterViewContextProvider>
        <Search
          results={data}
          isLoading={isLoading || isFetching}
          isError={isError}
          isSuccess={isSuccess}
          hasSearchParams={!!(partner && reporterId && productId)}
        />
      </MeasureFilterViewContextProvider>
    </>
  );
};

export default SearchPage;
