import clsx from 'clsx';
import Card from 'components/Card';
import Loading from 'components/Loading';
import { useGetSelfAssessParams } from 'hooks/useGetSelfAssessParams';
import { lastSelfAssessComplianceItemSelector } from 'modules/app/configSelectors';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetProfileMeasuresQuery } from 'services/selfAssess';
import { getRoute } from 'utils/router';
import AssessMeasureDetails from './AssessMeasureDetails';
import { useSelfAssessContext } from './SelfAssessProfile';
import styles from './SelfAssessProfileCompliance.module.scss';
import SelfAssessProfileComplianceSummary from './SelfAssessProfileComplianceSummary';

const SelfAssessProfileCompliance = () => {
  const response = useGetProfileMeasuresQuery();
  const { data: indicators, currentData } = response;
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const isMenuVisible = useSelfAssessContext();
  const dispatch = useDispatch();
  const lastSelectedItem = useSelector(
    lastSelfAssessComplianceItemSelector,
    shallowEqual
  );
  const navigate = useNavigate();
  const { profileId, measureId, ntmCode } = useGetSelfAssessParams();

  // Replace the URL for every time a measure group is selected
  useEffect(() => {
    if (
      !measureId &&
      !ntmCode &&
      lastSelectedItem &&
      profileId === lastSelectedItem.profileId &&
      lastSelectedItem.ntmCode &&
      lastSelectedItem.measureId
    ) {
      navigate(
        getRoute('fullSelfAssessmentProfileByQuery', {
          profileId,
          measureId: lastSelectedItem.measureId,
          ntmCode: lastSelectedItem.ntmCode,
        }),
        { replace: true }
      );
    }
  }, [dispatch, lastSelectedItem, measureId, navigate, ntmCode, profileId]);

  useEffect(() => {
    if (!isMenuVisible) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.left, isCollapsed && styles.collapsed)}>
        <SelfAssessProfileComplianceSummary
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      </div>
      <div className={styles.right}>
        <Loading
          isLoading={response.isLoading || !currentData}
          isError={response.isError}
          isSuccess={response.isSuccess}
        >
          {Array.isArray(indicators) && indicators.length > 0 && (
            <AssessMeasureDetails
              indicators={indicators}
              isCollapsed={isCollapsed}
            />
          )}
          {Array.isArray(indicators) && indicators.length < 1 && (
            <Card hasCornerCut={false} className="p-3">
              <FormattedMessage
                id="message.noDataFound"
                defaultMessage="No data found"
              />
            </Card>
          )}
        </Loading>
      </div>
    </div>
  );
};

export default React.memo(SelfAssessProfileCompliance);
