import { createSlice } from '@reduxjs/toolkit';

export const cmsSlice = createSlice({
  name: 'cms',
  initialState: {
    bearerToken: null,
    contents: {} as Record<string, any>,
    cmsRoot: {},
    components: {} as Record<string, any>,
  },
  reducers: {
    fetchBearerToken: (): any => ({}),
    fetchContent: (state, action): any => state,
    fetchComponent: (state, action): any => state,
    fetchContentGraphQL: (state, action) => state,
    setBearerToken: (state, action) => {
      state.bearerToken = action.payload;
    },
    setContent: (state, { payload: { key, ...data } }) => ({
      ...state,
      contents: {
        ...state.contents,
        [key]: { ...data },
      },
    }),
  },
});

export const {
  fetchBearerToken,
  fetchContentGraphQL,
  fetchContent,
  fetchComponent,
  setBearerToken,
  setContent,
} = cmsSlice.actions;

export default cmsSlice.reducer;
