import React, { useMemo } from 'react';
import { MeasureFilterViewContextProvider } from 'modules/app/MeasureFilterViewContext';
import CompareCore from 'modules/compare/products/CompareCore';
import { useCompareCountriesOverviewQuery } from 'services/compare';
import Loading from 'components/Loading';
import NoResultFound from 'components/NoResultFound';
import { useGetCompareCountriesParams } from 'hooks/useGetCompareParams';
import styles from '../products/CompareProducts.module.scss';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
  useGetFromCountriesById,
  useGetToCountriesById,
} from 'services/countries';
import { CompareTwoItemsResult } from 'AppTypes';
import { getFlagCode } from 'utils/flags';
import { createSelector } from '@reduxjs/toolkit';
import { CompareCountryItemResponse } from 'ApiServices';
import { createTwoCountriesComparison } from 'services/data/compare-two-countries';

const CompareWithTwoOptions = () => {
  const { partner, reporterId, productId } = useGetCompareCountriesParams();

  const selectTwoCountriesComparison = useMemo(() => {
    return createSelector(
      (response: any) => response.data,
      (response: any, countryCodes: string[]) => countryCodes,
      (data: CompareCountryItemResponse[], countryCodes: string[]) =>
        createTwoCountriesComparison(data, countryCodes[0], countryCodes[1])
    );
  }, []);

  const response = useCompareCountriesOverviewQuery(
    partner && reporterId && productId
      ? { partner, reporterId, productId }
      : skipToken,
    {
      selectFromResult: (result) => ({
        ...result,
        data: selectTwoCountriesComparison(result, reporterId || []),
      }),
    }
  );
  const { data } = response;
  const { data: fromCountries } = useGetFromCountriesById();
  const { data: toCountries } = useGetToCountriesById();

  const newData = useMemo(() => {
    const lookupCountries = {
      ...fromCountries,
      ...toCountries,
    };

    const country1 = lookupCountries[data?.countryCode1 || ''];
    const country2 = lookupCountries[data?.countryCode2 || ''];

    return {
      ...data,
      displayName1: country1?.name || data?.countryCode1 || '',
      displayName2: country2?.name || data?.countryCode2 || '',
      countryIsoCode1: getFlagCode(country1?.iso2),
      countryIsoCode2: getFlagCode(country2?.iso2),
    } as CompareTwoItemsResult;
  }, [data, fromCountries, toCountries]);

  return (
    <div className={styles.wrapper}>
      <Loading
        response={response}
        loadingWrapperClassName="justify-content-center"
      >
        {data &&
        Number(data.totalMeasures1 || 0) > 0 &&
        Number(data.totalMeasures2 || 0) > 0 ? (
          <MeasureFilterViewContextProvider>
            <CompareCore isCompareProducts={false} data={newData} />
          </MeasureFilterViewContextProvider>
        ) : (
          <NoResultFound />
        )}
      </Loading>
    </div>
  );
};

export default React.memo(CompareWithTwoOptions);
