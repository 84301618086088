import { useEffect } from 'react';

export function useImperativeDisableScroll({
  element,
  disabled,
}: {
  element: HTMLElement;
  disabled: boolean;
}) {
  useEffect(() => {
    if (!element) {
      return;
    }

    element.style.overflowY = disabled ? 'hidden' : 'scroll';

    return () => {
      element.style.overflowY = 'scroll';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);
}
