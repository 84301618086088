import clsx from 'clsx';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface Response {
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  isSuccess: boolean;
}

type Props =
  | {
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
      loadingWrapperClassName?: string;
      children: React.ReactNode;
    }
  | {
      response: Response;
      loadingWrapperClassName?: string;
      children: React.ReactNode;
    };

const Loading = (props: Props) => {
  const { loadingWrapperClassName, children } = props;
  let [isLoading, isError, isSuccess] = [false, false, false];

  if ('response' in props) {
    isLoading = props.response.isLoading;
    isError = props.response.isError;
    isSuccess = props.response.isSuccess;
  } else {
    isLoading = props.isLoading;
    isError = props.isError;
    isSuccess = props.isSuccess;
  }

  return (
    <>
      {isLoading ? (
        <div
          className={clsx(loadingWrapperClassName, 'd-flex align-items-center')}
        >
          <Spinner animation="border" className="spinner-border me-3" />
          <strong>
            <FormattedMessage
              id="message.loading"
              defaultMessage="Loading, please wait..."
            />
          </strong>
        </div>
      ) : isError ? (
        <div className={loadingWrapperClassName}>
          <strong>
            <FormattedMessage
              id="message.errorOccurred"
              defaultMessage="An unexpected error occurred, please try again"
            />
          </strong>
        </div>
      ) : isSuccess ? (
        children
      ) : null}
    </>
  );
};

export default Loading;
