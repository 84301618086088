import {
  MeasureFilterAndMeasureTypeMapping,
  MeasureFilterType,
  MeasureType,
  MeasureTypeDetails,
} from 'modules/constants';
import React, { createContext, useContext, useMemo, useState } from 'react';

export const MeasureFilterViewContext = createContext<{
  filterView: MeasureFilterType;
  setFilterView: (value: MeasureFilterType) => void;
}>({
  filterView: MeasureFilterType.SPS_TBT,
  setFilterView: (value: MeasureFilterType) => {},
});

export function MeasureFilterViewContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [filterView, setFilterView] = useState(MeasureFilterType.SPS_TBT);

  const contextValue = useMemo(
    () => ({ filterView, setFilterView }),
    [filterView]
  );

  return (
    <MeasureFilterViewContext.Provider value={contextValue}>
      {children}
    </MeasureFilterViewContext.Provider>
  );
}

export function useGetMeasureTypesInFilter(includeOtherRequirements = false) {
  const { filterView } = useContext(MeasureFilterViewContext);
  const measureTypes =
    MeasureFilterAndMeasureTypeMapping[
      filterView ?? MeasureFilterType.SPS_TBT
    ]?.map((id) => MeasureTypeDetails[id]) ?? [];

  return includeOtherRequirements
    ? measureTypes
    : measureTypes.filter((item) => item.key !== MeasureType.OTHER);
}
