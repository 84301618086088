import {
  CompareCountryNtmCodeLevel,
  CompareIndicatorResult,
  Country,
} from 'AppTypes';
import CompareCountriesDetailIndicator from 'modules/compare/countries/CompareCountriesDetailIndicator';
import CompareCountriesDetailSelect from 'modules/compare/countries/CompareCountriesDetailSelect';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './CompareCountriesDetailPanelSection.module.scss';
import uniqBy from 'lodash/uniqBy';

type Props = {
  isFlipped?: boolean;
  availableCountries: Country[];
  selectedCountry: Country | null;
  setCountry: (value: Country | null) => void;
  indicator: CompareCountryNtmCodeLevel<CompareIndicatorResult>;
  measureCountByCountryCode: Record<string, number>;
};

const CompareCountriesDetailPanelSection = ({
  isFlipped,
  availableCountries,
  selectedCountry,
  setCountry,
  indicator,
  measureCountByCountryCode,
}: Props) => {
  const lookupCountryKey = useMemo(() => {
    const foundItem = indicator.measureCount.find(
      (item) => item.countryId === selectedCountry?.code
    );

    return foundItem?.countryGroup === '0'
      ? selectedCountry?.code
      : foundItem?.countryGroup;
  }, [indicator.measureCount, selectedCountry?.code]);

  const filteredMeasures = useMemo(
    () =>
      uniqBy(
        indicator.measures.filter(
          (measure) => measure.reporterId === lookupCountryKey
        ),
        (item) => `${item.measureId}-${item.reporterId}`
      ),
    [indicator.measures, lookupCountryKey]
  );

  return (
    <div className={styles.wrapper}>
      {filteredMeasures.length > 0 && (
        <>
          <CompareCountriesDetailSelect
            isFlipped={isFlipped}
            options={availableCountries}
            value={selectedCountry}
            setValue={setCountry}
          />
          <div className={styles.commonMeasuresMessage}>
            <FormattedMessage
              id="compare.message.indicatorPresentedInTotalMeasures"
              defaultMessage="This indicator is present in {numberOfCommonMeasures} out of the total {totalMeasureCount} measures"
              values={{
                numberOfCommonMeasures:
                  indicator[selectedCountry?.code || ''] || 0,
                totalMeasureCount:
                  measureCountByCountryCode[lookupCountryKey || ''] || 0,
              }}
            />
          </div>
          <CompareCountriesDetailIndicator indicators={filteredMeasures} />
        </>
      )}
    </div>
  );
};

export default CompareCountriesDetailPanelSection;
