import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow-up.svg';
import { useIntl } from 'react-intl';
import styles from './ScrollToTop.module.scss';

const ScrollToTop = () => {
  const intl = useIntl();
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 200) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <button
      type="button"
      style={{
        opacity: showTopBtn ? 1 : 0,
        visibility: showTopBtn ? 'visible' : 'hidden',
      }}
      className={styles.btn}
      title={intl.formatMessage({
        id: 'component.scrollToTop',
        description: 'Scroll to top button label',
        defaultMessage: 'Scroll to top',
      })}
      onClick={goToTop}
    >
      <ArrowUp height={23} />
    </button>
  );
};

export default ScrollToTop;
