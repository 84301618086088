import React from 'react';
import { Header } from '@tanstack/react-table';
import { RadioGroup } from 'components/RadioGroup';
import { RowType } from 'hooks/useMeasureTable';
import { FormattedMessage } from 'react-intl';
import { useUpdateComplianceMutation } from 'services/selfAssess';
import { AssessProfileMeasureIndicator } from 'AppTypes';

type Props = {
  header: Header<RowType, unknown>;
  profileId: string;
};

const AssessMeasureDetailsTableRadioByColumn = ({
  header,
  profileId,
}: Props) => {
  const [updateCompliance, { isLoading }] = useUpdateComplianceMutation();
  const indicator = (header.column.columnDef.meta as any)
    .indicator as AssessProfileMeasureIndicator;

  return (
    <>
      <RadioGroup
        name={`${header.id}`}
        disabled={isLoading}
        items={[
          {
            label: (
              <FormattedMessage
                id="selfAssess.compliance.complied"
                defaultMessage="Yes"
              />
            ),
            value: 'true',
          },
          {
            label: (
              <FormattedMessage
                id="selfAssess.compliance.notComplied"
                defaultMessage="No"
              />
            ),
            value: 'false',
          },
        ]}
        value={indicator.assessmentId ? String(indicator.isComplied) : null}
        onChange={(newValue) => {
          updateCompliance({
            IsComplied: newValue === 'true',
            AssessmentId: indicator.assessmentId,
            MeasureId: indicator.measureId,
            NtmCode: indicator.ntmCode,
            IndicatorId: indicator.indicatorId,
            AssessmentRowId: indicator.rowId,
            AssessmentProfileId: profileId || '',
            ExtraData: null,
          });
        }}
      />
    </>
  );
};

export default React.memo(AssessMeasureDetailsTableRadioByColumn);
