import clsx from 'clsx';
import React from 'react';
import styles from './Orb.module.scss';

type Props = {
  color: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Orb = ({ color, ...props }: Props) => {
  return (
    <div
      {...props}
      className={clsx(styles.container, props.className)}
      style={{
        ...props.style,
        backgroundImage: `linear-gradient(0deg, var(--bs-${color}) 0%, var(--bs-white) 100%)`,
      }}
    />
  );
};

export default Orb;
