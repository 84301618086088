import React from 'react';
import styles from './BaseWidget.module.scss';
import Card from './Card';
import Orb from './Orb';

type Props = {
  children: React.ReactNode;
  title: React.ReactNode;
  icon: React.ReactNode;
  decorColor: string;
};

const BaseWidget = ({ children, title, icon, decorColor }: Props) => {
  return (
    <Card className={styles.card}>
      <div className={styles.cardTitle}>
        <Orb className={styles.decoration} color={decorColor} />
        {title && <div className={styles.title}>{title}</div>}
        <div className={styles.icon}>{icon}</div>
      </div>
      <div className={styles.cardBody}>{children}</div>
    </Card>
  );
};

export default BaseWidget;
