import React, { useMemo, useState } from 'react';
import { MeasureGroupOverview } from 'AppTypes';
import { getMeasureIcon } from 'assets/remote';
import SectionHeader from 'components/SectionHeader';
import styles from './MeasureGroupList.module.scss';
import { FormattedMessage } from 'react-intl';
import { SelectOption } from 'AppTypes';
import NTMSelect from 'components/Select';
import { ReactComponent as SortingIcon } from 'assets/icons/sorting.svg';
import { SingleValue } from 'react-select';
import SearchNTMAccordion from 'modules/search/components/SearchNTMAccordion';
import SearchMeasureGroupDetails from 'modules/search/SearchMeasureGroupDetails';
import { getStandardMeasureCode } from 'utils/helpers';

type Props = {
  title: React.ReactNode;
  icon: React.ReactNode;
  data: MeasureGroupOverview[];
  enableSorting?: boolean;
};

enum MEASURE_SORTING {
  LARGE_TO_SMALL,
  SMALL_TO_LARGE,
}

const MEASURE_SORTING_OPTIONS: SelectOption<MEASURE_SORTING>[] = [
  {
    label: (
      <FormattedMessage
        id="select.option.largestToSmallest"
        defaultMessage="Largest to smallest"
      />
    ),
    value: MEASURE_SORTING.LARGE_TO_SMALL,
  },
  {
    label: (
      <FormattedMessage
        id="select.option.smallestToLargest"
        defaultMessage="Smallest to largest"
      />
    ),
    value: MEASURE_SORTING.SMALL_TO_LARGE,
  },
];

const MeasureGroupList = ({ title, icon, data, enableSorting }: Props) => {
  const [sortValue, setSortValue] = useState<SingleValue<SelectOption>>(
    MEASURE_SORTING_OPTIONS[0]
  );

  const dataGroupOption1 = data.filter(
    (item: MeasureGroupOverview) => item.groupOption === 1
  );
  const dataGroupOption2 = data.filter(
    (item: MeasureGroupOverview) => item.groupOption === 2
  );

  const ntmMergeCodes = Array.from(
    new Set(
      dataGroupOption2.map((item: MeasureGroupOverview) => item.ntmMergeCode)
    )
  );

  const dataGroupOption2ByNtmMergeCode = ntmMergeCodes
    .map((code) => {
      return dataGroupOption2.filter(
        (item: MeasureGroupOverview) => item.ntmMergeCode === code
      );
    })
    .map((item: MeasureGroupOverview[]) => {
      if (item.length === 1) {
        return item[0];
      }

      return item.reduce(
        (result: MeasureGroupOverview, current: MeasureGroupOverview) => {
          console.log({ result, current });
          return {
            ...current,
            ntmCode: [result?.ntmCode ?? '', current?.ntmCode]
              .filter((code) => code !== '')
              .join(','),
            measureCount: (result.measureCount ?? 0) + current.measureCount,
          } as MeasureGroupOverview;
        },
        {} as MeasureGroupOverview
      );
    });

  const refinedData = useMemo(
    () => [...dataGroupOption1, ...dataGroupOption2ByNtmMergeCode],
    [dataGroupOption1, dataGroupOption2ByNtmMergeCode]
  );

  const sortedData = useMemo(() => {
    const sortDirection =
      sortValue?.value === MEASURE_SORTING.LARGE_TO_SMALL ? 1 : -1;

    return [...refinedData].sort((a, b) => {
      if (a.measureCount > b.measureCount) return -1 * sortDirection;
      if (a.measureCount < b.measureCount) return 1 * sortDirection;

      return a.shortTitle.localeCompare(b.shortTitle);
    });
  }, [refinedData, sortValue?.value]);

  return (
    <section>
      <SectionHeader title={title} icon={icon}>
        <div className={styles.sorting}>
          <SortingIcon fill="var(--bs-white)" />
          <NTMSelect
            options={MEASURE_SORTING_OPTIONS}
            placeholder={
              <FormattedMessage
                id="select.placeholder.sortBy"
                defaultMessage="Sort by"
              />
            }
            value={sortValue}
            onChange={(value) => setSortValue(value)}
          />
        </div>
      </SectionHeader>
      <SearchNTMAccordion
        data={sortedData}
        renderSummaryFn={RenderSummary}
        renderDetailFn={RenderDetail}
      />
    </section>
  );
};

export function RenderSummary(data: MeasureGroupOverview) {
  return (
    <div className={styles.summary}>
      <div className={styles.image}>
        <svg
          data-src={getMeasureIcon(
            getStandardMeasureCode(
              data.ntmCode.includes(',')
                ? data.ntmCode.split(',')[0]
                : data.ntmCode
            )
          )}
        />
      </div>
      <div className={styles.title}>
        {data.shortTitle} ({data.measureCount})
      </div>
    </div>
  );
}

function RenderDetail(data: MeasureGroupOverview) {
  return <SearchMeasureGroupDetails measureGroup={data} />;
}

export default React.memo(MeasureGroupList);
