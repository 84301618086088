import React from 'react';
import BaseWidget from 'components/BaseWidget';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as UserIcon } from 'assets/icons/user-filled.svg';
import Button from 'components/Button';
import styles from './SelfAssessWidget.module.scss';
import { HomePageWidget } from 'AppTypes';
import { sanitize } from 'dompurify';
import { useAuth } from 'react-oidc-context';
import SelfAssessWidgetBody from './SelfAssessWidgetBody';

const SelfAssessWidget = ({ data }: { data?: HomePageWidget }) => {
  const auth = useAuth();

  return (
    <BaseWidget
      title={data?.title}
      icon={<img src={data?.titleIcon?.[0]?.url} alt="" />}
      decorColor="red"
    >
      <div className={styles.body}>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitize(data?.description || ''),
          }}
        />
        {auth.isAuthenticated ? (
          <SelfAssessWidgetBody />
        ) : (
          <div className={styles.loginArea}>
            <Button
              variant="white"
              className={styles.loginBtn}
              onClick={() =>
                void auth.signinRedirect({
                  state: {
                    title: window.document.title,
                    href: window.location.href,
                    pathname: window.location.pathname,
                  },
                })
              }
              disabled={!!(auth.activeNavigator || auth.isLoading)}
            >
              <FormattedMessage
                id="selfAssess.widget.loginBtnLabel"
                defaultMessage="Login to use"
              />
              <UserIcon />
            </Button>
          </div>
        )}
      </div>
    </BaseWidget>
  );
};

export default SelfAssessWidget;
