import { AnyAction } from '@reduxjs/toolkit';
import { combineEpics } from 'redux-observable';
import cmsEpics from 'modules/cms/epics';
import selfAssessEpic from 'modules/self-assess/selfAssessEpic';

const rootEpic = combineEpics<AnyAction, AnyAction, any, any>(
  cmsEpics,
  selfAssessEpic
);

export default rootEpic;
