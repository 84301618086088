import clsx from 'clsx';
import React from 'react';
import styles from './Card.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  hasCornerCut?: boolean;
};

const Card = ({
  children,
  className,
  wrapperClassName,
  hasCornerCut = true,
}: Props) => {
  return (
    <section
      className={clsx(
        styles.cardWrapper,
        hasCornerCut && styles.hasCornerCut,
        wrapperClassName
      )}
    >
      <div className={clsx(styles.card, className)}>{children}</div>
    </section>
  );
};

export default Card;
