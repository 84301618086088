import clsx from 'clsx';
import React from 'react';
import { NavDropdown, NavDropdownProps } from 'react-bootstrap';
import { useMatch } from 'react-router-dom';

type Props = {
  basePath: string;
} & NavDropdownProps;

const NavDropdownLink = ({ basePath, ...props }: Props) => {
  const isMatched = useMatch({
    path: basePath,
    end: false,
  });

  return (
    <NavDropdown
      {...props}
      className={clsx(props.className, !!isMatched && 'parent-active')}
    />
  );
};

export default React.memo(NavDropdownLink);
