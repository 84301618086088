import { Routes } from 'utils/router';
import { Provider } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';
import { store, history } from 'store';
import { HelmetProvider } from 'react-helmet-async';
import { AuthProvider } from 'react-oidc-context';
import { authConfig } from 'config';

function App() {
  return (
    <AuthProvider {...authConfig}>
      <Provider store={store}>
        <HistoryRouter history={history}>
          <HelmetProvider>
            <Routes />
          </HelmetProvider>
        </HistoryRouter>
      </Provider>
    </AuthProvider>
  );
}

export default App;
