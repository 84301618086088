import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SearchModel } from 'AppTypes';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as GlobeIcon } from 'assets/icons/globe.svg';
import { ReactComponent as ProductIcon } from 'assets/icons/product.svg';
import styles from './SearchForm.module.scss';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/router';
import { CountrySelectRHF } from 'components/Select';
import ProductSelect from 'components/Select/ProductSelect';
import clsx from 'clsx';
import { useGetSearchParams } from 'hooks/useGetSearchParams';
import {
  useGetFromCountriesQuery,
  useGetToCountriesQuery,
} from 'services/countries';

const searchSchema = yup.object({
  partner: yup.string().required().label('From country'),
  reporterId: yup.string().required().label('To country'),
  productId: yup.string().required().label('Product'),
});

type Props = {
  horizontalLayout?: boolean;
  onChangeToCountry?: (value: string | null) => void;
};

const SearchForm = ({ horizontalLayout, onChangeToCountry }: Props) => {
  const navigate = useNavigate();
  const data = useGetSearchParams();

  const formMethods = useForm<SearchModel>({
    resolver: yupResolver(searchSchema),
    defaultValues: data,
  });

  const { handleSubmit, watch } = formMethods;

  const [fromValue, toValue] = watch(['partner', 'reporterId']);

  useEffect(() => {
    onChangeToCountry?.(toValue);
  }, [onChangeToCountry, toValue]);

  const onSubmit = useCallback(
    (data: SearchModel) => {
      navigate({
        pathname: getRoute('search'),
        search: `${createSearchParams(data)}`,
      });
    },
    [navigate]
  );

  const { data: fromCountries = [], isFetching: isFetchingFromCountries } =
    useGetFromCountriesQuery();
  const { data: toCountries = [], isFetching: isFetchingToCountries } =
    useGetToCountriesQuery();

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={clsx(styles.form, horizontalLayout && styles.horizontal)}
      >
        <div className={styles.formBody}>
          <div className={styles.formGroup}>
            <label htmlFor="search.form.from">
              <GlobeIcon />
              <FormattedMessage
                id="search.form.fromLabel"
                defaultMessage="From"
              />
            </label>
            <div className={styles.formField}>
              <CountrySelectRHF
                name="partner"
                id="search.form.from"
                options={fromCountries}
                isLoading={isFetchingFromCountries}
                isOptionDisabled={(option) => option.code === toValue}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="search.form.to">
              <GlobeIcon />
              <FormattedMessage id="search.form.toLabel" defaultMessage="To" />
            </label>
            <div className={styles.formField}>
              <CountrySelectRHF
                name="reporterId"
                id="search.form.to"
                options={toCountries}
                isLoading={isFetchingToCountries}
                isOptionDisabled={(option) => option.code === fromValue}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="search.form.productCode">
              <ProductIcon />
              <FormattedMessage
                id="search.form.productLabel"
                defaultMessage="Product"
              />
            </label>
            <div className={styles.formField}>
              <ProductSelect name="productId" id="search.form.productCode" />
            </div>
          </div>
        </div>
        <div className={styles.formAction}>
          <Button type="submit" variant="secondary" hasCornerCut={true}>
            {
              <FormattedMessage
                id="search.form.submitBtn"
                defaultMessage="Search"
              />
            }
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default SearchForm;
