import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from './Button';
import * as DOMPurify from 'dompurify';
import clsx from 'clsx';
import { isTextExceedWordLimit } from 'utils/helpers';

type Props = {
  toggleKeyProp?: number | string;
  children: string;
  maxWords?: number;
  defaultExpanded?: boolean;
  onToggle?: (value: boolean, key?: number | string) => void;
};

const Truncate = ({
  toggleKeyProp,
  children,
  maxWords = 200,
  defaultExpanded: isExpanded,
  onToggle,
}: Props) => {
  const showSeeMore = useMemo(() => {
    return isTextExceedWordLimit(children, maxWords);
  }, [children, maxWords]);

  const content = useMemo(() => {
    if (showSeeMore && !isExpanded) {
      const contentArray = children.split('\n');

      let wordIdx = 0;
      let result = '';

      for (const str of contentArray) {
        const words = str.split(' ');

        if (words.length === 1 && words[0] === '') {
          result += '\n';
          continue;
        }

        if (words.length > 1) {
          if (wordIdx + words.length <= maxWords) {
            result += str;
            wordIdx += words.length;
          } else {
            for (let i = 0; i < maxWords - wordIdx; i++) {
              result += words[i] + ' ';
              wordIdx++;
            }
            result += '...';
            break;
          }
        }

        result += '\n';
      }

      return result;
    }

    return children;
  }, [children, isExpanded, maxWords, showSeeMore]);

  const onToggleExpand = useCallback(() => {
    onToggle?.(!isExpanded, toggleKeyProp);
  }, [toggleKeyProp, isExpanded, onToggle]);

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content),
        }}
      ></div>
      {showSeeMore && (
        <div style={{ textAlign: 'right', marginTop: '.5rem' }}>
          <Button
            type="button"
            variant="outline-toggle"
            onClick={() => onToggleExpand()}
            size="sm"
            className={clsx(isExpanded && 'active')}
          >
            {isExpanded ? (
              <FormattedMessage
                id="button.action.seeLess"
                defaultMessage="See less"
              />
            ) : (
              <FormattedMessage
                id="button.action.seeMore"
                defaultMessage="See more"
              />
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default React.memo(Truncate);
