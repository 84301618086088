import { createColumnHelper, SortingState } from '@tanstack/react-table';
import { TradeRequirementByCountry } from 'AppTypes';
import { SimpleTable } from 'components/Table';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Checkmark } from 'assets/icons/checkmark.svg';
import config from 'config';

type Props = {
  data: TradeRequirementByCountry[];
};

const columnHelper = createColumnHelper<TradeRequirementByCountry>();
const defaultSortingState: SortingState = [{ id: 'cityName', desc: false }];

const DataAvailabilityTable = ({ data }: Props) => {
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('cityName', {
        header: () => (
          <FormattedMessage
            id="dataAvailability.header.countries"
            defaultMessage="Countries"
          />
        ),
        cell: (info) => info.getValue() ?? '',
        enableColumnFilter: false,
      }),
      ...Object.keys(data[0] ?? {})
        .filter((key) => !['cityCode', 'cityName'].includes(key))
        .sort()
        .map((key) =>
          columnHelper.accessor((data) => !!data[key], {
            header: key,
            cell: (info) => (
              <div className="text-center">
                {info.getValue() ? (
                  <Checkmark fill="var(--bs-primary)" />
                ) : null}
              </div>
            ),
            enableColumnFilter: false,
          })
        ),
    ];
  }, [data]);

  return (
    <SimpleTable
      data={data}
      columns={columns}
      enableGlobalFilter
      enableSorting
      stickyHeader
      defaultSortingState={defaultSortingState}
      onClickDownloadBtn={() => {
        window.location.href = `${config.apiPrimary}/v1/about/get-data-available-report`;
      }}
    />
  );
};

export default DataAvailabilityTable;
