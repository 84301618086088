import { IndicatorDetail, SelectOption } from 'AppTypes';
import Button from 'components/Button';
import { useIndicatorToggle, useMeasureGroup } from 'hooks/useMeasureGroup';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SingleValue } from 'react-select';
import { isSelectedMeasureMRL } from 'utils/helpers';
import styles from './MeasureGroupDetailsCompareContent.module.scss';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import SourceLegislation from 'modules/app/SourceLegislation';
import MeasureDetails from 'modules/app/MeasureDetails';
import { MeasureDetailsContext } from 'modules/app/MeasureDetailsContext';
import NTMSelect from 'components/Select';
import clsx from 'clsx';
import CompareNonMRLMeasures from 'modules/compare/components/CompareNonMRLMeasures';

type Props = {
  indicators1: IndicatorDetail[];
  indicators2: IndicatorDetail[];
};

const MeasureGroupDetailsCompareContent = ({
  indicators1,
  indicators2,
}: Props) => {
  const {
    measuresToSelect: measuresToSelect1,
    indicatorsGroupedByMeasures: indicatorsGroupedByMeasures1,
  } = useMeasureGroup(indicators1);

  const [selectedMeasure1, setSelectedMeasure1] = useState<
    SingleValue<SelectOption>
  >(measuresToSelect1[0]);

  const {
    isSelectedAllMeasures: isSelectedAllMeasures1,
    selectedIndicators: selectedIndicators1,
    toggleBodyState: toggleBodyState1,
    showExpandAll: showExpandAll1,
    showCollapseAll: showCollapseAll1,
    onClickExpandAll: onClickExpandAll1,
    onClickCollapseAll: onClickCollapseAll1,
    updateToggleBodyState: updateToggleBodyState1,
    isDisplaySource: isDisplaySource1,
    onToggleSource: onToggleSource1,
    isShowToggleBodyActions: isShowToggleBodyActions1,
  } = useIndicatorToggle(
    indicators1,
    selectedMeasure1,
    indicatorsGroupedByMeasures1
  );

  const doesSelectedMeasureOptionHasMRL1 = useMemo(() => {
    if (isSelectedAllMeasures1) return false;
    return isSelectedMeasureMRL(selectedIndicators1 ?? []);
  }, [selectedIndicators1, isSelectedAllMeasures1]);

  const onChangeSelectedMeasure1 = useCallback(
    (newValue: SingleValue<SelectOption>) => {
      setSelectedMeasure1(newValue);
    },
    []
  );

  const {
    measuresToSelect: measuresToSelect2,
    indicatorsGroupedByMeasures: indicatorsGroupedByMeasures2,
  } = useMeasureGroup(indicators2);

  const [selectedMeasure2, setSelectedMeasure2] = useState<
    SingleValue<SelectOption>
  >(measuresToSelect2[0]);

  const {
    isSelectedAllMeasures: isSelectedAllMeasures2,
    selectedIndicators: selectedIndicators2,
    toggleBodyState: toggleBodyState2,
    showExpandAll: showExpandAll2,
    showCollapseAll: showCollapseAll2,
    onClickExpandAll: onClickExpandAll2,
    onClickCollapseAll: onClickCollapseAll2,
    updateToggleBodyState: updateToggleBodyState2,
    isDisplaySource: isDisplaySource2,
    onToggleSource: onToggleSource2,
    isShowToggleBodyActions: isShowToggleBodyActions2,
  } = useIndicatorToggle(
    indicators2,
    selectedMeasure2,
    indicatorsGroupedByMeasures2
  );

  const doesSelectedMeasureOptionHasMRL2 = useMemo(() => {
    if (isSelectedAllMeasures2) return false;
    return isSelectedMeasureMRL(selectedIndicators2 ?? []);
  }, [selectedIndicators2, isSelectedAllMeasures2]);

  const onChangeSelectedMeasure2 = useCallback(
    (newValue: SingleValue<SelectOption>) => {
      setSelectedMeasure2(newValue);
    },
    []
  );

  const tableActionRef1 = useRef<HTMLDivElement>(null);
  const tableActionRef2 = useRef<HTMLDivElement>(null);

  const isAtLeastOneSideBeMRL =
    doesSelectedMeasureOptionHasMRL1 ||
    isSelectedAllMeasures1 ||
    doesSelectedMeasureOptionHasMRL2 ||
    isSelectedAllMeasures2;

  return (
    <MeasureDetailsContext.Provider value={tableActionRef1?.current}>
      <div className={styles.contentWrapper}>
        <div className={styles.actions}>
          <div className={styles.col}>
            <div className={styles.compareLayoutActionContainer}>
              <div id="measure-group-details" ref={tableActionRef1}></div>
              {!isDisplaySource1 &&
                isShowToggleBodyActions1 &&
                !doesSelectedMeasureOptionHasMRL1 && (
                  <>
                    {showExpandAll1 && !isSelectedAllMeasures1 && (
                      <Button
                        type="button"
                        variant="light"
                        size="sm"
                        className={styles.expandBtn}
                        onClick={onClickExpandAll1}
                      >
                        <FormattedMessage
                          id="button.action.expandAll"
                          defaultMessage="Expand All"
                        />
                        <PlusIcon />
                      </Button>
                    )}
                    {showCollapseAll1 && !isSelectedAllMeasures1 && (
                      <Button
                        type="button"
                        variant="light"
                        size="sm"
                        className={styles.collapseBtn}
                        onClick={onClickCollapseAll1}
                      >
                        <FormattedMessage
                          id="button.action.collapseAll"
                          defaultMessage="Collapse All"
                        />
                        <MinusIcon />
                      </Button>
                    )}
                  </>
                )}
            </div>
            <div className={styles.header}>
              <div className={clsx(styles.wrapper)}>
                <div className={styles.title}>
                  <NTMSelect
                    options={measuresToSelect1}
                    defaultValue={measuresToSelect1?.[0]}
                    value={selectedMeasure1}
                    onChange={onChangeSelectedMeasure1}
                    showNavigationButtons
                  />
                </div>
                {!isSelectedAllMeasures1 && (
                  <div className={styles.sourceLegislation}>
                    <Button
                      type="button"
                      size="sm"
                      onClick={() => {
                        onToggleSource1();
                        if (!isAtLeastOneSideBeMRL) {
                          onToggleSource2();
                        }
                      }}
                      className={styles.toggleBtn}
                    >
                      {isDisplaySource1 ? (
                        <FormattedMessage
                          id="button.action.viewIndicators"
                          defaultMessage="Indicators"
                        />
                      ) : (
                        <FormattedMessage
                          id="button.action.viewSourceLegislation"
                          defaultMessage="Source Legislation"
                        />
                      )}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.compareLayoutActionContainer}>
              <div id="measure-group-details" ref={tableActionRef2}></div>
              {!isDisplaySource2 &&
                isShowToggleBodyActions2 &&
                !doesSelectedMeasureOptionHasMRL2 && (
                  <>
                    {showExpandAll2 && !isSelectedAllMeasures2 && (
                      <Button
                        type="button"
                        variant="light"
                        size="sm"
                        className={styles.expandBtn}
                        onClick={onClickExpandAll2}
                      >
                        <FormattedMessage
                          id="button.action.expandAll"
                          defaultMessage="Expand All"
                        />
                        <PlusIcon />
                      </Button>
                    )}
                    {showCollapseAll2 && !isSelectedAllMeasures2 && (
                      <Button
                        type="button"
                        variant="light"
                        size="sm"
                        className={styles.collapseBtn}
                        onClick={onClickCollapseAll2}
                      >
                        <FormattedMessage
                          id="button.action.collapseAll"
                          defaultMessage="Collapse All"
                        />
                        <MinusIcon />
                      </Button>
                    )}
                  </>
                )}
            </div>
            <div className={styles.header}>
              <div className={clsx(styles.wrapper)}>
                <div className={styles.title}>
                  <NTMSelect
                    options={measuresToSelect2}
                    defaultValue={measuresToSelect2?.[0]}
                    value={selectedMeasure2}
                    onChange={onChangeSelectedMeasure2}
                    showNavigationButtons
                  />
                </div>
                {!isSelectedAllMeasures2 && (
                  <div className={styles.sourceLegislation}>
                    <Button
                      type="button"
                      size="sm"
                      onClick={() => {
                        onToggleSource2();
                        if (!isAtLeastOneSideBeMRL) {
                          onToggleSource1();
                        }
                      }}
                      className={styles.toggleBtn}
                    >
                      {isDisplaySource2 ? (
                        <FormattedMessage
                          id="button.action.viewIndicators"
                          defaultMessage="Indicators"
                        />
                      ) : (
                        <FormattedMessage
                          id="button.action.viewSourceLegislation"
                          defaultMessage="Source Legislation"
                        />
                      )}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.body}>
          {isAtLeastOneSideBeMRL ? (
            <>
              <div className={styles.col}>
                {isDisplaySource1 ? (
                  !!selectedIndicators1?.[0] && (
                    <SourceLegislation measure={selectedIndicators1[0]} />
                  )
                ) : (
                  <MeasureDetails
                    indicators={selectedIndicators1}
                    toggleState={toggleBodyState1}
                    updateToggleState={updateToggleBodyState1}
                    isSelectAll={isSelectedAllMeasures1}
                    className={styles.resetPadding}
                    tableActionContainer={tableActionRef1?.current}
                  />
                )}
              </div>
              <div className={styles.col}>
                {isDisplaySource2 ? (
                  !!selectedIndicators2?.[0] && (
                    <SourceLegislation measure={selectedIndicators2[0]} />
                  )
                ) : (
                  <MeasureDetails
                    indicators={selectedIndicators2}
                    toggleState={toggleBodyState2}
                    updateToggleState={updateToggleBodyState2}
                    isSelectAll={isSelectedAllMeasures2}
                    className={styles.resetPadding}
                    tableActionContainer={tableActionRef2?.current}
                  />
                )}
              </div>
            </>
          ) : (
            <CompareNonMRLMeasures
              indicators1={selectedIndicators1}
              indicators2={selectedIndicators2}
              displaySource={isDisplaySource1 || isDisplaySource2}
              toggleState1={toggleBodyState1}
              toggleState2={toggleBodyState2}
              updateToggleState1={updateToggleBodyState1}
              updateToggleState2={updateToggleBodyState2}
            />
          )}
        </div>
      </div>
    </MeasureDetailsContext.Provider>
  );
};

export default MeasureGroupDetailsCompareContent;
