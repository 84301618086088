import { FilterFn } from '@tanstack/react-table';

declare module '@tanstack/table-core' {
  interface FilterFns {
    singleValueInArray?: FilterFn<unknown>;
  }
}

export const singleValueInArray: FilterFn<unknown> = (
  row,
  columnId,
  filterValues: unknown[]
) => {
  return filterValues.some(
    (filterValue) => row.getValue(columnId) === filterValue
  );
};

singleValueInArray.autoRemove = (val: any) => testFalsy(val) || !val?.length;

function testFalsy(val: any) {
  return val === undefined || val === null || val === '';
}
