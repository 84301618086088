import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as SortingIcon } from 'assets/icons/sorting.svg';
import styles from './MeasureFilterView.module.scss';
import { FILTER_VIEW_OPTIONS, MeasureFilterType } from 'modules/constants';
import clsx from 'clsx';
import { MeasureFilterViewContext } from './MeasureFilterViewContext';
import { FormattedMessage, useIntl } from 'react-intl';
import NTMSelect from 'components/Select';
import { SingleValue } from 'react-select';
import { SelectOption } from 'AppTypes';

type Props =
  | {
      className?: string;
      useDropdown?: false;
    }
  | {
      className?: string;
      useDropdown: true;
      inverted?: boolean;
    };

const MeasureFilterView = (props: Props) => {
  const { className, useDropdown } = props;
  const { setFilterView, filterView } = useContext(MeasureFilterViewContext);
  const [filterValue, setFilterValue] = useState<
    SingleValue<SelectOption<MeasureFilterType>>
  >(FILTER_VIEW_OPTIONS[0]);
  const intl = useIntl();

  // if there's a filter view option, set it to the second option
  // otherwise, set it to the first option
  useEffect(() => {
    if (FILTER_VIEW_OPTIONS?.length > 0) {
      setFilterView(FILTER_VIEW_OPTIONS[1].value);
      setFilterValue(FILTER_VIEW_OPTIONS[1]);
    } else {
      setFilterView(FILTER_VIEW_OPTIONS[0].value);
      setFilterValue(FILTER_VIEW_OPTIONS[0]);
    }
  }, [setFilterView]);

  if (useDropdown) {
    return (
      <div
        className={clsx(
          styles.sortingDropdown,
          useDropdown && props.inverted && styles.inverted,
          className
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          return;
        }}
      >
        <SortingIcon />
        <NTMSelect
          options={FILTER_VIEW_OPTIONS}
          placeholder={
            <FormattedMessage
              id="select.placeholder.sortBy"
              defaultMessage="Sort by"
            />
          }
          value={filterValue}
          onChange={(value) => {
            setFilterValue(value);
            setFilterView(value?.value ?? MeasureFilterType.SPS_TBT);
          }}
        />
      </div>
    );
  }

  return (
    <div
      className={clsx(styles.sorting, className)}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        return;
      }}
    >
      <Dropdown align="end">
        <Dropdown.Toggle
          variant="secondary"
          title={intl.formatMessage({
            id: 'button.action.sortBy',
            defaultMessage: 'Sort by',
          })}
        >
          <SortingIcon fill="var(--bs-white)" />
        </Dropdown.Toggle>

        <Dropdown.Menu className={styles.menu}>
          {FILTER_VIEW_OPTIONS.map((option, index, arr) => (
            <React.Fragment key={index}>
              <Dropdown.Item
                className={clsx(
                  option.value === filterView && styles.selectedOption
                )}
                onClick={() => {
                  setFilterView(option.value);
                }}
              >
                {option.label}
              </Dropdown.Item>
              {index < arr.length - 1 && <Dropdown.Divider />}
            </React.Fragment>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default React.memo(MeasureFilterView);
