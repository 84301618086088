import { useMemo } from 'react';
import { AssessProfileMeasureIndicator, IndicatorDetail } from 'AppTypes';
import { groupBy, uniqBy } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { createColumnHelper } from '@tanstack/react-table';
import { sanitize } from 'dompurify';
import AssessMeasureDetailsTableRadioByRow from 'modules/self-assess/profile/AssessMeasureDetailsTableRadioByRow';
import tableStyles from 'components/Table/SimpleTable.module.scss';

export type RowType = Record<string, any>;

export const columnHelper = createColumnHelper<RowType>();

type UseMeasureTableProps =
  | {
      indicators: IndicatorDetail[];
    }
  | {
      hasStickyComplianceColumn: boolean;
      indicators: AssessProfileMeasureIndicator[];
    };

export function useMeasureTable(props: UseMeasureTableProps) {
  const { indicators } = props;
  const intl = useIntl();

  const measureOrderById = useMemo(
    () =>
      uniqBy(indicators, 'measureId')
        .sort((a, b) => a.measureId?.localeCompare(b.measureId))
        .reduce(
          (acc, indicator, index) => ({
            ...acc,
            [indicator.measureId]: index + 1,
          }),
          {} as Record<string, number>
        ),
    [indicators]
  );

  const indicatorsGroupedByRowId = useMemo(
    () =>
      groupBy(
        indicators,
        (indicator) => `${indicator.measureId}-${indicator.rowId || ''}`
      ),
    [indicators]
  );

  const data = useMemo(() => {
    return Object.entries(indicatorsGroupedByRowId).map(([rowId, items]) => {
      const rowObject = items.reduce(
        (acc, curr) =>
          ({
            ...acc,
            [curr.indicatorId]: curr.indicatorExplanation ?? '',
          } as RowType),
        {} as RowType
      );

      // TODO: Check data consistency between different objects
      // TODO: assign the correct indicator id for MRL cases

      if ('hasStickyComplianceColumn' in props) {
        const indicator = (items as AssessProfileMeasureIndicator[]).filter(
          (item) => item.complianceApplicable
        )[0];

        rowObject.rowId = indicator?.rowId;
        rowObject.ntmCode = indicator?.ntmCode;
        rowObject.measureId = indicator?.measureId;
        rowObject.isComplied = indicator?.isComplied;
        rowObject.isExtraField = indicator?.isExtraField;
        rowObject.extraData =
          indicator?.extraData != null ? Number(indicator.extraData) : null;
        rowObject.assessmentId = indicator?.assessmentId;
        rowObject.indicatorId = indicator?.indicatorId;
        rowObject.maxValue = indicator?.maxValue;
        rowObject.minValue = indicator?.minValue;
      }

      rowObject.rowTitle = `${intl.formatMessage({
        id: 'measures.prefix',
        defaultMessage: 'Measure',
      })} ${measureOrderById[items[0]?.measureId]}`;

      return rowObject;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorsGroupedByRowId, intl, measureOrderById]);

  const columns = useMemo(() => {
    const sortedIndicators = [...indicators].sort((a, b) =>
      a.indicatorId?.localeCompare(b.indicatorId)
    );

    const preconfigColumns = uniqBy(
      sortedIndicators,
      (indicator) => indicator.indicatorId
    ).map((indicator) =>
      columnHelper.accessor(indicator.indicatorId, {
        header: indicator.indicatorLabel || '',
        cell: (info) => {
          return (
            <div
              dangerouslySetInnerHTML={{
                __html: sanitize(String(info.getValue() || '')),
              }}
            />
          );
        },
        meta: { indicator },
        enableColumnFilter: !('hasStickyComplianceColumn' in props),
        filterFn: 'singleValueInArray',
      })
    );

    if ('hasStickyComplianceColumn' in props) {
      return props.hasStickyComplianceColumn
        ? preconfigColumns.concat(
            columnHelper.accessor('sticky', {
              header: () => (
                <FormattedMessage
                  id="selfAssess.message.enterValueOrCheckManually"
                  defaultMessage="Enter your product value for automatic check or mark whether you are compliant with the given requirement:"
                />
              ),
              enablePinning: true,
              enableColumnFilter: false,
              minSize: 300,
              cell: ({ row, cell }) => {
                return (
                  <AssessMeasureDetailsTableRadioByRow
                    row={row}
                    cell={cell}
                    inputClassName={tableStyles.input}
                  />
                );
              },
            })
          )
        : preconfigColumns;
    }

    return preconfigColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicators]);

  return {
    tableData: data,
    tableColumns: columns,
  };
}
