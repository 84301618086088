import { HSFinderForm, ProductByHSRev } from 'AppTypes';
import clsx from 'clsx';
import { InputRHF } from 'components/Input';
import Loading from 'components/Loading';
import { CountrySelectRHF } from 'components/Select';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useGetToCountriesQuery } from 'services/countries';
import { useMetadataInfoQuery } from 'services/metadata';
import {
  getProductsByLatestHSRev,
  useProductsByLatestHSRevQuery,
} from 'services/products';
import styles from './HSFinder.module.scss';
import HSItem from './HSItem';
import { skipToken } from '@reduxjs/toolkit/query/react';

type Props = {
  selectedCountry?: string;
  countryCode?: string;
  onSelect?: (value: ProductByHSRev) => void;
} & ModalProps;

const HSFinderBody = ({ selectedCountry, onSelect, onHide }: Props) => {
  const intl = useIntl();
  const { data: toCountries = [], isFetching: isFetchingToCountries } =
    useGetToCountriesQuery();
  const response = useProductsByLatestHSRevQuery();
  const { data, isLoading } = response;

  const searchValue = useWatch({ name: 'search' });
  const formCountryCode = useWatch({ name: 'countryCode' });

  const { data: metadata } = useMetadataInfoQuery(formCountryCode || skipToken);

  const hsItems = useMemo(() => {
    return data ? getProductsByLatestHSRev(data, searchValue) : null;
  }, [data, searchValue]);

  const onClickItem = useCallback(
    (item: ProductByHSRev, level: number, isLeaf?: boolean) => {
      if (isLeaf) {
        onSelect?.(item);

        if (selectedCountry) {
          onHide?.();
        }
      }
    },
    [onHide, onSelect, selectedCountry]
  );

  return (
    <>
      <Modal.Header closeButton className={styles.header}>
        <Modal.Title className={styles.titleSection}>
          <div className={styles.title}>
            <FormattedMessage
              id="search.hsfinder.title"
              defaultMessage="Enter search keywords or browse your product"
            />
          </div>
          <div className={styles.description}>
            <div>
              <ul className={styles.legend}>
                <li className={styles.legendItem}>
                  <FormattedMessage
                    id="search.hsfinder.classificationSystem"
                    defaultMessage="Classification system: Harmonized Commodity Description and Coding System (HS)"
                  />{' '}
                  {!!metadata?.hsRevision && (
                    <>
                      -{' '}
                      <FormattedMessage
                        id="search.hsfinder.revision"
                        defaultMessage="Revision"
                      />
                      : {metadata?.hsRevision}
                    </>
                  )}
                </li>
                <li className={clsx(styles.legendItem, styles.ntlc)}>
                  <div className={styles.ntlcDetails}>
                    <FormattedMessage
                      id="search.hsfinder.ntlcGuide"
                      defaultMessage="National tariff line codes (NTLCs) with 8 digits or more are from"
                    />
                    <CountrySelectRHF
                      name="countryCode"
                      id="search.form.to"
                      options={toCountries}
                      isDisabled={!!selectedCountry}
                      isLoading={isFetchingToCountries}
                    />
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <InputRHF
                name="search"
                placeholder={intl.formatMessage({
                  id: 'search.hsfinder.searchPlaceholder',
                  defaultMessage: 'Search at 6-digit level',
                })}
                canClear
                fullBorder
                disabled={!data || isLoading}
              />
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loading response={response}>
          {!!hsItems?.length &&
            hsItems.map((item, index) => (
              <HSItem
                key={item.code}
                item={item}
                index={index}
                onClick={onClickItem}
              />
            ))}
        </Loading>
      </Modal.Body>
    </>
  );
};

const HSFinder = (props: Props) => {
  const { onSelect, selectedCountry, ...modalProps } = props;

  const formMethods = useForm<HSFinderForm>({
    defaultValues: { search: '', countryCode: selectedCountry ?? '' },
  });

  const { setValue, reset } = formMethods;

  useEffect(() => {
    setValue('countryCode', selectedCountry ?? null);
  }, [setValue, selectedCountry]);

  const onModalExit = useCallback(
    (node: HTMLElement) => {
      props.onExited?.(node);
      reset();
    },
    [props, reset]
  );

  const onModalEntered = useCallback(() => {
    reset({ search: '', countryCode: selectedCountry ?? '' });
  }, [reset, selectedCountry]);

  return (
    <Modal
      {...modalProps}
      onExited={onModalExit}
      onEntered={onModalEntered}
      size="xl"
    >
      <FormProvider {...formMethods}>
        <HSFinderBody {...props} selectedCountry={selectedCountry} />
      </FormProvider>
    </Modal>
  );
};

export default HSFinder;
