import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Button from 'components/Button';
import styles from './AssessMeasureDetails.module.scss';
import { FormattedMessage } from 'react-intl';
import { AssessProfileMeasureIndicator } from 'AppTypes';
import SourceLegislation from 'modules/app/SourceLegislation';
import AssessMeasureDetailsList from './AssessMeasureDetailsList';
import clsx from 'clsx';
import { useGetSelfAssessParams } from 'hooks/useGetSelfAssessParams';
import { useGetProfileSummaryQuery } from 'services/selfAssess';
import { useIntersectionObserver } from 'usehooks-ts';
import { uniq, uniqBy } from 'lodash';
import AssessMeasureDetailsTable from './AssessMeasureDetailsTable';

type Props = {
  indicators: AssessProfileMeasureIndicator[];
  isCollapsed: boolean;
};

const HeaderInfo = ({
  isCollapsed,
  selectedIndex,
}: {
  isCollapsed: boolean;
  selectedIndex: number;
}) => {
  const { ntmCode, measureId } = useGetSelfAssessParams();
  const { data: summary } = useGetProfileSummaryQuery();
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});

  useEffect(() => {
    if (entry && !entry.isIntersecting) {
      ref.current?.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ntmCode, measureId, entry]);

  const measureGroup = useMemo(() => {
    return summary?.find((measureGroup) => measureGroup.ntmCode === ntmCode);
  }, [ntmCode, summary]);

  return (
    <div className={clsx(isCollapsed && styles.container)} ref={ref}>
      <span className={styles.measure}>
        <FormattedMessage id="measures.prefix" defaultMessage="Measure" />{' '}
        {selectedIndex + 1}
      </span>
      {isCollapsed && measureGroup && (
        <>
          <div className={styles.divider} />
          <span
            className={styles.measureGroupName}
          >{`${measureGroup.shortTitle} (${measureGroup.measureCount})`}</span>
        </>
      )}
    </div>
  );
};

const AssessMeasureDetails = ({ indicators, isCollapsed }: Props) => {
  const { measureId } = useGetSelfAssessParams();
  const [isDisplaySource, setIsDisplaySource] = useState<boolean>(false);

  const displayIndicators = useMemo(
    () => indicators.filter((indicator) => indicator.measureId === measureId),
    [indicators, measureId]
  );

  const isTabularMeasure = useMemo(
    () => uniqBy(displayIndicators, 'rowId').length > 1,
    [displayIndicators]
  );

  const onToggleSource = useCallback(() => {
    setIsDisplaySource((value) => !value);
  }, []);

  const selectedIndexInGroup = useMemo(
    () =>
      uniq(indicators.map((indicator) => indicator.measureId)).findIndex(
        (id) => id === measureId
      ),
    [indicators, measureId]
  );

  return displayIndicators.length > 0 ? (
    <div>
      <div className={styles.header}>
        <HeaderInfo
          isCollapsed={isCollapsed}
          selectedIndex={selectedIndexInGroup}
        />
        <div>
          <Button
            type="button"
            size="sm"
            onClick={onToggleSource}
            className={styles.toggleBtn}
            variant="light"
          >
            {isDisplaySource ? (
              <FormattedMessage
                id="button.action.viewIndicators"
                defaultMessage="Indicators"
              />
            ) : (
              <FormattedMessage
                id="button.action.viewSourceLegislation"
                defaultMessage="Source Legislation"
              />
            )}
          </Button>
        </div>
      </div>
      <div className={styles.body}>
        {isDisplaySource ? (
          !!displayIndicators?.[0] && (
            <SourceLegislation
              measure={displayIndicators[0]}
              isSelfAssessModule
            />
          )
        ) : isTabularMeasure ? (
          <AssessMeasureDetailsTable indicators={displayIndicators} />
        ) : (
          <AssessMeasureDetailsList indicators={displayIndicators} />
        )}
      </div>
    </div>
  ) : null;
};

export default React.memo(AssessMeasureDetails);
