import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formattedNumberWithPrefix } from 'utils/helpers';
import styles from './MeasureCount.module.scss';

const MeasureCount = ({
  measureCount,
  className,
}: {
  measureCount: number;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        className,
        styles.measureDisplay,
        'measure-count-wrapper'
      )}
    >
      <div className={clsx(styles.measureCount, 'measure-count')}>
        {formattedNumberWithPrefix(measureCount)}
      </div>
      <div className={styles.unit}>
        <FormattedMessage id="measures.unit" defaultMessage="measures" />
      </div>
    </div>
  );
};

export default MeasureCount;
