import React from 'react';
import { MeasureGroupOverview } from 'AppTypes';
import { useGetCompareProductMeasureGroupDetail } from 'hooks/useMeasureGroup';
import Loading from 'components/Loading';
import MeasureGroupDetails from 'modules/app/MeasureGroupDetails';
import { FormattedMessage } from 'react-intl';

type Props = {
  measureGroup: MeasureGroupOverview;
  fromCountryCode: string;
  toCountryCode: string;
  productId: string;
};

const CompareMeasureGroupDetails = ({
  measureGroup,
  fromCountryCode,
  toCountryCode,
  productId,
}: Props) => {
  const response = useGetCompareProductMeasureGroupDetail({
    measureGroup,
    partner: fromCountryCode,
    reporterId: toCountryCode,
    productId,
  });
  const { data: indicators } = response;

  return (
    <Loading response={response}>
      {indicators?.length ? (
        <MeasureGroupDetails
          measureGroup={measureGroup}
          indicators={indicators}
          compareLayout
        />
      ) : (
        <FormattedMessage
          id="message.noDataFound"
          defaultMessage="No data found"
        />
      )}
    </Loading>
  );
};

export default React.memo(CompareMeasureGroupDetails);
