import React, { useMemo } from 'react';
import { IndicatorDetail } from 'AppTypes';
import MeasureDetailsList from './MeasureDetailsList';
import MeasureDetailsTable from './MeasureDetailsTable';
import { isSelectedMeasureMRL } from 'utils/helpers';
import clsx from 'clsx';
import styles from './MeasureDetails.module.scss';
import { useIntl } from 'react-intl';

type Props = {
  indicators: IndicatorDetail[];
  toggleState: Record<string, boolean | undefined>;
  updateToggleState: (value: boolean, index?: number | string) => void;
  index?: number;
  isSelectAll?: boolean;
  className?: string;
  tableActionContainer?: HTMLDivElement | null;
};

const MeasureDetails = ({
  indicators,
  toggleState,
  updateToggleState,
  index,
  isSelectAll,
  className,
  tableActionContainer,
}: Props) => {
  const intl = useIntl();
  const isTableForm = useMemo(() => {
    // Single measure
    return isSelectedMeasureMRL(indicators) || isSelectAll;
  }, [indicators, isSelectAll]);

  return (
    <div
      className={clsx(
        index != null && index % 2 === 1 && styles.oddItem,
        styles.measureDetail,
        className
      )}
      title={
        index != null
          ? `${intl.formatMessage({
              id: 'measures.prefix',
              defaultMessage: 'Measure',
            })} ${index + 1}`
          : ''
      }
    >
      {isTableForm ? (
        <MeasureDetailsTable
          indicators={indicators}
          stickyHeader
          isSelectAll={!!isSelectAll}
          tableActionContainer={tableActionContainer}
        />
      ) : (
        <MeasureDetailsList
          indicators={indicators}
          toggleState={toggleState}
          updateToggleState={updateToggleState}
        />
      )}
    </div>
  );
};

export default MeasureDetails;
