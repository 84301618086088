import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useContent } from 'services/cms';
import Loading from 'components/Loading';
import CMSGenericPage from 'modules/cms/CMSGenericPage';
import DataAvailabilityTable from 'modules/about/DataAvailabilityTable';
import { useDataAvailabilityQuery } from 'services/about';

const DataAvailabilityPage = () => {
  const requirementResponse = useDataAvailabilityQuery();
  const { data: requirements } = requirementResponse;

  const response = useContent('data-availability');
  const { data } = response;
  const intl = useIntl();

  return (
    <>
      <Helmet
        title={intl.formatMessage({
          id: 'application.title.dataAvailability',
          defaultMessage: 'Data Availability',
        })}
      />
      <Loading
        response={response}
        loadingWrapperClassName="justify-content-center p-3"
      >
        <CMSGenericPage data={data}>
          <Loading response={requirementResponse}>
            {!!requirements && <DataAvailabilityTable data={requirements} />}
          </Loading>
        </CMSGenericPage>
      </Loading>
    </>
  );
};

export default DataAvailabilityPage;
