import React from 'react';
import { useGetSelfAssessContent } from 'services/cms';
import SelfAssessProfileFooter from './SelfAssessProfileFooter';
import ComplianceRate from './ComplianceRate';
import styles from './SelfAssessProfileSummary.module.scss';
import {
  useGetProfileById,
  useGetProfileSummaryQuery,
} from 'services/selfAssess';
import SummaryAccordion from './SummaryAccordion';

const SelfAssessProfileSummary = () => {
  const { data: summary } = useGetProfileSummaryQuery();
  const { data: cmsContent } = useGetSelfAssessContent();
  const profile = useGetProfileById();

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.main}>
          {summary && <SummaryAccordion accordionData={summary} />}
        </div>
        <div className={styles.complianceRate}>
          <ComplianceRate complianceRate={profile?.overallPercentage} />
        </div>
      </div>
      {cmsContent && <SelfAssessProfileFooter data={cmsContent} />}
    </div>
  );
};

export default SelfAssessProfileSummary;
