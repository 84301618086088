import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IndicatorDetailResponse } from 'ApiServices';
import { GetMeasureDetailModel, IndicatorDetail } from 'AppTypes';
import config from 'config';

export const measureApi = createApi({
  reducerPath: 'measureApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiPrimary}/v1/`,
  }),
  endpoints: (builder) => ({
    getMeasureData: builder.query<IndicatorDetail[], GetMeasureDetailModel>({
      query: (body) => ({
        url: 'get-measure-data',
        method: 'post',
        body,
      }),
      keepUnusedDataFor: 900,
      transformResponse: (response: IndicatorDetailResponse[]) => {
        return response.map((indicator) => {
          return indicator;
        });
      },
    }),
  }),
});

export const { useGetMeasureDataQuery } = measureApi;
