import { ReactComponent as CollapsedPillIcon } from 'assets/icons/collapsed-pill.svg';
import { ReactComponent as ExpandedPillIcon } from 'assets/icons/expanded-pill.svg';
import { ReactComponent as ExpandedPillFilledIcon } from 'assets/icons/expanded-pill-filled.svg';

export function ExpandIcon(props: {
  isCollapsed: boolean;
  isInverted: boolean;
  useFillVariantWhenExpanded?: boolean;
  size: 'lg' | 'sm';
}) {
  const svgProps: React.SVGProps<SVGSVGElement> = {
    fill:
      !props.isCollapsed && props.isInverted
        ? 'var(--bs-white)'
        : 'var(--bs-primary)',
  };

  return props.isCollapsed ? (
    <CollapsedPillIcon {...svgProps} />
  ) : props.useFillVariantWhenExpanded ? (
    <ExpandedPillFilledIcon />
  ) : (
    <ExpandedPillIcon {...svgProps} />
  );
}
