import React from 'react';
import Button, { ButtonProps } from './Button';
import styles from './BaseSquareButton.module.scss';
import clsx from 'clsx';

type Props = {
  inverted?: boolean;
  icon: React.ReactNode;
  message: React.ReactNode;
} & ButtonProps;

const BaseSquareButton = ({ inverted, icon, message, ...btnProps }: Props) => {
  return (
    <Button
      variant="light"
      type="button"
      {...btnProps}
      className={clsx(
        styles.btn,
        inverted && styles.inverted,
        btnProps.className
      )}
    >
      <div className={styles.icon}>{icon}</div>
      <span className={styles.label}>{message}</span>
    </Button>
  );
};

export default React.memo(BaseSquareButton);
