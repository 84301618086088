import { ItemWithMeasureCount, CompareResultDataItem } from 'AppTypes';

export function sortByMeasureCountAndName(
  measureCountKeyToCompare: keyof CompareResultDataItem
) {
  return function (a: CompareResultDataItem, b: CompareResultDataItem) {
    if (a[measureCountKeyToCompare] === b[measureCountKeyToCompare]) {
      return a.shortTitle.localeCompare(b.shortTitle);
    }

    return a[measureCountKeyToCompare] < b[measureCountKeyToCompare] ? 1 : -1;
  };
}

export function remapCompareResultItems(
  items: CompareResultDataItem[],
  measureCountKey: keyof CompareResultDataItem
) {
  return items
    .map(
      (item) =>
        ({
          ...item,
          measureCount:
            measureCountKey in item
              ? item[measureCountKey as keyof CompareResultDataItem] ?? 0
              : 0,
        } as ItemWithMeasureCount<CompareResultDataItem>)
    )
    .sort(
      sortByMeasureCountAndName(measureCountKey as keyof CompareResultDataItem)
    );
}
