import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { getRoute, routerDict } from 'utils/router';
import styles from './NavBar.module.scss';
import { Link } from 'react-router-dom';
import NavDropdownLink from 'components/NavDropdownLink';
import { useGetShellContent } from 'services/cms';
import HSFinder from 'modules/products/HSFinder';

const NavBar = () => {
  const intl = useIntl();
  const { data } = useGetShellContent();

  const [showProductFinder, setShowProductFinder] = useState(false);

  const handleClose = () => setShowProductFinder(false);
  const handleOpen = () => setShowProductFinder(true);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="primary"
      variant="dark"
      className={styles.navbar}
    >
      <Container fluid="xxl" className="px-lg-0">
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto" style={{ maxHeight: '100px' }} navbarScroll>
            <Link to={getRoute('home')} className={'nav-link'}>
              <FormattedMessage id="navbar.home" defaultMessage="Home" />
            </Link>
            <NavLink to={getRoute('search')} className={'nav-link'}>
              <FormattedMessage id="navbar.search" defaultMessage="Search" />
            </NavLink>
            <NavLink to={getRoute('compare')} className={'nav-link'}>
              <FormattedMessage id="navbar.compare" defaultMessage="Compare" />
            </NavLink>
            <NavLink to={getRoute('selfAssessment')} className={'nav-link'}>
              <FormattedMessage
                id="navbar.selfAssess"
                defaultMessage="Self Assess"
              />
            </NavLink>
          </Nav>
          <Nav>
            <NavDropdownLink
              align="end"
              id="navbar.help"
              className={styles.navDropdown}
              title={intl.formatMessage({
                id: 'navbar.help',
                defaultMessage: 'Help',
              })}
              renderMenuOnMount={true}
              basePath={routerDict.help}
            >
              <NavLink to={getRoute('faq')} className={styles.menuItem}>
                <NavDropdown.Item as="span">
                  <FormattedMessage id="navbar.faq" defaultMessage="FAQ" />
                </NavDropdown.Item>
              </NavLink>
              <NavDropdown.Item
                className={styles.menuItem}
                onClick={handleOpen}
              >
                <FormattedMessage
                  id="navbar.productSearch"
                  defaultMessage="Product Search"
                />
              </NavDropdown.Item>
            </NavDropdownLink>
            <NavDropdownLink
              align="end"
              id="navbar.about"
              className={styles.navDropdown}
              title={intl.formatMessage({
                id: 'navbar.about',
                defaultMessage: 'About',
              })}
              renderMenuOnMount={true}
              basePath={routerDict.about}
            >
              <NavLink
                to={getRoute('dataAvailability')}
                className={styles.menuItem}
              >
                <NavDropdown.Item as="span">
                  <FormattedMessage
                    id="navbar.dataAvailability"
                    defaultMessage="Data Availability"
                  />
                </NavDropdown.Item>
              </NavLink>
              <NavLink to={getRoute('methodology')} className={styles.menuItem}>
                <NavDropdown.Item as="span">
                  <FormattedMessage
                    id="navbar.methodology"
                    defaultMessage="Methodology"
                  />
                </NavDropdown.Item>
              </NavLink>
            </NavDropdownLink>
            <NavDropdownLink
              align="end"
              id="navbar.resources"
              className={styles.navDropdown}
              title={intl.formatMessage({
                id: 'navbar.resources',
                defaultMessage: 'Resources',
              })}
              renderMenuOnMount={true}
              basePath={routerDict.resources}
            >
              {data?.resourceMenuItems?.map((item, index) => (
                <NavDropdown.Item
                  key={index}
                  href={item.url}
                  target="_blank"
                  className={styles.menuItem}
                >
                  {item.text}
                </NavDropdown.Item>
              ))}
            </NavDropdownLink>
          </Nav>
        </Navbar.Collapse>
        <HSFinder
          show={showProductFinder}
          onHide={handleClose}
          fullscreen="md-down"
          scrollable={true}
          size="lg"
        />
      </Container>
    </Navbar>
  );
};

export default NavBar;
