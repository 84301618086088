import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ProductByHSRevResponse, ProductSearchItemResponse } from 'ApiServices';
import {
  NormalizedProductByHSRev,
  ProductByHSRevMap,
  ProductsByHSRev,
  ProductSearchItem,
} from 'AppTypes';
import config from 'config';
import { sortBy } from 'lodash';
const NTMSATAPI_KEY = `${config.apiSecondaryKey}`;
console.log(NTMSATAPI_KEY);
export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiSecondary}/`,
  }),
  endpoints: (builder) => ({
    productsByLatestHSRev: builder.query<ProductByHSRevMap, void>({
      query: () => ({
        url: 'products-by-latest-hs-rev',
        headers: { 'x-API-key': `${config.apiSecondaryKey}` },
        params: {
          revCode: 'H6',
        },
      }),
      transformResponse: (response: ProductByHSRevResponse[]) => {
        const mapping: ProductByHSRevMap = {};

        for (const item of response) {
          mapping[item.Code] = {
            code: item.Code,
            name: item.Name,
            parentCode: item.ParentCode,
          };
        }

        return mapping;
      },
      keepUnusedDataFor: 900,
    }),
    searchProducts: builder.query<
      ProductSearchItem[],
      {
        countryCode: string;
        keyword: string | undefined;
        year: string;
        level?: number;
      }
    >({
      query: ({ countryCode, keyword, year, level = 6 }) => ({
        url: 'ntm-products-by-year',
        headers: { 'x-API-key': `${config.apiSecondaryKey}` },
        params: {
          level,
          year,
          reporterCode: countryCode,
          keyword,
        },
      }),
      transformResponse: (
        response: ProductSearchItemResponse[],
        meta,
        { keyword }
      ) => {
        const itemsStartWithSearchString: ProductSearchItemResponse[] = [];
        const itemsNotStartWithSearchString: ProductSearchItemResponse[] = [];

        for (const item of response) {
          if (keyword && item.Code.startsWith(keyword)) {
            itemsStartWithSearchString.push(item);
          } else {
            itemsNotStartWithSearchString.push(item);
          }
        }

        return itemsStartWithSearchString
          .concat(itemsNotStartWithSearchString)
          .map((item) => ({ code: item.Code, name: item.Name }));
      },
    }),
  }),
});

function buildProductsHashTable(products: NormalizedProductByHSRev[]) {
  const hashTable: Record<string, Array<NormalizedProductByHSRev>> = {};

  for (const product of products) {
    const parentCode = product.parentCode ?? 'root';
    if (!hashTable[parentCode]) {
      hashTable[parentCode] = [product];
    } else {
      hashTable[parentCode] = [...hashTable[parentCode], product];
    }
  }

  return hashTable;
}

function getProductTree(
  hashTable: Record<string, Array<NormalizedProductByHSRev>>,
  code: string
): ProductsByHSRev {
  return (
    hashTable[code]?.map((item) => ({
      code: item.code,
      name: item.name,
      children: getProductTree(hashTable, item.code),
    })) ?? []
  );
}

export function getProductsByLatestHSRev(
  map: ProductByHSRevMap,
  searchInput?: string
) {
  let filteredMap: ProductByHSRevMap = {};
  const hasSearchInput = !!searchInput?.trim();

  if (hasSearchInput) {
    const normalizedSearchInput = searchInput!.trim().toLowerCase();
    for (const item of Object.values(map)) {
      if (
        item.code.toLowerCase().includes(normalizedSearchInput) ||
        item.name.toLowerCase().includes(normalizedSearchInput)
      ) {
        filteredMap[item.code] = item;

        addAllParentsToMap(filteredMap, map, item);
      }
    }
  } else {
    filteredMap = map;
  }

  /**
   * We don't want to sort the roman numerals using the traditional way, so split them into 2 arrays,
   * the one with actual digit codes will be sorted
   */
  const searchedItems = Object.values(filteredMap);
  const romanSections = searchedItems.filter((item) => item.parentCode == null);
  const nonRomanSections = searchedItems.filter(
    (item) => item.parentCode != null
  );
  const arrayToBuildHashTable = [
    ...romanSections,
    ...sortBy(nonRomanSections, 'code'),
  ];
  const hashTable = buildProductsHashTable(arrayToBuildHashTable);
  const rootItems = getProductTree(hashTable, 'root');

  return rootItems;
}

function addAllParentsToMap(
  map: ProductByHSRevMap,
  originalMap: ProductByHSRevMap,
  item: NormalizedProductByHSRev
) {
  if (item.parentCode) {
    map[item.parentCode] = originalMap[item.parentCode];
    addAllParentsToMap(map, originalMap, originalMap[item.parentCode]);
  }
}

export const { useSearchProductsQuery, useProductsByLatestHSRevQuery } =
  productApi;
