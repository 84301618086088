import React, { useCallback } from 'react';
import { AssessProfileMeasureIndicator } from 'AppTypes';
import { FormattedMessage } from 'react-intl';
import styles from './AssessMeasureDetailsNavigation.module.scss';
import { useSummaryNavigate } from 'modules/self-assess/profile/useSummaryNavigate';
// eslint-disable-next-line no-restricted-imports
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { getRoute } from 'utils/router';
import { useGetSelfAssessParams } from 'hooks/useGetSelfAssessParams';

type Props = {
  indicators: AssessProfileMeasureIndicator[];
  goToNextMeasure?: () => void;
};

const AssessMeasureDetailsNavigation = ({ indicators }: Props) => {
  const { profileId } = useGetSelfAssessParams();
  const {
    goToNextMeasure,
    goToPreviousMeasure,
    isSelectingFirstItem,
    isSelectingLastItem,
  } = useSummaryNavigate();

  const onClickPreviousMeasureBtn = useCallback(() => {
    goToPreviousMeasure();
  }, [goToPreviousMeasure]);

  const onClickNextMeasureBtn = useCallback(() => {
    goToNextMeasure();
  }, [goToNextMeasure]);

  return (
    <>
      {!isSelectingFirstItem && (
        <Button
          type="button"
          variant="outline-primary"
          onClick={onClickPreviousMeasureBtn}
          className={styles.navigateBtn}
        >
          <FormattedMessage
            id="button.action.previousMeasure"
            defaultMessage="Previous"
          />
        </Button>
      )}
      {!isSelectingLastItem && (
        <Button
          type="button"
          variant="outline-primary"
          onClick={onClickNextMeasureBtn}
          className={styles.navigateBtn}
        >
          <FormattedMessage
            id="button.action.nextMeasure"
            defaultMessage="Next"
          />
        </Button>
      )}
      {isSelectingLastItem && (
        <NavLink
          to={getRoute('fullSelfAssessmentProfileSummaryById', profileId)}
        >
          <Button
            type="button"
            variant="outline-primary"
            className={styles.navigateBtn}
          >
            <FormattedMessage
              id="selfAssess.nav.summary"
              defaultMessage="Summary"
            />
          </Button>
        </NavLink>
      )}
    </>
  );
};

export default React.memo(AssessMeasureDetailsNavigation);
