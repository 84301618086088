import Button, { DownloadButton } from 'components/Button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  createSearchParams,
  NavLink,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { getRoute, routerDict } from 'utils/router';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import styles from './SelfAssessProfile.module.scss';
import { useIntersectionObserver } from 'usehooks-ts';
import {
  fetchSelfAssessReportFile,
  useDeleteProfileMutation,
  useGetCategorizedProfilesQuery,
  useGetProfileById,
  useGetProfileSummaryQuery,
} from 'services/selfAssess';
import Loading from 'components/Loading';
import { MeasureFilterViewContextProvider } from 'modules/app/MeasureFilterViewContext';
import { getFlagCode } from 'utils/flags';
import {
  useGetFromCountriesById,
  useGetToCountriesById,
} from 'services/countries';
import { useGetSelfAssessParams } from 'hooks/useGetSelfAssessParams';
import { toast } from 'react-toastify';
import QuickChangeSelfAssessProfile from 'modules/self-assess/profile/QuickChangeSelfAssessProfile';
import QuickCreateSelfAssessProfileDialog from 'modules/self-assess/profile/QuickCreateSelfAssessProfileDialog';
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import { AssessProfile } from 'AppTypes';

const SelfAssessProfileEmpty = () => {
  const [deleteProfile, { isLoading, isSuccess, isUninitialized }] =
    useDeleteProfileMutation();
  const { profileId } = useGetSelfAssessParams();
  const profile = useGetProfileById();
  const navigate = useNavigate();
  const [profileToDelete, setProfileToDelete] = useState<
    AssessProfile | undefined
  >(undefined);

  useEffect(() => {
    if (!isUninitialized && !isLoading) {
      if (isSuccess) {
        toast.success(
          <>
            <strong>
              <FormattedMessage
                id="message.profileDeleteSucceed"
                defaultMessage="Profile deleted"
              />
            </strong>
          </>
        );
        navigate({
          pathname: getRoute('selfAssessment'),
          search: `${createSearchParams({
            profileName: profileToDelete?.profileName || '',
            partner: profileToDelete?.fromCountryCode || '',
            reporterId: profileToDelete?.toCountryCode || '',
            productCode: profileToDelete?.productCode || '',
          })}`,
        });
      }
    }
  }, [
    isLoading,
    isSuccess,
    isUninitialized,
    navigate,
    profileToDelete?.fromCountryCode,
    profileToDelete?.productCode,
    profileToDelete?.profileName,
    profileToDelete?.toCountryCode,
  ]);

  return (
    <div className={styles.noResults}>
      <div className={styles.message}>
        <FormattedMessage
          id="selfAssess.message.emptyProfilePrompt"
          defaultMessage="This profile does not have applicable measures."
        />
      </div>
      {profile && (
        <div className={styles.actions}>
          <NavLink
            to={{
              pathname: getRoute('selfAssessment'),
              search: `${createSearchParams({
                profileName: profile?.profileName || '',
                partner: profile?.fromCountryCode || '',
                reporterId: profile?.toCountryCode || '',
                productCode: profile?.productCode || '',
              })}`,
            }}
          >
            <Button disabled={isLoading}>
              <FormattedMessage
                id="button.action.goBack"
                defaultMessage="Go back"
              />
            </Button>
          </NavLink>
          <Button
            variant="danger"
            disabled={isLoading}
            onClick={() => {
              if (profileId) {
                setProfileToDelete(profile);
                deleteProfile(profileId);
              }
            }}
          >
            <FormattedMessage
              id="button.action.deleteProfile"
              defaultMessage="Delete this profile"
            />
          </Button>
        </div>
      )}
    </div>
  );
};

const SelfAssessProfile = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const { data: fromCountriesById } = useGetFromCountriesById();
  const { data: toCountriesById } = useGetToCountriesById();
  const profile = useGetProfileById();
  const { data: categorizedProfiles } = useGetCategorizedProfilesQuery();
  const response = useGetProfileSummaryQuery();
  const { data: summary } = response;
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const onClickDownloadReport = useCallback(async () => {
    if (profile?.assessmentProfileId) {
      setLoading(true);
      try {
        const blobData = await fetchSelfAssessReportFile(
          profile.assessmentProfileId
        );
        saveAs(
          blobData,
          `self-assess-${
            profile.profileName
          }-${new Date().toLocaleString()}.xlsx`
        );
      } catch (e) {
        toast.error(
          <FormattedMessage
            id="message.errorOccurred"
            defaultMessage="An unexpected error occurred, please try again"
          />
        );
      } finally {
        setLoading(false);
      }
    }
  }, [profile?.assessmentProfileId, profile?.profileName]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <div className={styles.profileName}>
            <span className={styles.profileNameLabel}>
              <FormattedMessage
                id="selfAssess.form.profileLabel"
                defaultMessage="Profile"
              />
            </span>
            : {profile?.profileName}
            <QuickChangeSelfAssessProfile
              className="d-inline ms-3"
              profiles={categorizedProfiles?.nonEmptyProfiles ?? []}
            />
            <Button
              variant="outline-primary"
              size="sm"
              className={clsx(styles.switchProfileBtn, 'ms-2')}
              onClick={() => void setOpenCreateDialog(true)}
              title={intl.formatMessage({
                id: 'button.title.createNewAssessmentProfile',
                defaultMessage: 'Create a new assessment profile',
              })}
            >
              <PlusIcon />
            </Button>
            <QuickCreateSelfAssessProfileDialog
              open={openCreateDialog}
              setOpen={setOpenCreateDialog}
            />
          </div>
          <div>
            {!!profile && (
              <ul className={styles.profileCriteria}>
                <li>
                  <FormattedMessage
                    id="selfAssess.form.fromLabel"
                    defaultMessage="From"
                  />
                  :{' '}
                  <span className={styles.countryName}>
                    {fromCountriesById[profile.fromCountryCode] && (
                      <span
                        className={`fi fi-${getFlagCode(
                          fromCountriesById[profile.fromCountryCode]?.iso2
                        )} me-1`}
                        aria-hidden={true}
                      />
                    )}
                    {profile.fromCountry ?? profile.fromCountryCode}
                  </span>
                </li>
                <li>
                  <FormattedMessage
                    id="selfAssess.form.toLabel"
                    defaultMessage="To"
                  />
                  :{' '}
                  <span className={styles.countryName}>
                    {toCountriesById[profile.toCountryCode] && (
                      <span
                        className={`fi fi-${getFlagCode(
                          toCountriesById[profile.toCountryCode]?.iso2
                        )} me-1`}
                        aria-hidden={true}
                      />
                    )}
                    {profile.toCountry ?? profile.toCountryCode}
                  </span>
                </li>
                <li>
                  <FormattedMessage
                    id="selfAssess.form.productLabel"
                    defaultMessage="Product"
                  />
                  : {profile?.productDisplayName}
                </li>
              </ul>
            )}
          </div>
        </div>
        <div>
          {Array.isArray(summary) && summary.length > 0 && (
            <DownloadButton
              onClick={onClickDownloadReport}
              disabled={loading}
            />
          )}
        </div>
      </div>
      <Loading response={response}>
        {Array.isArray(summary) && summary.length > 0 ? (
          <MeasureFilterViewContextProvider>
            <div className={styles.navArea} ref={ref}>
              <div className={styles.navContainer}>
                <NavLink
                  to={routerDict.assessComplianceCheck}
                  className={styles.nav}
                >
                  <FormattedMessage
                    id="selfAssess.nav.complianceCheck"
                    defaultMessage="Compliance check"
                  />
                </NavLink>
              </div>

              <div className={styles.end}>
                <div className={styles.navContainer}>
                  <NavLink to={routerDict.assessSummary} className={styles.nav}>
                    <FormattedMessage
                      id="selfAssess.nav.summary"
                      defaultMessage="Summary"
                    />
                  </NavLink>
                </div>
                {/* <div className={styles.navContainer}>
                  <NavLink
                    to={routerDict.assessUsefulInfo}
                    className={styles.nav}
                  >
                    <FormattedMessage
                      id="selfAssess.nav.usefulInfo"
                      defaultMessage="Useful info"
                    />
                  </NavLink>
                </div> */}
              </div>
            </div>
            <Outlet context={{ isVisible }} />
          </MeasureFilterViewContextProvider>
        ) : (
          <SelfAssessProfileEmpty />
        )}
      </Loading>
    </div>
  );
};

export function useSelfAssessContext() {
  return useOutletContext<boolean>();
}

export default SelfAssessProfile;
