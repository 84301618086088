import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MetadataResponse } from 'ApiServices';
import config from 'config';

export const metadataApi = createApi({
  reducerPath: 'metadataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiPrimary}/v1/`,
  }),
  endpoints: (builder) => ({
    metadataInfo: builder.query<MetadataResponse, string>({
      query: (reporterId) => ({
        url: 'get-meta-data',
        params: {
          reporterId,
        },
      }),
    }),
  }),
});

export const { useMetadataInfoQuery } = metadataApi;
