import clsx from 'clsx';
import Button from 'components/Button';
import React, { useCallback, useMemo } from 'react';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, {
  ActionMeta,
  ContainerProps,
  createFilter,
  GroupBase,
  OnChangeValue,
  Props,
  components,
} from 'react-select';

type CustomSelectProps = {
  error?: string;
  ref?: React.Ref<any>;
  labelKey?: string;
  valueKey?: string;
  hasAllOption?: boolean;
  allOptionLabel?: React.ReactElement;
  allOptionValue?: string;
  showNavigationButtons?: boolean;
  onChangeValue?: (value: any) => void;
};

const OPTION_ALL = {
  label: <FormattedMessage id="select.option.all" defaultMessage="All" />,
  value: 'ALL',
};

const NTMSelect = React.forwardRef(
  <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
  >(
    {
      error,
      className,
      labelKey = 'label',
      valueKey = 'value',
      hasAllOption,
      getOptionLabel,
      getOptionValue,
      options,
      allOptionLabel,
      allOptionValue,
      onChange,
      onChangeValue,
      showNavigationButtons,
      ...props
    }: Props<Option, IsMulti, Group> & CustomSelectProps,
    ref?: React.Ref<any>
  ) => {
    const getOptionLabelFn = useCallback(
      (option: Option) => {
        return getOptionLabel
          ? getOptionLabel(option)
          : (option as unknown as Record<string, any>)[labelKey];
      },
      [getOptionLabel, labelKey]
    );

    const getOptionValueFn = useCallback(
      (option: Option) => {
        return getOptionValue
          ? getOptionValue(option)
          : (option as unknown as Record<string, any>)[valueKey];
      },
      [getOptionValue, valueKey]
    );

    const selectOptions = useMemo(() => {
      if (!hasAllOption) return options;
      return options?.length
        ? [
            {
              [labelKey]: allOptionLabel ?? OPTION_ALL.label,
              [valueKey]: allOptionValue ?? OPTION_ALL.value,
            } as Option,
            ...options,
          ]
        : options;
    }, [
      allOptionLabel,
      allOptionValue,
      hasAllOption,
      labelKey,
      options,
      valueKey,
    ]);

    const onChangeHandler = useCallback(
      (
        newValue: OnChangeValue<Option, IsMulti>,
        actionMeta: ActionMeta<Option>
      ) => {
        onChange?.(newValue, actionMeta);
        onChangeValue?.(
          Array.isArray(newValue)
            ? newValue.map((item) => item[valueKey])
            : (newValue as unknown as Record<string, any>)?.[valueKey]
        );
      },
      [onChange, onChangeValue, valueKey]
    );

    return (
      <div
        className={clsx('select-wrapper', !!error && 'select-wrapper--error')}
      >
        <div className={clsx(showNavigationButtons && 'd-flex')}>
          <Select
            menuPlacement="auto"
            filterOption={createFilter({ ignoreAccents: false })}
            {...props}
            getOptionLabel={getOptionLabelFn}
            getOptionValue={getOptionValueFn}
            options={selectOptions}
            className={clsx(
              'react-select',
              error && 'react-select--error',
              className
            )}
            classNamePrefix="react-select"
            ref={ref}
            noOptionsMessage={NoOptionMessage}
            loadingMessage={LoadingMessage}
            onChange={onChangeHandler}
            styles={
              props.menuPortalTarget === document.body
                ? { menuPortal: (provided) => ({ ...provided, zIndex: 9999 }) }
                : undefined
            }
            components={{
              ...props.components,
              SelectContainer:
                props.components?.SelectContainer ||
                (!props.isMulti && showNavigationButtons)
                  ? SelectContainer
                  : components.SelectContainer,
            }}
          />
        </div>
        {!!error && (
          <div className="select-wrapper__subscript text-error">
            <div>{error}</div>
          </div>
        )}
      </div>
    );
  }
);

function NoOptionMessage({ inputValue }: { inputValue: string }) {
  return (
    <FormattedMessage
      id="select.message.noOptions"
      defaultMessage="No options"
    />
  );
}

function LoadingMessage({ inputValue }: { inputValue: string }) {
  return (
    <FormattedMessage id="select.message.loading" defaultMessage="Loading..." />
  );
}

const SelectContainer = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  children,
  ...props
}: ContainerProps<Option, IsMulti, Group>) => {
  const value = props.getValue();
  const intl = useIntl();
  const currentSelectedIndex = props.options.findIndex(
    (option) => option === value?.[0]
  );

  return (
    <>
      <Button
        size="sm"
        variant="light"
        disabled={currentSelectedIndex === 0}
        title={intl.formatMessage({
          id: 'button.message.previous',
          defaultMessage: 'Previous',
        })}
        className="me-2"
        onClick={() =>
          props.selectOption(props.options[currentSelectedIndex - 1] as Option)
        }
      >
        <ChevronLeft />
      </Button>
      <components.SelectContainer
        {...props}
        className={clsx(props.className, 'flex-grow-1')}
      >
        {children}
      </components.SelectContainer>
      <Button
        size="sm"
        variant="light"
        disabled={currentSelectedIndex === props.options.length - 1}
        title={intl.formatMessage({
          id: 'button.message.next',
          defaultMessage: 'Next',
        })}
        className="ms-2"
        onClick={() =>
          props.selectOption(props.options[currentSelectedIndex + 1] as Option)
        }
      >
        <ChevronRight />
      </Button>
    </>
  );
};

export default NTMSelect as <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group> & CustomSelectProps
) => React.ReactElement;
