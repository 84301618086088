import clsx from 'clsx';
import React from 'react';
import styles from './SectionHeader.module.scss';

type Props = {
  title: React.ReactNode;
  icon: React.ReactNode;
  children?: React.ReactNode;
  wrapperClassName?: string;
  iconClassName?: string;
};

const SectionHeader = ({
  title,
  icon,
  children,
  wrapperClassName,
  iconClassName,
}: Props) => {
  return (
    <div className={clsx(styles.header, wrapperClassName)}>
      <div className={clsx(styles.info, 'section-header-info')}>
        <div
          className={clsx(styles.icon, iconClassName, 'section-header-icon')}
        >
          {icon}
        </div>
        <div className={clsx(styles.name, 'section-header-name')}>{title}</div>
      </div>
      {children && (
        <div className={clsx(styles.action, 'section-header-content')}>
          {children}
        </div>
      )}
    </div>
  );
};

export default SectionHeader;
