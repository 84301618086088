import React from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { FormattedMessage } from 'react-intl';
import styles from './NoResultFound.module.scss';

const NoResultFound = () => {
  return (
    <div className={styles.wrapper}>
      <SearchIcon />
      <div className={styles.message}>
        <FormattedMessage
          id="search.info.noResultFound"
          defaultMessage="No result found"
        />
      </div>
    </div>
  );
};

export default NoResultFound;
