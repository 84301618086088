import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompareCountriesModel } from 'AppTypes';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as GlobeIcon } from 'assets/icons/globe.svg';
import { ReactComponent as ProductIcon } from 'assets/icons/product.svg';
import styles from './CompareForm.module.scss';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/router';
import { CountrySelectRHF } from 'components/Select';
import ProductSelect from 'components/Select/ProductSelect';
import clsx from 'clsx';
import { CompareType } from 'modules/constants';
import { useGetCompareCountriesParams } from 'hooks/useGetCompareParams';
import {
  useGetFromCountriesQuery,
  useGetToCountriesQuery,
} from 'services/countries';

const compareProductsSchema = yup.object({
  partner: yup.string().required().label('From country'),
  reporterId: yup
    .array()
    .of(yup.string())
    .min(2)
    .required()
    .label('To country'),
  productId: yup.string().required().label('Product'),
});

type Props = {
  horizontalLayout?: boolean;
};

const CompareCountriesForm = ({ horizontalLayout }: Props) => {
  const navigate = useNavigate();
  const data = useGetCompareCountriesParams();

  const formMethods = useForm<CompareCountriesModel>({
    resolver: yupResolver(compareProductsSchema),
    defaultValues: data,
  });

  const { handleSubmit, watch } = formMethods;

  const [fromValue, toValue] = watch(['partner', 'reporterId']);

  const onSubmit = useCallback(
    (data: CompareCountriesModel) => {
      navigate({
        pathname: getRoute('compare'),
        search: `${createSearchParams({
          ...data,
          reporterId: data.reporterId.join(','),
          type: CompareType.COUNTRIES_INTL_STANDARDS,
        })}`,
      });
    },
    [navigate]
  );

  const { data: fromCountries = [], isFetching: isFetchingFromCountries } =
    useGetFromCountriesQuery();
  const { data: toCountries = [], isFetching: isFetchingToCountries } =
    useGetToCountriesQuery();

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={clsx(styles.form, horizontalLayout && styles.horizontal)}
      >
        <div className={styles.formBody}>
          <div className={styles.formGroup}>
            <label htmlFor="compare.form.from">
              <GlobeIcon />
              <FormattedMessage
                id="compare.form.fromLabel"
                defaultMessage="From"
              />
            </label>
            <div className={styles.formField}>
              <CountrySelectRHF
                name="partner"
                id="compare.form.from"
                options={fromCountries}
                isLoading={isFetchingFromCountries}
                isOptionDisabled={(option) => toValue?.includes(option.code)}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="compare.form.to">
              <GlobeIcon />
              <FormattedMessage id="compare.form.toLabel" defaultMessage="To" />
            </label>
            <div className={styles.formField}>
              <CountrySelectRHF
                name="reporterId"
                id="compare.form.to"
                options={toCountries}
                isLoading={isFetchingToCountries}
                isOptionDisabled={(option) => option.code === fromValue}
                isMulti
              />
            </div>
          </div>
          <div className={clsx(styles.formGroup, styles.product)}>
            <label htmlFor="compare.form.productCode">
              <ProductIcon />
              <FormattedMessage
                id="compare.form.productLabel"
                defaultMessage="Product"
              />
            </label>
            <div className={styles.formField}>
              <ProductSelect name="productId" id="compare.form.productCode" />
            </div>
          </div>
        </div>
        <div className={styles.formAction}>
          <Button type="submit" variant="secondary" hasCornerCut={true}>
            {
              <FormattedMessage
                id="compare.form.submitBtn"
                defaultMessage="Compare"
              />
            }
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default CompareCountriesForm;
