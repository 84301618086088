import React, { useCallback, useMemo } from 'react';
import { ActionMeta, components, OptionProps, SingleValue } from 'react-select';
import { Country } from 'AppTypes';
import { FormattedMessage } from 'react-intl';
import NTMSelect from './NTMSelect';
import { getFlagCode } from 'utils/flags';

type Props = {
  name?: string;
  id: string;
  options: Country[];
  onChange: (value?: string) => void;
  valueKey?: string;
  isDisabled?: boolean;
};

export default function CountrySelect({
  name,
  id,
  options,
  onChange,
  valueKey,
  isDisabled,
}: Props) {
  const onChangeValue = useCallback(
    (newValue: SingleValue<Country>, actionMeta: ActionMeta<Country>) => {
      onChange(newValue?.code);
    },
    [onChange]
  );

  const value = useMemo(() => {
    return options.find((option) => option.code === valueKey);
  }, [options, valueKey]);

  return (
    <>
      <NTMSelect
        name={name}
        inputId={id}
        options={options}
        placeholder={
          <FormattedMessage
            id="select.placeholder.chooseCountry"
            defaultMessage="Choose country"
          />
        }
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        menuPortalTarget={document.body}
        value={value}
        onChange={onChangeValue}
        menuPlacement="auto"
        isMulti={false}
        components={{
          Option: CustomOption,
        }}
        formatOptionLabel={formatOptionLabel}
        isDisabled={isDisabled}
      />
    </>
  );
}

export function getOptionValue(country: Country) {
  return country.code;
}

export function getOptionLabel(country: Country) {
  return country.name;
}

export function formatOptionLabel(data: Country) {
  return (
    <>
      <span className={`fi fi-${getFlagCode(data.iso2)}`} aria-hidden={true} />
      <span className="ms-2">{data.name}</span>
    </>
  );
}

export function CustomOption({ children, ...props }: OptionProps<Country>) {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div className="d-flex">
        <div>
          <span
            className={`fi fi-${getFlagCode(data.iso2)}`}
            aria-hidden={true}
          />
        </div>
        <span className="ms-2">{data.name}</span>
      </div>
    </components.Option>
  );
}
