import React, { ChangeEvent, useCallback } from 'react';
import { Form, FormCheckProps } from 'react-bootstrap';

type Props = {
  items: FormCheckProps[];
  name: string;
  onChange?: (value: string) => void;
  wrapperClassName?: string;
  value?: string | null;
  disabled?: boolean;
};

let GROUP_COUNT = 0;

const RadioGroup = ({
  items,
  name,
  onChange,
  value,
  wrapperClassName,
  disabled,
}: Props) => {
  const COUNT = GROUP_COUNT++;

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    },
    [onChange]
  );

  return (
    <div className={wrapperClassName}>
      {items.map((item, index) => (
        <Form.Check
          key={index}
          type="radio"
          inline
          id={`radio-${COUNT}-${index}`}
          name={name}
          onChange={onInputChange}
          checked={item.value === value}
          disabled={disabled}
          {...item}
        />
      ))}
    </div>
  );
};

export default React.memo(RadioGroup);
