import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  SearchResultCountResponse,
  SearchResultDataResponse,
} from 'ApiServices';
import {
  DownloadSearchDataModel,
  MeasureGroupOverview,
  SearchModel,
  SearchResult,
  SearchSummary,
} from 'AppTypes';
import config from 'config';
import { MeasureFilterType, MeasureType } from 'modules/constants';
import {
  getStandardMeasureCode,
  isSearchingBetweenEUCountries,
} from 'utils/helpers';

function createSearchSummary(
  measures: MeasureGroupOverview[]
): SearchSummary | null {
  if (measures.length === 0) {
    return null;
  }

  const summary: SearchSummary = {
    total: 0,
    countBy: {
      [MeasureFilterType.SPS_TBT]: {
        [MeasureType.SPS]: 0,
        [MeasureType.TBT]: 0,
        [MeasureType.TECHNICAL_REGULATIONS]: 0,
        [MeasureType.CONFORMITY_ASSESSMENT]: 0,
        [MeasureType.OTHER]: 0,
      },
      [MeasureFilterType.TECHNICAL_CONFORMITY]: {
        [MeasureType.SPS]: 0,
        [MeasureType.TBT]: 0,
        [MeasureType.TECHNICAL_REGULATIONS]: 0,
        [MeasureType.CONFORMITY_ASSESSMENT]: 0,
        [MeasureType.OTHER]: 0,
      },
    },
  };

  for (const measure of measures) {
    summary.total +=
      measure.groupOption === MeasureFilterType.SPS_TBT
        ? measure.measureCount
        : 0;
    summary.countBy[measure.groupOption][measure.measureType] +=
      measure.measureCount;
  }

  return summary;
}

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiPrimary}/v1/`,
  }),
  endpoints: (builder) => ({
    searchResultCount: builder.query<SearchResultCountResponse[], SearchModel>({
      query: (body) => ({
        url: 'search-result-count',
        method: 'post',
        body,
      }),
      transformResponse: (response: SearchResultCountResponse[], meta, arg) => {
        return isSearchingBetweenEUCountries(arg.partner, arg.reporterId)
          ? []
          : response;
      },
    }),
    searchResultData: builder.query<SearchResult, SearchModel>({
      query: (body) => ({
        url: 'search-result-data',
        method: 'post',
        body,
      }),
      transformResponse: (response: SearchResultDataResponse[], meta, arg) => {
        const measureGroups = isSearchingBetweenEUCountries(
          arg.partner,
          arg.reporterId
        )
          ? []
          : response ?? [];

        return {
          summary: createSearchSummary(measureGroups),
          data: measureGroups.map((item) => ({
            ...item,
            ntmCode: getStandardMeasureCode(item.ntmCode),
          })),
        };
      },
    }),
  }),
});

const DOWNLOAD_SEARCH_API = `${config.apiPrimary}/v1/get-search-report`;

export function fetchSearchDataFile(params: DownloadSearchDataModel) {
  return fetch(DOWNLOAD_SEARCH_API, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.blob());
}

export const { useSearchResultCountQuery, useSearchResultDataQuery } =
  searchApi;
