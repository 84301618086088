import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { SelfAssessProfileForm } from 'AppTypes';
import styles from './CreateProfileForm.module.scss';
import { FormattedMessage } from 'react-intl';
import { CountrySelectRHF } from 'components/Select';
import { ReactComponent as GlobeIcon } from 'assets/icons/globe.svg';
import { ReactComponent as ProductIcon } from 'assets/icons/product.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import ProductSelect from 'components/Select/ProductSelect';
import Button from 'components/Button';
import { InputRHF } from 'components/Input';
import clsx from 'clsx';
import { useCreateProfileMutation } from 'services/selfAssess';
import { useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/router';
import {
  useGetFromCountriesQuery,
  useGetToCountriesQuery,
} from 'services/countries';
import { toast } from 'react-toastify';

const selfAssessSchema = yup.object({
  profileName: yup.string().required().label('Profile name'),
  partner: yup.string().required().label('From country'),
  reporterId: yup.string().required().label('To country'),
  productCode: yup.string().required().label('Product'),
});

type Props = {
  defaultValue?: SelfAssessProfileForm;
  canSave?: boolean;
  denseLayout?: boolean;
  customBtn?: React.ReactNode;
  onStartAssessmentSuccess?: () => void;
};

enum SubmissionType {
  NONE,
  SAVE,
  START_ASSESSMENT,
}

const CreateProfileForm = ({
  canSave,
  denseLayout,
  customBtn,
  defaultValue,
  onStartAssessmentSuccess,
}: Props) => {
  const navigate = useNavigate();
  const [createProfile, { data, isSuccess, isLoading }] =
    useCreateProfileMutation();
  const [submissionType, setSubmissionType] = useState<SubmissionType>(
    SubmissionType.NONE
  );

  const defaultFormValue = useMemo(
    (): SelfAssessProfileForm => ({
      profileName: defaultValue?.profileName ?? '',
      partner: defaultValue?.partner ?? '',
      reporterId: defaultValue?.reporterId ?? '',
      productCode: defaultValue?.productCode ?? '',
    }),
    [
      defaultValue?.partner,
      defaultValue?.productCode,
      defaultValue?.profileName,
      defaultValue?.reporterId,
    ]
  );

  const formMethods = useForm<SelfAssessProfileForm>({
    resolver: yupResolver(selfAssessSchema),
    defaultValues: defaultFormValue,
  });

  const { handleSubmit, watch, reset } = formMethods;

  const [fromValue, toValue] = watch(['partner', 'reporterId']);

  useEffect(() => {
    reset(defaultFormValue);
  }, [defaultFormValue, reset]);

  const { data: fromCountries = [], isFetching: isFetchingFromCountries } =
    useGetFromCountriesQuery();
  const { data: toCountries = [], isFetching: isFetchingToCountries } =
    useGetToCountriesQuery();

  const onFormSubmit = useCallback(
    (formValue: SelfAssessProfileForm) => {
      createProfile(formValue);
    },
    [createProfile]
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        <>
          <strong>
            <FormattedMessage
              id="message.profileCreateSucceed"
              defaultMessage="Profile created"
            />
          </strong>
        </>
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (data && isSuccess) {
      switch (submissionType) {
        case SubmissionType.SAVE:
          reset(defaultFormValue);
          break;
        case SubmissionType.START_ASSESSMENT:
          onStartAssessmentSuccess?.();
          navigate(getRoute('fullSelfAssessmentProfileById', data));
          break;
      }
    }
  }, [
    data,
    defaultFormValue,
    isSuccess,
    navigate,
    onStartAssessmentSuccess,
    reset,
    submissionType,
  ]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className={clsx(styles.form, denseLayout && styles.denseLayout)}
      >
        <div className={styles.formBody}>
          <div className={styles.formGroup}>
            <label htmlFor="selfAssess.form.name">
              <UserIcon />
              <FormattedMessage
                id="selfAssess.form.nameLabel"
                defaultMessage="Name"
              />
            </label>
            <div className={styles.formField}>
              <InputRHF
                name="profileName"
                id="selfAssess.form.name"
                aria-autocomplete="none"
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="selfAssess.form.from">
              <GlobeIcon />
              <FormattedMessage
                id="selfAssess.form.fromLabel"
                defaultMessage="From"
              />
            </label>
            <div className={styles.formField}>
              <CountrySelectRHF
                name="partner"
                id="selfAssess.form.from"
                options={fromCountries}
                isLoading={isFetchingFromCountries}
                isOptionDisabled={(option) => option.code === toValue}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="selfAssess.form.to">
              <GlobeIcon />
              <FormattedMessage
                id="selfAssess.form.toLabel"
                defaultMessage="To"
              />
            </label>
            <div className={styles.formField}>
              <CountrySelectRHF
                name="reporterId"
                id="selfAssess.form.to"
                options={toCountries}
                isLoading={isFetchingToCountries}
                isOptionDisabled={(option) => option.code === fromValue}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="selfAssess.form.productCode">
              <ProductIcon />
              <FormattedMessage
                id="selfAssess.form.productLabel"
                defaultMessage="Product"
              />
            </label>
            <div className={styles.formField}>
              <ProductSelect
                name="productCode"
                id="selfAssess.form.productCode"
                defaultValue={defaultValue?.productCode}
              />
            </div>
          </div>
        </div>
        <div className={styles.formAction}>
          {canSave && (
            <Button
              type="button"
              variant="light"
              disabled={isLoading}
              hasCornerCut={true}
              onClick={() => {
                setSubmissionType(SubmissionType.SAVE);
                handleSubmit(onFormSubmit)();
              }}
            >
              {
                <FormattedMessage
                  id="selfAssess.form.saveBtn"
                  defaultMessage="Save"
                />
              }
            </Button>
          )}
          {customBtn}
          <Button
            type="button"
            variant="secondary"
            disabled={isLoading}
            hasCornerCut={true}
            onClick={() => {
              setSubmissionType(SubmissionType.START_ASSESSMENT);
              handleSubmit(onFormSubmit)();
            }}
          >
            {
              <FormattedMessage
                id="selfAssess.form.startAssessBtn"
                defaultMessage="Start Assessment"
              />
            }
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default CreateProfileForm;
