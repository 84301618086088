import React from 'react';
import { useCompareProductsQuery } from 'services/compare';
import { useGetCompareProductsParams } from 'hooks/useGetCompareParams';
import Loading from 'components/Loading';
import CompareCore from 'modules/compare/products/CompareCore';
import { MeasureFilterViewContextProvider } from 'modules/app/MeasureFilterViewContext';
import NoResultFound from 'components/NoResultFound';
import styles from './CompareProducts.module.scss';
import { skipToken } from '@reduxjs/toolkit/query/react';

type Props = {
  isCompareProducts?: boolean;
};

const CompareProducts = ({ isCompareProducts = true }: Props) => {
  const { partner, reporterId, productId1, productId2 } =
    useGetCompareProductsParams();
  const response = useCompareProductsQuery(
    partner && reporterId && productId1 && productId2
      ? { partner, reporterId, productId1, productId2 }
      : skipToken
  );
  const { data } = response;

  return (
    <div className={styles.wrapper}>
      <Loading
        response={response}
        loadingWrapperClassName="justify-content-center"
      >
        {data &&
        Number(data.totalMeasures1 || 0) > 0 &&
        Number(data.totalMeasures2 || 0) > 0 ? (
          <MeasureFilterViewContextProvider>
            <CompareCore isCompareProducts={true} data={data} />
          </MeasureFilterViewContextProvider>
        ) : (
          <NoResultFound />
        )}
      </Loading>
    </div>
  );
};

export default CompareProducts;
