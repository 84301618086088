import { AnyAction, configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware, Epic } from 'redux-observable';
import rootEpics from './root-epics';
import config from 'config';
import rootReducers from './root-reducers';
import { StoreDeps } from 'StoreTypes';
import { routerMiddleware, createReduxHistory } from './history';
import { productApi } from 'services/products';
import { searchApi } from 'services/search';
import { measureApi } from 'services/measures';
import { cmsApi } from 'services/cms';
import { metadataApi } from 'services/metadata';
import { rtkQueryErrorDisplay } from './error-handlers';
import { authApi } from 'services/auth';
import { selfAssessApi } from 'services/selfAssess';
import { aboutApi } from 'services/about';
import { countriesApi } from 'services/countries';
import { compareApi } from 'services/compare';

type RootState = ReturnType<typeof rootReducers>;
export type AppEpic = Epic<AnyAction, AnyAction, RootState, StoreDeps>;

const epicMiddleware = createEpicMiddleware<
  AnyAction,
  AnyAction,
  RootState,
  StoreDeps
>({
  dependencies: {
    config,
    timestamp: () => Date.now(),
  },
});

export const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      routerMiddleware,
      productApi.middleware,
      searchApi.middleware,
      measureApi.middleware,
      cmsApi.middleware,
      metadataApi.middleware,
      authApi.middleware,
      selfAssessApi.middleware,
      aboutApi.middleware,
      countriesApi.middleware,
      compareApi.middleware,
      rtkQueryErrorDisplay,
      epicMiddleware
    ),
  devTools: process.env.NODE_ENV !== 'production',
});

export const history = createReduxHistory(store);

epicMiddleware.run(rootEpics);
