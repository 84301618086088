import React from 'react';
import { GenericPage } from 'AppTypes';
import { RemoteImage } from 'assets/remote';
import styles from './CMSGenericPage.module.scss';
import clsx from 'clsx';
import CustomAccordion from 'components/Accordion/CustomAccordion';
import { sanitize } from 'dompurify';

type Props = {
  data?: GenericPage;
  highlightLevel2?: boolean;
  children?: React.ReactNode;
};

const CMSGenericPage = ({ data, highlightLevel2, children }: Props) => {
  return (
    <>
      <section className={styles.banner}>
        {data?.bannerImage?.[0]?.url && (
          <RemoteImage url={data.bannerImage[0].url} />
        )}
        <div className={'sub-container'}>
          <h1 className={styles.title}>{data?.title}</h1>
        </div>
      </section>
      <section className={clsx(styles.body, 'sub-container')}>
        {data?.content && (
          <div
            className="mb-3"
            dangerouslySetInnerHTML={{
              __html: sanitize(data?.content),
            }}
          />
        )}

        <CustomAccordion
          data={data?.accordion}
          highlightLevel2={highlightLevel2}
        />

        {data?.contentBottom && (
          <div
            className="mt-3"
            dangerouslySetInnerHTML={{
              __html: sanitize(data?.contentBottom),
            }}
          />
        )}

        {children}
      </section>
    </>
  );
};

export default CMSGenericPage;
