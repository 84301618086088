import { HomePageContentSection } from 'AppTypes';
import Button from 'components/Button';
import { sanitize } from 'dompurify';
import React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

import styles from './Home.module.scss';

const ContentSection = ({ data }: { data?: HomePageContentSection }) => {
  return (
    <section>
      <h2 className={styles.sectionTitle}>
        <img src={data?.titleImage?.[0]?.url} alt="" />
        {data?.title}
      </h2>
      {data?.secondaryTitle && (
        <span className={styles.sectionDescription}>
          {data?.secondaryTitle}
        </span>
      )}
      {data?.description && (
        <div
          dangerouslySetInnerHTML={{ __html: sanitize(data?.description) }}
        />
      )}

      {data?.isTwitterWidget && data.twitterHandle && (
        <div className="text-center">
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="ITCnews"
            noHeader
            noFooter
            options={{ height: 400 }}
          />
        </div>
      )}
      {data?.ctaButtonLabel && data.ctaButtonURL && (
        <Button
          variant="outline-primary"
          href={data.ctaButtonURL}
          rel="noreferrer"
          target="_blank"
        >
          {data.ctaButtonLabel}
        </Button>
      )}
    </section>
  );
};

export default ContentSection;
