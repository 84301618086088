import React, { useContext, useState } from 'react';
import { Column, Row } from '@tanstack/react-table';
import {
  CompareCountryFirstLevel,
  CompareCountryNtmCodeLevel,
  CompareIndicatorResult,
} from 'AppTypes';
import styles from './CompareMultipleOptionsTable.module.scss';
import { useCompareCountriesIndicatorQuery } from 'services/compare';
import { useGetCompareCountriesParams } from 'hooks/useGetCompareParams';
import { skipToken } from '@reduxjs/toolkit/query/react';
import Loading from 'components/Loading';
import { COLUMN_ID } from 'modules/compare/countries/useMultipleOptionsTable';
import { ReactComponent as CheckmarkThinIcon } from 'assets/icons/checkmark-thin.svg';
import { FormattedMessage } from 'react-intl';
import { ShowMeasureNumberContext } from 'modules/compare/countries/ShowMeasureNumberContext';
import clsx from 'clsx';
import { ExpandIcon } from 'components/ExpandIcon';
import CompareCountriesDetailPanel from 'modules/compare/countries/CompareCountriesDetailPanel';

type Props = {
  row: Row<CompareCountryFirstLevel>;
  visibleColumns: Column<CompareCountryFirstLevel, unknown>[];
};

const CountCell = ({ measureNumber }: { measureNumber: number }) => {
  const showMeasureNumber = useContext(ShowMeasureNumberContext);

  if (showMeasureNumber) {
    return (
      <span
        className={clsx(
          styles.measureNumber,
          styles[`rowLevel2`],
          measureNumber > 0 && styles.positiveNumber
        )}
      >
        {measureNumber}
      </span>
    );
  }

  return measureNumber > 0 ? (
    <CheckmarkThinIcon />
  ) : (
    <span>
      <FormattedMessage id="compare.message.NA" defaultMessage="NA" />
    </span>
  );
};

const SubRow = ({
  row,
  indicator,
  visibleColumns,
  measureCountByCountryCode,
}: {
  row: Row<CompareCountryFirstLevel>;
  indicator: CompareCountryNtmCodeLevel<CompareIndicatorResult>;
  visibleColumns: Column<CompareCountryFirstLevel, unknown>[];
  measureCountByCountryCode: Record<string, number>;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <tr className={styles.rowLevel2}>
        {row.getVisibleCells().map((cell) => (
          <td key={cell.id}>
            {cell.column.id === COLUMN_ID.ROW_HEADER && (
              <button
                type="button"
                onClick={() => setIsExpanded((value) => !value)}
                className={clsx(styles.expandButton)}
              >
                <>
                  <div className={styles.summary}>
                    <>{indicator[cell.column.id]}</>
                  </div>
                  <div className={styles.icon}>
                    <ExpandIcon
                      isCollapsed={!isExpanded}
                      isInverted={false}
                      size="sm"
                      useFillVariantWhenExpanded={true}
                    />
                  </div>
                </>
              </button>
            )}
            {cell.column.id !== COLUMN_ID.ROW_HEADER &&
              cell.column.id in indicator && (
                <CountCell measureNumber={indicator[cell.column.id]} />
              )}
          </td>
        ))}
      </tr>
      {isExpanded && (
        <tr className={styles.rowLevel3}>
          <td colSpan={visibleColumns.length}>
            <CompareCountriesDetailPanel
              indicator={indicator}
              measureCountByCountryCode={measureCountByCountryCode}
            />
          </td>
        </tr>
      )}
    </>
  );
};

const CompareMultipleOptionsTableIndicatorLevel = ({
  row,
  visibleColumns,
}: Props) => {
  const { partner, productId, reporterId } = useGetCompareCountriesParams();
  const ntmCode = row.original.ntmCode || '';

  const response = useCompareCountriesIndicatorQuery(
    partner && productId && reporterId && ntmCode
      ? { reporterId, partner, productId, ntmCode }
      : skipToken
  );

  const { isLoading, data } = response;

  return isLoading ? (
    <tr className={styles[`rowLevel${row.depth + 1}`]}>
      <td colSpan={visibleColumns.length}>
        <Loading response={response} loadingWrapperClassName="py-2 px-3">
          <></>
        </Loading>
      </td>
    </tr>
  ) : (
    <>
      {data?.indicators?.map((indicator) => (
        <SubRow
          key={indicator.indicatorId}
          row={row}
          indicator={indicator}
          visibleColumns={visibleColumns}
          measureCountByCountryCode={data.qtyOfMeasuresByCountryCode}
        />
      ))}
    </>
  );
};

export default CompareMultipleOptionsTableIndicatorLevel;
