import React from 'react';
import BaseWidget from 'components/BaseWidget';
import SearchForm from './SearchForm';
import { HomePageWidget } from 'AppTypes';
import { sanitize } from 'dompurify';

const SearchWidget = ({ data }: { data?: HomePageWidget }) => {
  return (
    <BaseWidget
      title={data?.title}
      icon={<img src={data?.titleIcon?.[0]?.url} alt="" />}
      decorColor="purple"
    >
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(data?.description || ''),
        }}
      />
      <SearchForm />
    </BaseWidget>
  );
};

export default SearchWidget;
