import { AuthUserState } from 'ApiServices';
import { getCurrentLocale } from 'hooks/useLocale';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';
import { local } from 'store/storage';

const config = {
  cmsIdentityServer: process.env.REACT_APP_CMS_IDENTITY_SERVER || '',
  cmsApiRoot: process.env.REACT_APP_CMS_API_ROOT || '',
  cmsAssetRoot: process.env.REACT_APP_CMS_ASSET_ROOT || '',
  cmsClientId: process.env.REACT_APP_CMS_CLIENT_ID || '',
  cmsClientSecret: process.env.REACT_APP_CMS_CLIENT_SECRET || '',
  apiPrimary: process.env.REACT_APP_API_PRIMARY || '',
  apiSecondary: process.env.REACT_APP_API_SECONDARY || '',
  apiSecondaryKey: process.env.REACT_APP_API_KEY_SECONDARY || '',
};

export const authConfig: AuthProviderProps = {
  client_id: 'NTM-SAT',
  redirect_uri: window.location.origin + '/callback',
  post_logout_redirect_uri: window.location.origin + '/signout-callback',
  // silent_redirect_uri:
  //   window.location.origin + '/authentication/silent-callback',
  scope: 'profile openid email NTM-SAT.API offline_access', // offline_access scope allow your client to retrieve the refresh_token
  authority: 'https://idserv.marketanalysis.intracen.org',
  revokeTokensOnSignout: true,
  loadUserInfo: true,
  automaticSilentRenew: true,
  checkSessionIntervalInSeconds: 2,
  onSigninCallback: (user) => {
    const currentLocale = getCurrentLocale();

    if (user instanceof User && user.state) {
      const userState = (user.state || {}) as AuthUserState;
      const rootPages = [
        window.location.href,
        `${window.location.href}/${currentLocale}`,
      ];
      if (!rootPages.includes(userState.href)) {
        window.location.href = userState.href as string;
      }
    } else {
      window.history.replaceState(
        {},
        window.document.title,
        `/${currentLocale}`
      );
    }
  },
  userStore: new WebStorageStateStore({ store: local }),
};

export default config;
