import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import HomePage from 'pages/HomePage';
import Layout from 'pages/Layout';
import DataAvailabilityPage from 'pages/About/DataAvailabilityPage';
import MethodologyPage from 'pages/About/MethodologyPage';
import FaqPage from 'pages/Help/FaqPage';
import ClassificationPage from 'pages/Help/ClassificationPage';
import SearchPage from 'pages/SearchPage';
import SelfAssessmentPage from 'pages/SelfAssessmentPage';
import SelfAssessFrontPage from 'modules/self-assess/front-page/SelfAssessFrontPage';
import SelfAssessProfile from 'modules/self-assess/profile/SelfAssessProfile';
import SelfAssessProfileCompliance from 'modules/self-assess/profile/SelfAssessProfileCompliance';
import SelfAssessProfileSummary from 'modules/self-assess/profile/SelfAssessProfileSummary';
import SelfAssessProfileInfo from 'modules/self-assess/profile/SelfAssessProfileInfo';
import { getCurrentLocale } from 'hooks/useLocale';
import LogoutCallback from 'pages/Auth/LogoutCallback';
import ComparePage from 'pages/ComparePage';

export const routerDict = {
  home: '',
  authLogoutCallback: 'signout-callback',
  dataAvailability: 'about/data-availability',
  methodology: 'about/methodology',
  faq: 'help/faq',
  classification: 'help/classification',
  search: 'search',
  compare: 'compare',
  selfAssessment: 'self-assessment',
  selfAssessmentProfile: ':profileId',
  assessComplianceCheck: 'check',
  assessSummary: 'summary',
  assessUsefulInfo: 'info',
  userDashboard: 'user',
  help: ':locale/help',
  about: ':locale/about',
  resources: ':locale/resources',
  fullSelfAssessmentProfileById: (profileId: string) =>
    `self-assessment/${profileId}/check`,
  fullSelfAssessmentProfileSummaryById: (profileId: string) =>
    `self-assessment/${profileId}/summary`,
  fullSelfAssessmentProfileByQuery: ({
    profileId,
    ntmCode,
    measureId,
  }: {
    profileId: string;
    ntmCode: string;
    measureId: string;
  }) => `self-assessment/${profileId}/check/${ntmCode}/${measureId}`,
} as const;

export function getRoute(key: keyof typeof routerDict, arg?: any) {
  const locale = getCurrentLocale();
  const route: string =
    typeof routerDict[key] === 'function'
      ? (routerDict[key] as Function)?.(arg)
      : routerDict[key];

  return `/${locale}/${route}`;
}

const routerConfig: RouteObject[] = [
  // {
  //   path: routerDict.authCallback,
  //   element: <Callback />,
  // },
  {
    path: routerDict.authLogoutCallback,
    element: <LogoutCallback />,
  },
  {
    path: '/:language',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: routerDict.dataAvailability,
        element: <DataAvailabilityPage />,
      },
      {
        path: routerDict.methodology,
        element: <MethodologyPage />,
      },
      {
        path: routerDict.faq,
        element: <FaqPage />,
      },
      {
        path: routerDict.classification,
        element: <ClassificationPage />,
      },
      {
        path: routerDict.search,
        element: <SearchPage />,
      },
      {
        path: routerDict.compare,
        element: <ComparePage />,
      },
      {
        path: routerDict.selfAssessment,
        element: <SelfAssessmentPage />,
        children: [
          {
            index: true,
            element: <SelfAssessFrontPage />,
          },
          {
            path: routerDict.selfAssessmentProfile,
            element: <SelfAssessProfile />,
            children: [
              {
                index: true,
                element: (
                  <Navigate to={routerDict.assessComplianceCheck} replace />
                ),
              },
              {
                path: routerDict.assessComplianceCheck,
                element: <SelfAssessProfileCompliance />,
                children: [
                  {
                    path: ':ntmCode',
                    children: [
                      {
                        path: ':measureId',
                        element: <SelfAssessProfileCompliance />,
                      },
                    ],
                  },
                ],
              },
              {
                path: routerDict.assessSummary,
                element: <SelfAssessProfileSummary />,
              },
              {
                path: routerDict.assessUsefulInfo,
                element: <SelfAssessProfileInfo />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/en" replace />, // TODO: use the current selected locale
  },
];

export const Routes = () => {
  const routes = useRoutes(routerConfig);
  return routes;
};
