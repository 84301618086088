import React, { useMemo, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useIntl } from 'react-intl';

type Props = {
  complianceRate?: number;
} & HighchartsReact.Props;

const ComplianceRate = ({
  complianceRate: defaultComplianceRate = 0,
  ...props
}: Props) => {
  const complianceRate = Math.round(defaultComplianceRate);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const intl = useIntl();

  const options = useMemo(
    (): Highcharts.Options => ({
      chart: {
        backgroundColor: 'var(--bs-gray-200)',
        plotBorderWidth: 0,
        plotShadow: false,
        style: {
          color: 'var(--bs-primary)',
          fontFamily: 'var(--bs-body-font-family)',
        },
        spacingTop: 0,
        spacingBottom: 0,
        height: 300,
      },
      title: {
        text: `${complianceRate}%`,
        align: 'center',
        verticalAlign: 'middle',
        y: 20,
        style: {
          fontSize: '23px',
          fontWeight: '500',
        },
      },
      caption: {
        text: `<div>
          <div style="text-transform: uppercase; text-decoration: underline;">${intl.formatMessage(
            {
              id: 'selfAssess.message.complianceRate',
              defaultMessage: 'Compliance rate',
            }
          )}</div>
          <div style="margin-top: 10px">${intl.formatMessage({
            id: 'selfAssess.message.complianceRateDescription',
            defaultMessage: 'based on the provided information',
          })}</div>
        </div>`,
        useHTML: true,
        style: {
          textAlign: 'center',
          display: 'block',
          color: 'var(--bs-primary)',
          fontSize: '18px',
        },
        x: 25,
        y: -20,
      },
      colors: ['var(--bs-orange)', 'var(--bs-primary)'],
      tooltip: {
        pointFormat: '{point.percentage:.0f}%',
      },
      accessibility: {
        enabled: false,
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '75%'],
          size: '130%',
        },
      },
      series: [
        {
          type: 'pie',
          innerSize: '60%',
          data: [
            {
              name: 'Complied',
              y: complianceRate,
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: 'Not Complied',
              y: 100 - complianceRate,
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
      ],
    }),
    [complianceRate, intl]
  );

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
        {...props}
      />
    </div>
  );
};

export default ComplianceRate;
