import { AssessComplianceMeasureRouteMetadata } from 'AppTypes';
import { useParams, useSearchParams } from 'react-router-dom';

export function useGetSelfAssessParams() {
  const params = useParams<AssessComplianceMeasureRouteMetadata>();
  return params;
}

export function useGetSelfAssessSearchParams() {
  const [searchParams] = useSearchParams();

  const data = {
    profileName: searchParams.get('profileName') || undefined,
    partner: searchParams.get('partner') || undefined,
    reporterId: searchParams.get('reporterId') || undefined,
    productCode: searchParams.get('productCode') || undefined,
  };

  const { profileName, partner, reporterId, productCode } = data;

  return profileName && partner && reporterId && productCode
    ? {
        profileName,
        partner,
        reporterId,
        productCode,
      }
    : undefined;
}
