import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ProductSearchItem } from 'AppTypes';
import { useController, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  ActionMeta,
  components,
  GroupBase,
  MenuProps,
  SingleValue,
} from 'react-select';
import { isEmpty } from 'lodash';
import { useDebounce } from 'use-debounce';
import { useSearchProductsQuery } from 'services/products';
import NTMSelect from './NTMSelect';
import Button from 'components/Button';
import styles from './ProductSelect.module.scss';
import { skipToken } from '@reduxjs/toolkit/query/react';
import HSFinder from 'modules/products/HSFinder';
import { ProductSelectContext } from './ProductSelectContext';
import { useSearchParams } from 'react-router-dom';
import { useMetadataInfoQuery } from 'services/metadata';

type Props = {
  name: string;
  id: string;
  defaultInputValue?: string;
  isOptionDisabled?: (option: ProductSearchItem) => boolean;
  defaultValue?: string;
};

const ProductSelect = ({
  name,
  id,
  defaultInputValue,
  isOptionDisabled,
  defaultValue,
}: Props) => {
  const [searchParams] = useSearchParams();
  const productIdParams = searchParams.get(name) ?? defaultValue;
  const {
    field: { onChange: onChangeRHF, onBlur: onBlurRHF, value: valueRHF, ref },
    fieldState: { error },
  } = useController({
    name,
  });

  const [inputValue, setInputValue] = useState<string | undefined>(
    defaultInputValue
  );
  const [lastSuccessValue, setLastSuccessValue] = useState<
    string | undefined
  >();

  const [inputValueForSearch] = useDebounce(inputValue, 300);

  const [value, setValue] = useState<ProductSearchItem | null>(null);

  const toValue = useWatch({
    name: 'reporterId',
  });

  const { currentData: metadata } = useMetadataInfoQuery(toValue, {
    skip: !toValue,
  });

  const { isLoading, isFetching, isSuccess, data, currentData } =
    useSearchProductsQuery(
      toValue &&
        metadata?.ntmYear &&
        (inputValueForSearch || lastSuccessValue || valueRHF)
        ? {
            countryCode: toValue,
            keyword: inputValueForSearch || lastSuccessValue || valueRHF,
            year: metadata.ntmYear,
          }
        : skipToken
    );

  useEffect(() => {
    if (
      isSuccess &&
      !isLoading &&
      productIdParams &&
      productIdParams === currentData?.[0]?.code
    ) {
      setValue(currentData?.[0]);
    }
  }, [currentData, isLoading, isSuccess, productIdParams]);

  useEffect(() => {
    if (!valueRHF) {
      setValue(null);
      setInputValue('');
    }
  }, [valueRHF]);

  const onChange = useCallback(
    (
      newValue: SingleValue<ProductSearchItem>,
      actionMeta?: ActionMeta<ProductSearchItem>
    ) => {
      setLastSuccessValue(inputValueForSearch);
      onChangeRHF(newValue?.code);
      setValue(newValue);
    },
    [inputValueForSearch, onChangeRHF]
  );

  const onFinderSelected = useCallback(
    (newValue: SingleValue<ProductSearchItem>) => {
      setLastSuccessValue('');
      setInputValue('');
      onChangeRHF(newValue?.code);
      setValue(newValue);
    },
    [onChangeRHF]
  );

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const defaultContextValue = useMemo(
    () => ({
      open: show,
      setOpen: handleShow,
      isOptionDisabled,
    }),
    [handleShow, isOptionDisabled, show]
  );

  return (
    <>
      <ProductSelectContext.Provider value={defaultContextValue}>
        <NTMSelect
          name={name}
          inputId={id}
          inputValue={inputValue}
          value={value}
          onInputChange={setInputValue}
          placeholder={
            <FormattedMessage
              id="select.placeholder.chooseProduct"
              defaultMessage="Choose product"
            />
          }
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          menuPortalTarget={document.body}
          onBlur={onBlurRHF}
          ref={ref}
          onChange={onChange}
          menuPlacement="auto"
          isDisabled={isEmpty(toValue) || !metadata?.ntmYear}
          isLoading={isFetching}
          options={data}
          components={{ Menu: CustomMenu }}
          error={error?.message}
          isOptionDisabled={isOptionDisabled}
        />
        <HSFinder
          show={show}
          onHide={handleClose}
          fullscreen="md-down"
          scrollable={true}
          size="lg"
          selectedCountry={toValue}
          onSelect={onFinderSelected}
        />
      </ProductSelectContext.Provider>
    </>
  );
};

function getOptionValue(product: ProductSearchItem) {
  return product.code;
}

function getOptionLabel(product: ProductSearchItem) {
  return `${product.code} - ${product.name}`;
}

function CustomMenu({
  children,
  ...props
}: MenuProps<ProductSearchItem, false, GroupBase<ProductSearchItem>>) {
  const { setOpen } = useContext(ProductSelectContext);

  return (
    <>
      <div className={styles.menuWrapper}>
        <components.Menu<ProductSearchItem, false, GroupBase<ProductSearchItem>>
          {...props}
        >
          {children}
        </components.Menu>
        <div
          className={styles.ctaSection}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return;
          }}
        >
          <p>
            <FormattedMessage
              id="search.description.productCode1"
              defaultMessage="Not sure about your product code?"
            />
          </p>
          <p>
            <FormattedMessage
              id="search.description.productCode2"
              defaultMessage="We help you identify the correct HS code for your product with our Product Code Finder tool."
            />
          </p>
          <Button
            type="button"
            hasCornerCut
            className={styles.ctaBtn}
            onClick={() => void setOpen()}
          >
            <FormattedMessage
              id="button.action.productCodeFinder"
              defaultMessage="Product Code Finder"
            />
          </Button>
        </div>
      </div>
    </>
  );
}

export default React.memo(ProductSelect);
