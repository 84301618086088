import Button from 'components/Button';
import NTMSelect from 'components/Select';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './SelfAssessWidgetBody.module.scss';
import { components, OptionProps } from 'react-select';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/router';
import CreateProfileForm from '../CreateProfileForm';
import { AssessProfile } from 'AppTypes';
import { useGetCategorizedProfilesQuery } from 'services/selfAssess';
import { getFlagCode } from 'utils/flags';
import {
  useGetFromCountriesById,
  useGetToCountriesById,
} from 'services/countries';

const SelfAssessWidgetBody = () => {
  const { data, isFetching } = useGetCategorizedProfilesQuery();
  const [isCreatingNewProfile, setIsCreatingNewProfile] =
    useState<boolean>(false);
  const intl = useIntl();
  const navigate = useNavigate();

  const onSelectSavedProfile = useCallback(
    (profileId: string) => {
      navigate(getRoute('fullSelfAssessmentProfileById', profileId));
    },
    [navigate]
  );

  return (
    <div>
      {!isCreatingNewProfile && (
        <div className={styles.frontPage}>
          <NTMSelect
            options={data.nonEmptyProfiles}
            isLoading={isFetching}
            placeholder={intl.formatMessage({
              id: 'selfAssess.widget.resumeProfile',
              defaultMessage: 'Resume a saved assessment profile',
            })}
            valueKey="assessmentProfileId"
            labelKey="profileName"
            menuPortalTarget={document.body}
            components={{
              Option: CustomOption,
            }}
            onChangeValue={onSelectSavedProfile}
          />
          <span className="d-block text-center my-3">
            <FormattedMessage id="selfAssess.widget.or" defaultMessage="or" />
          </span>
          <div className="text-center">
            <Button onClick={() => setIsCreatingNewProfile(true)}>
              <FormattedMessage
                id="selfAssess.widget.createNewProfile"
                defaultMessage="Create a new assessment profile"
              />
            </Button>
          </div>
        </div>
      )}
      {isCreatingNewProfile && (
        <>
          <CreateProfileForm
            denseLayout
            customBtn={
              <Button
                hasCornerCut
                variant="light"
                onClick={() => setIsCreatingNewProfile(false)}
              >
                <FormattedMessage
                  id="selfAssess.widget.back"
                  defaultMessage="Back"
                />
              </Button>
            }
          />
        </>
      )}
    </div>
  );
};

export function CustomOption({
  children,
  ...props
}: OptionProps<AssessProfile>) {
  const { data: profile } = props;
  const { data: fromCountriesById } = useGetFromCountriesById();
  const { data: toCountriesById } = useGetToCountriesById();

  return (
    <components.Option
      {...props}
      className={clsx(props.className, styles.hasDivider)}
    >
      <div className={styles.profileName}>{profile.profileName}</div>
      <div>
        <FormattedMessage id="selfAssess.widget.from" defaultMessage="From" />:{' '}
        <span className={styles.countryName}>
          {fromCountriesById[profile.fromCountry] && (
            <span
              className={`fi fi-${getFlagCode(
                fromCountriesById[profile.fromCountry]?.iso2
              )} me-1`}
              aria-hidden={true}
            />
          )}
          {fromCountriesById[profile.fromCountry]?.name ?? profile.fromCountry}
        </span>
      </div>
      <div>
        <FormattedMessage id="selfAssess.widget.to" defaultMessage="To" />:{' '}
        <span className={styles.countryName}>
          {toCountriesById[profile.toCountry] && (
            <span
              className={`fi fi-${getFlagCode(
                toCountriesById[profile.toCountry]?.iso2
              )} me-1`}
              aria-hidden={true}
            />
          )}
          {toCountriesById[profile.toCountry]?.name ?? profile.toCountry}
        </span>
      </div>
      <div>
        <FormattedMessage
          id="selfAssess.widget.product"
          defaultMessage="Product"
        />{' '}
        : <span>{profile.productDisplayName}</span>
      </div>
    </components.Option>
  );
}

export default SelfAssessWidgetBody;
