import { useGetShellContent } from 'services/cms';
import { local } from 'store/storage';

const STORAGE_KEY = 'CMS_SQUIDEX_TRANSLATIONS';

export function saveTranslationsToLocal(data: Record<string, string>) {
  local.setItem(STORAGE_KEY, JSON.stringify(data));
}

export function getLocalTranslations(): Record<string, string> {
  const storage = local.getItem(STORAGE_KEY) || '{}';
  try {
    return storage ? JSON.parse(storage) : {};
  } catch (e) {
    console.error(e);
    return {};
  }
}

export function useGetIntlMessages() {
  const { data, isLoading } = useGetShellContent();

  if (!isLoading && data) {
    return data.translations ?? getLocalTranslations() ?? {};
  }

  const translation = getLocalTranslations();
  return translation;
}
