import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useMetadataInfoQuery } from 'services/metadata';

type Props = {
  className?: string;
  reporterId: string;
};

const SearchInfoMetadata = ({ className, reporterId }: Props) => {
  const { data, isFetching, isSuccess } = useMetadataInfoQuery(reporterId);

  if (!isFetching && isSuccess) {
    return (
      <dl className={className}>
        <dt>
          <FormattedMessage
            id="search.dataInfo.hsRevision"
            defaultMessage="HS Revision"
          />
          :&nbsp;
        </dt>
        <dd>{data?.hsRevision}</dd>
        <dt>
          <FormattedMessage
            id="search.dataInfo.ntmClassification"
            defaultMessage="NTM Classification"
          />
          :&nbsp;
        </dt>
        <dd>{data?.ntmRevision}</dd>
        <dt>
          <FormattedMessage
            id="search.dataInfo.ntmYear"
            defaultMessage="NTM Year"
          />
          :&nbsp;
        </dt>
        <dd>{data?.ntmYear}</dd>
        <dt>
          <FormattedMessage
            id="search.dataInfo.source"
            defaultMessage="Source"
          />
          :&nbsp;
        </dt>
        <dd>{data?.source}</dd>
      </dl>
    );
  }

  return null;
};

export default SearchInfoMetadata;
