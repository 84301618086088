import React, { useCallback, useMemo } from 'react';
import { Country } from 'AppTypes';
import {
  CustomOption,
  formatOptionLabel,
  getOptionLabel,
  getOptionValue,
} from './CountrySelect';
import { useController } from 'react-hook-form';
import { ActionMeta, MultiValue, Options, SingleValue } from 'react-select';
import NTMSelect from './NTMSelect';
import { FormattedMessage } from 'react-intl';

type Props = {
  name: string;
  id: string;
  options: Country[];
  isDisabled?: boolean;
  isOptionDisabled?: (
    option: Country,
    selectValue: Options<Country>
  ) => boolean;
  isLoading?: boolean;
  isMulti?: boolean;
};

const CountrySelectRHF = ({
  name,
  id,
  options,
  isDisabled,
  isOptionDisabled,
  isLoading,
  isMulti,
}: Props) => {
  const {
    field: { onChange: onChangeRHF, onBlur: onBlurRHF, value: valueRHF, ref },
    fieldState: { error },
  } = useController({
    name,
  });

  const onChange = useCallback(
    (
      newValue: SingleValue<Country> | MultiValue<Country>,
      actionMeta: ActionMeta<Country>
    ) => {
      if (Array.isArray(newValue)) {
        onChangeRHF(
          (newValue as MultiValue<Country>)?.map((item) => item.code)
        );
      } else {
        onChangeRHF((newValue as SingleValue<Country>)?.code);
      }
    },
    [onChangeRHF]
  );

  const value = useMemo(() => {
    if (Array.isArray(valueRHF)) {
      return options.filter((option) => valueRHF.includes(option.code));
    }
    return options.find((option) => option.code === valueRHF) || null;
  }, [options, valueRHF]);

  return (
    <>
      <NTMSelect
        name={name}
        inputId={id}
        options={options}
        placeholder={
          <FormattedMessage
            id="select.placeholder.chooseCountry"
            defaultMessage="Choose country"
          />
        }
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        menuPortalTarget={document.body}
        onBlur={onBlurRHF}
        value={value}
        ref={ref}
        onChange={onChange}
        menuPlacement="auto"
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        components={{
          Option: CustomOption,
        }}
        error={error?.message}
        formatOptionLabel={formatOptionLabel}
        isDisabled={isDisabled}
        isOptionDisabled={isOptionDisabled}
        isLoading={isLoading}
      />
    </>
  );
};

export default React.memo(CountrySelectRHF);
