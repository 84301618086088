import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { useGetCurrentLocale } from 'hooks/useLocale';
import { IntlProvider } from 'react-intl';
import ScrollToTop from 'components/ScrollToTop';
import NavBar from './NavBar';
import styles from './Layout.module.scss';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from 'pages/ErrorPage';
import DefaultPageTitle from 'components/DefaultPageTitle';
import { useDispatch } from 'react-redux';
import { setLocale } from 'modules/app/configSlice';
import { cmsApi } from 'services/cms';
import { SERVICE_TAGS } from 'services/constants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useGetIntlMessages } from 'utils/useIntl';
import AuthLoadingOverlay from 'components/AuthLoadingOverlay';

function Index() {
  const locale = useGetCurrentLocale();
  const dispatch = useDispatch();
  const messages = useGetIntlMessages();

  useEffect(() => {
    dispatch(setLocale(locale));
    setTimeout(() => {
      cmsApi.util.invalidateTags([SERVICE_TAGS.CMS]);
    }, 100);
  }, [dispatch, locale]);

  return (
    <IntlProvider messages={messages} locale={locale} defaultLocale="en">
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <AuthLoadingOverlay />
        <DefaultPageTitle />
        <Header />
        <NavBar />
        <main className={styles.main}>
          <Outlet />
        </main>
        <Footer />
        <ScrollToTop />
        <ToastContainer theme="colored" />
      </ErrorBoundary>
    </IntlProvider>
  );
}

export default Index;
