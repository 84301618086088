import { CompareType } from 'modules/constants';
import { useSearchParams } from 'react-router-dom';

export function useGetCompareType() {
  const [searchParams] = useSearchParams();

  return searchParams.get('type') as CompareType | undefined;
}

export function useGetCompareParams() {
  const [searchParams] = useSearchParams();

  return {
    type: searchParams.get('type') as CompareType | undefined,
    partner: searchParams.get('partner') || undefined,
    reporterId: searchParams.get('reporterId') || undefined,
    productId1: searchParams.get('productId1') || undefined,
    productId2: searchParams.get('productId2') || undefined,
    productId: searchParams.get('productId') || undefined,
  };
}

export function useGetCompareProductsParams() {
  const [searchParams] = useSearchParams();

  const type = searchParams.get('type') as CompareType | undefined;

  const data =
    type === CompareType.PRODUCTS
      ? {
          partner: searchParams.get('partner') || undefined,
          reporterId: searchParams.get('reporterId') || undefined,
          productId1: searchParams.get('productId1') || undefined,
          productId2: searchParams.get('productId2') || undefined,
          type,
        }
      : {
          partner: undefined,
          reporterId: undefined,
          productId1: undefined,
          productId2: undefined,
          type,
        };

  return data;
}

export function useGetCompareCountriesParams() {
  const [searchParams] = useSearchParams();

  const type = searchParams.get('type') as CompareType | undefined;

  const data =
    type === CompareType.COUNTRIES_INTL_STANDARDS
      ? {
          partner: searchParams.get('partner') || undefined,
          reporterId: searchParams.get('reporterId')?.split(',') ?? [],
          productId: searchParams.get('productId') || undefined,
          type,
        }
      : {
          partner: undefined,
          reporterId: undefined,
          productId: undefined,
          type,
        };

  return data;
}
