import { useImperativeDisableScroll } from 'hooks/useImperativeDisableScroll';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import styles from './AuthLoadingOverlay.module.scss';

const AuthLoadingOverlay = () => {
  const auth = useAuth();

  useImperativeDisableScroll({
    element: document.body,
    disabled: auth.isLoading,
  });

  return auth.isLoading ? (
    <div className={styles.overlay}>
      <Spinner variant="light" className={styles.spinner} />
    </div>
  ) : null;
};

export default AuthLoadingOverlay;
