import { combineEpics } from 'redux-observable';
import { concatMap, EMPTY, filter, of } from 'rxjs';
import { AppEpic } from 'store';
import { setLastSelfAssessComplianceItem } from 'modules/app/configSlice';
import { LOCATION_CHANGE } from 'redux-first-history';
import type { Action } from 'history';
import { createAction } from '@reduxjs/toolkit';
import { matchPath } from 'react-router-dom';

const changeLocationAction = createAction<{
  location: Location;
  action: Action;
}>(LOCATION_CHANGE);

const saveLastAssessComplianceItem: AppEpic = (action$, state$, { config }) => {
  return action$.pipe(
    filter(changeLocationAction.match),
    concatMap((action) => {
      const pathname = action.payload.location.pathname;
      const matches = matchPath(
        ':language/self-assessment/:profileId/check/:ntmCode/:measureId',
        pathname
      );
      if (matches) {
        const { profileId, ntmCode, measureId } = matches.params;
        return of(
          setLastSelfAssessComplianceItem({ profileId, ntmCode, measureId })
        );
      }
      return EMPTY;
    })
  );
};

export default combineEpics(saveLastAssessComplianceItem);
