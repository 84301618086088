import React from 'react';
import clsx from 'clsx';
import { Nav, Tab } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import CompareCountriesForm from './CompareCountriesForm';
import CompareProductsForm from './CompareProductsForm';
import { CompareType } from 'modules/constants';
import { useGetCompareProductsParams } from 'hooks/useGetCompareParams';

type Props = {
  className?: string;
  horizontalLayout?: boolean;
};

const CompareForm = ({ className, horizontalLayout }: Props) => {
  const { type } = useGetCompareProductsParams();

  return (
    <div
      className={clsx(
        className,
        horizontalLayout && 'horizontal',
        'compare-nav full-height'
      )}
    >
      <Tab.Container
        defaultActiveKey={type ?? CompareType.COUNTRIES_INTL_STANDARDS}
        unmountOnExit={true}
      >
        <div className="nav-area">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey={CompareType.COUNTRIES_INTL_STANDARDS}>
                <FormattedMessage
                  id="compare.widget.countries"
                  defaultMessage="Countries/standards"
                />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={CompareType.PRODUCTS}>
                <FormattedMessage
                  id="compare.widget.products"
                  defaultMessage="Products"
                />
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey={CompareType.COUNTRIES_INTL_STANDARDS}>
            <CompareCountriesForm horizontalLayout={horizontalLayout} />
          </Tab.Pane>
          <Tab.Pane eventKey={CompareType.PRODUCTS}>
            <CompareProductsForm horizontalLayout={horizontalLayout} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default CompareForm;
