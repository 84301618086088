import { useGetSelfAssessParams } from 'hooks/useGetSelfAssessParams';
import { MeasureFilterViewContext } from 'modules/app/MeasureFilterViewContext';
import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetProfileSummaryQuery } from 'services/selfAssess';
import { getRoute } from 'utils/router';

export function useSummaryNavigate() {
  const { filterView } = useContext(MeasureFilterViewContext);
  const { data: summary } = useGetProfileSummaryQuery();
  const { profileId, measureId, ntmCode } = useGetSelfAssessParams();
  const navigate = useNavigate();

  const flattenedSummary = useMemo(
    () =>
      summary
        ?.filter((item) => item.groupOption === filterView)
        .flatMap((summaryGroup) => {
          const { measureSummary, ...summaryProps } = summaryGroup;
          return summaryGroup.measureSummary.map((measure) => ({
            ...measure,
            ...summaryProps,
          }));
        }) ?? [],
    [filterView, summary]
  );

  const currentSelectedIndex = useMemo(() => {
    if (!profileId || !measureId || !ntmCode) {
      return 0;
    }

    return flattenedSummary.findIndex(
      (item) => item.ntmCode === ntmCode && item.measureCode === measureId
    );
  }, [flattenedSummary, measureId, ntmCode, profileId]);

  const isSelectingFirstItem = currentSelectedIndex === 0;
  const isSelectingLastItem =
    currentSelectedIndex === flattenedSummary.length - 1;

  const goToNextMeasure = useCallback(() => {
    if (
      currentSelectedIndex < flattenedSummary.length - 1 &&
      currentSelectedIndex >= 0
    ) {
      const nextItem = flattenedSummary[currentSelectedIndex + 1];
      navigate(
        getRoute('fullSelfAssessmentProfileByQuery', {
          profileId,
          measureId: nextItem.measureCode,
          ntmCode: nextItem.ntmCode,
        }),
        { replace: true }
      );
    }
  }, [currentSelectedIndex, flattenedSummary, navigate, profileId]);

  const goToPreviousMeasure = useCallback(() => {
    if (currentSelectedIndex > 0) {
      const previousItem = flattenedSummary[currentSelectedIndex - 1];
      navigate(
        getRoute('fullSelfAssessmentProfileByQuery', {
          profileId,
          measureId: previousItem.measureCode,
          ntmCode: previousItem.ntmCode,
        }),
        { replace: true }
      );
    }
  }, [currentSelectedIndex, flattenedSummary, navigate, profileId]);

  return {
    goToNextMeasure,
    goToPreviousMeasure,
    isSelectingFirstItem,
    isSelectingLastItem,
    firstItem: flattenedSummary[0],
  };
}
