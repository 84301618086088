import { MeasureGroupOverview } from 'AppTypes';
import Card from 'components/Card';
import { RenderSummary } from 'modules/app/MeasureGroupList';
import CompareMeasureGroupDetails from 'modules/compare/components/CompareMeasureGroupDetails';
import React, { useEffect, useMemo, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import styles from './CompareNTMAccordion.module.scss';
import { ReactComponent as CollapseIcon } from 'assets/icons/collapsed-pill.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/expanded-pill.svg';
import { useGetCompareParams } from 'hooks/useGetCompareParams';
import { CompareType } from 'modules/constants';

type Props = {
  data: MeasureGroupOverview[];
  isRightSide?: boolean;
};

const CompareNTMAccordion = ({ data, isRightSide }: Props) => {
  const [openState, setOpenState] = useState<Record<string, boolean>>({});
  const { type, partner, reporterId, productId1, productId2, productId } =
    useGetCompareParams();

  useEffect(() => {
    setOpenState(
      data.reduce<Record<string, boolean>>(
        (acc, curr) => ({
          ...acc,
          [curr.ntmCode]: false,
        }),
        {}
      )
    );
  }, [data]);

  const activeKeys = useMemo(
    () =>
      Object.entries(openState)
        .filter(([key, isExpanded]) => isExpanded)
        .map(([key]) => key),
    [openState]
  );

  return (
    <Accordion
      alwaysOpen
      bsPrefix="compare-accordion"
      className={styles.compareAccordion}
      activeKey={activeKeys}
    >
      {data.map((ntm) => (
        <Accordion.Item eventKey={ntm.ntmCode} key={ntm.ntmCode}>
          <Accordion.Header
            onClick={() =>
              setOpenState((state) => ({
                ...state,
                [ntm.ntmCode]: !state[ntm.ntmCode],
              }))
            }
          >
            <div className="container">
              {RenderSummary(ntm)}
              <div className={styles.indicator}>
                {openState[ntm.ntmCode] ? <ExpandIcon /> : <CollapseIcon />}
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Collapse eventKey={ntm.ntmCode} mountOnEnter={true}>
            <Card className="container">
              <CompareMeasureGroupDetails
                measureGroup={ntm}
                fromCountryCode={partner || ''}
                toCountryCode={
                  (type === CompareType.PRODUCTS
                    ? reporterId
                    : reporterId?.split(',')?.[isRightSide ? 1 : 0]) || ''
                }
                productId={
                  (type === CompareType.PRODUCTS
                    ? isRightSide
                      ? productId2
                      : productId1
                    : productId) || ''
                }
              />
            </Card>
          </Accordion.Collapse>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default CompareNTMAccordion;
