import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonProps } from './Button';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import BaseSquareButton from 'components/Button/BaseSquareButton';

type Props = {
  inverted?: boolean;
} & ButtonProps;

const DownloadButton = ({ inverted, ...btnProps }: Props) => {
  return (
    <BaseSquareButton
      inverted={inverted}
      icon={<DownloadIcon />}
      message={
        <FormattedMessage
          id="button.action.download"
          defaultMessage="Download"
        />
      }
      {...btnProps}
    />
  );
};

export default React.memo(DownloadButton);
