import React from 'react';
import { useGetMeasureTypesInFilter } from 'modules/app/MeasureFilterViewContext';
import { FormattedMessage } from 'react-intl';
import styles from './CommonMeasureTypes.module.scss';
import CommonMeasureTypeSection from './CommonMeasureTypeSection';
import { CompareResultCommonType } from 'AppTypes';

type Props = {
  data?: CompareResultCommonType;
};

const CommonMeasureTypes = ({ data }: Props) => {
  const measureTypes = useGetMeasureTypesInFilter();

  return (
    <div>
      <div className={styles.header}>
        <h2>
          <FormattedMessage
            id="compare.info.commonMeasureTypes"
            defaultMessage="Types of measures in common"
          />
        </h2>
      </div>
      {measureTypes
        .filter((measureType) => {
          if (data != null) {
            return data[measureType.key] != null;
          }

          return true;
        })
        .map((measureType, index) => {
          return data ? (
            <CommonMeasureTypeSection
              key={index}
              title={measureType.title}
              icon={measureType.icon}
              compareData={data[measureType.key]}
            />
          ) : null;
        })}
    </div>
  );
};

export default React.memo(CommonMeasureTypes);
