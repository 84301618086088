import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TradeRequirementByCountry } from 'AppTypes';
import config from 'config';

export const aboutApi = createApi({
  reducerPath: 'about',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiPrimary}/v1/about`,
  }),
  endpoints: (builder) => ({
    dataAvailability: builder.query<TradeRequirementByCountry[], void>({
      query: () => ({
        url: 'get-data-availability',
      }),
    }),
  }),
});

export const { useDataAvailabilityQuery } = aboutApi;
