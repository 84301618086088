import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import {
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { FormattedMessage } from 'react-intl';
import { useMultipleOptionsTable } from 'modules/compare/countries/useMultipleOptionsTable';
import styles from './CompareMultipleOptionsTable.module.scss';
import { singleValueInArray } from 'components/Table/customFilterFns';
import { MeasureFilterViewContext } from 'modules/app/MeasureFilterViewContext';
import { MeasureFilterType } from 'modules/constants';
import { CompareCountryFirstLevel } from 'AppTypes';
import CompareMultipleOptionsTableIndicatorLevel from 'modules/compare/countries/CompareMultipleOptionsTableIndicatorLevel';

const defaultExpandState: ExpandedState = {
  '0': true,
  '1': true,
};

type Props = {
  tableData: Record<MeasureFilterType, CompareCountryFirstLevel[]>;
};

const CompareMultipleOptionsTable = ({ tableData }: Props) => {
  const { filterView } = useContext(MeasureFilterViewContext);
  const { columns } = useMultipleOptionsTable(
    tableData[MeasureFilterType.SPS_TBT]
  );

  const [expanded, setExpanded] =
    React.useState<ExpandedState>(defaultExpandState);

  useEffect(() => {
    setExpanded(defaultExpandState);
  }, [filterView]);

  const table = useReactTable({
    data: tableData[1], //need to be review with Hoang
    columns: columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: (row) => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    filterFns: { singleValueInArray: singleValueInArray },
    getRowCanExpand: () => true,
  });

  return (
    <table className={clsx(styles.table)}>
      <thead>
        {table.getHeaderGroups().map((headerGroup, index) => (
          <React.Fragment key={headerGroup.id}>
            <tr>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  <div className={styles.headerCell}>
                    {header.isPlaceholder ? null : (
                      <>
                        <span>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </span>
                      </>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </React.Fragment>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <React.Fragment key={row.id}>
            <tr className={styles[`rowLevel${row.depth}`]}>
              {row.depth < 2 &&
                row
                  .getVisibleCells()
                  .map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
            </tr>
            {row.depth === 1 && row.getIsExpanded() && (
              <CompareMultipleOptionsTableIndicatorLevel
                row={row}
                visibleColumns={table.getAllFlatColumns()}
              />
            )}
          </React.Fragment>
        ))}
        {table.getRowModel().rows.length === 0 && (
          <tr>
            <td colSpan={table.getAllColumns().length}>
              <FormattedMessage
                id="message.noDataFound"
                defaultMessage="No data found"
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default React.memo(CompareMultipleOptionsTable);
