import Card from 'components/Card';
import React, { useCallback, useMemo, useState } from 'react';
import AccordionSummary from './AccordionSummary';
import styles from './SearchNTMAccordion.module.scss';

type Props<T> = {
  data: Array<T>;
  renderSummaryFn: (data: T) => React.ReactNode;
  renderDetailFn: (data: T) => React.ReactNode;
};

function NTMAccordion<T = unknown>({
  data,
  renderSummaryFn,
  renderDetailFn,
}: Props<T>) {
  const [currentOpening, setCurrentOpening] = useState<string | null>(null);

  const onToggle = useCallback((newValue: string) => {
    setCurrentOpening((currentValue) =>
      currentValue !== newValue ? newValue : null
    );
  }, []);

  const transformedData = useMemo(() => {
    const results: Array<Array<T>> = [];
    for (let i = 0; i < Math.ceil(data.length / 2); i++) {
      const indexes = [i * 2, i * 2 + 1];
      results[i] = indexes.filter((x) => x < data.length).map((x) => data[x]);
    }
    return results;
  }, [data]);

  return (
    <div className={styles.accordion}>
      {transformedData.map((parentData, parentIndex) => {
        const keys = parentData.map((_, index) => `${parentIndex}-${index}`);
        return (
          <React.Fragment key={`${parentIndex}`}>
            <div className={styles.summary}>
              {parentData.map((item, index) => {
                return (
                  <AccordionSummary
                    isExpanded={currentOpening === keys[index]}
                    onToggle={onToggle}
                    key={keys[index]}
                    keyData={keys[index]}
                    noBorder={parentIndex === 0}
                  >
                    {renderSummaryFn(item)}
                  </AccordionSummary>
                );
              })}
            </div>
            {keys[0] === currentOpening && (
              <Card className={styles.details}>
                {renderDetailFn(parentData[0])}
              </Card>
            )}
            {keys[1] === currentOpening && (
              <Card className={styles.details}>
                {renderDetailFn(parentData[1])}
              </Card>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default NTMAccordion;
