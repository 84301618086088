import { localeSelector } from 'modules/app/configSelectors';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const availableLocales = [
  {
    title: 'English',
    locale: 'en',
  },
  {
    title: 'Français',
    locale: 'fr',
  },
  {
    title: 'Español',
    locale: 'es',
  },
];
export const acceptedLocales = ['en', 'fr', 'es'] as const;

export type AcceptedLocales = typeof acceptedLocales[number];

const useGetLocaleFromStore = () => {
  const locale = useSelector(localeSelector);

  return locale;
};

export const getCurrentLocale = (pathname = window.location.pathname) => {
  const locale = pathname.split('/')[1];

  const localeResult = acceptedLocales.includes(locale as AcceptedLocales)
    ? locale
    : 'en';

  return localeResult;
};

export const useGetCurrentLocale = () => {
  const { pathname } = useLocation();
  return getCurrentLocale(pathname);
};

export default useGetLocaleFromStore;
