import { ProductSearchItem } from 'AppTypes';
import React from 'react';

export const ProductSelectContext = React.createContext<{
  open: boolean;
  setOpen: () => void;
  isOptionDisabled?: (option: ProductSearchItem) => boolean;
}>({
  open: false,
  setOpen: () => {},
});
