import { createSelector } from '@reduxjs/toolkit';
import { CompareCountryItemResponse } from 'ApiServices';
import { useGetCompareCountriesParams } from 'hooks/useGetCompareParams';
import { MeasureFilterViewContextProvider } from 'modules/app/MeasureFilterViewContext';
import CompareMultipleOptionsTable from 'modules/compare/countries/CompareMultipleOptionsTable';
import { ShowMeasureNumberContext } from 'modules/compare/countries/ShowMeasureNumberContext';
import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useCompareCountriesOverviewQuery } from 'services/compare';
import styles from './CompareWithMultipleOptions.module.scss';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { createMultipleCountriesComparison } from 'services/data/compare-multiple-countries';
import Loading from 'components/Loading';
import NoResultFound from 'components/NoResultFound';

const CompareWithMultipleOptions = () => {
  const { partner, reporterId, productId } = useGetCompareCountriesParams();

  const selectMultipleCountriesComparison = useMemo(() => {
    return createSelector(
      (response: any) => response.data,
      (response: any, countryCodes: string[]) => countryCodes,
      (data: CompareCountryItemResponse[], countryCodes: string[]) =>
        createMultipleCountriesComparison(data, countryCodes)
    );
  }, []);

  const response = useCompareCountriesOverviewQuery(
    partner && reporterId && productId
      ? { partner, reporterId, productId }
      : skipToken,
    {
      selectFromResult: (result) => ({
        ...result,
        data: selectMultipleCountriesComparison(result, reporterId || []),
        originalData: result.data,
      }),
    }
  );
  const { data, originalData } = response;

  const [showMeasureNumber, setShowMeasureNumber] = useState<boolean>(false);

  return (
    <div className={styles.wrapper}>
      <Loading
        response={response}
        loadingWrapperClassName="justify-content-center"
      >
        {Array.isArray(originalData) && originalData.length > 0 ? (
          <>
            <Form.Group controlId="showNumberOfMeasures">
              <Form.Check
                className={styles.toggleMeasureDisplay}
                type="checkbox"
                label={
                  <span className="text-uppercase">
                    <FormattedMessage
                      id="compare.message.showNumberOfMeasures"
                      defaultMessage="Show number of measures"
                    />
                  </span>
                }
                checked={showMeasureNumber}
                onChange={(e) => setShowMeasureNumber(e.target.checked)}
                reverse
              />
            </Form.Group>
            <ShowMeasureNumberContext.Provider value={showMeasureNumber}>
              <MeasureFilterViewContextProvider>
                <CompareMultipleOptionsTable tableData={data} />
              </MeasureFilterViewContextProvider>
            </ShowMeasureNumberContext.Provider>
          </>
        ) : (
          <NoResultFound />
        )}
      </Loading>
    </div>
  );
};

export default React.memo(CompareWithMultipleOptions);
