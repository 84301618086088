import React, { useCallback, useEffect, useState } from 'react';
import { AssessProfileMeasureIndicator } from 'AppTypes';
import styles from './AssessMeasureDetailsList.module.scss';
import { FormattedMessage } from 'react-intl';
import { RadioGroup } from 'components/RadioGroup';
import { useUpdateComplianceMutation } from 'services/selfAssess';
import { useGetSelfAssessParams } from 'hooks/useGetSelfAssessParams';

const AssessMeasureItemRadioGroup = ({
  indicator,
}: {
  indicator: AssessProfileMeasureIndicator;
}) => {
  const { profileId } = useGetSelfAssessParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateCompliance, { data, isLoading, isSuccess, isError }] =
    useUpdateComplianceMutation();

  const [value, setValue] = useState<string | null>(
    indicator.assessmentId ? String(indicator.isComplied) : null
  );

  useEffect(() => {
    if (isError && !isLoading) {
      setValue(indicator.assessmentId ? String(indicator.isComplied) : null);
    }
  }, [indicator.assessmentId, indicator.isComplied, isError, isLoading]);

  const onInputChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      updateCompliance({
        IsComplied: newValue === 'true',
        AssessmentId: indicator.assessmentId,
        MeasureId: indicator.measureId,
        NtmCode: indicator.ntmCode,
        IndicatorId: indicator.indicatorId,
        AssessmentRowId: indicator.rowId,
        AssessmentProfileId: profileId || '',
        ExtraData: null,
      });
    },
    [
      indicator.assessmentId,
      indicator.indicatorId,
      indicator.measureId,
      indicator.ntmCode,
      indicator.rowId,
      profileId,
      updateCompliance,
    ]
  );

  return (
    <div className={styles.complianceCheck}>
      <div className={styles.legend}>
        <FormattedMessage
          id="selfAssess.compliance.markComplianceLegend"
          defaultMessage="Are you compliant with this requirement?"
        />
      </div>
      <RadioGroup
        name={`${indicator.measureId}-${indicator.indicatorId}-${indicator.rowId}`}
        value={value}
        onChange={onInputChange}
        disabled={isLoading}
        items={[
          {
            label: (
              <FormattedMessage
                id="selfAssess.compliance.complied"
                defaultMessage="Yes"
              />
            ),
            value: 'true',
          },
          {
            label: (
              <FormattedMessage
                id="selfAssess.compliance.notComplied"
                defaultMessage="No"
              />
            ),
            value: 'false',
          },
        ]}
      />
    </div>
  );
};

export default AssessMeasureItemRadioGroup;
