import React from 'react';

export const CustomAccordionContext = React.createContext<{
  activeKeys: string[];
  onClickItem: (key: string) => void;
  highlightLevel2?: boolean;
}>({
  activeKeys: [],
  onClickItem: () => {},
  highlightLevel2: false,
});
