import { SelfAssessProfileForm } from 'AppTypes';
import Button from 'components/Button';
import CreateProfileForm from 'modules/self-assess/CreateProfileForm';
import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import AuthCheck from 'components/AuthCheck';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  defaultValue?: SelfAssessProfileForm;
};

const QuickCreateSelfAssessProfileDialog = ({
  open,
  setOpen,
  defaultValue,
}: Props) => {
  const handleClose = useCallback(() => void setOpen(false), [setOpen]);

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id="selfAssess.message.createProfile"
            defaultMessage="Create a new assessment profile"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AuthCheck insideModal>
          <CreateProfileForm
            defaultValue={defaultValue}
            onStartAssessmentSuccess={() => {
              setOpen(false);
            }}
            customBtn={
              <Button hasCornerCut variant="light" onClick={handleClose}>
                <FormattedMessage
                  id="button.action.cancel"
                  defaultMessage="Cancel"
                />
              </Button>
            }
          />
        </AuthCheck>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(QuickCreateSelfAssessProfileDialog);
