import Button from 'components/Button';
import { availableLocales } from 'hooks/useLocale';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './LocaleSwitcher.module.scss';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { setLocale } from 'modules/app/configSlice';

const LocaleSwitcher = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const navigate = useNavigate();
  const { language: currentLocale } = useParams();

  const onChangeLocale = (newLocale: string) => {
    if (currentLocale !== newLocale) {
      dispatch(setLocale(newLocale));
      navigate(location.pathname.replace(`/${currentLocale}`, `/${newLocale}`));
    }
  };

  return (
    <div className={styles.wrapper}>
      {availableLocales.map((item) => (
        <Button
          key={item.locale}
          variant="link"
          size="sm"
          onClick={() => onChangeLocale(item.locale)}
          className={clsx(currentLocale === item.locale && styles.active)}
          title={item.title}
        >
          {item.locale}
        </Button>
      ))}
    </div>
  );
};

export default LocaleSwitcher;
