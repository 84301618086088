import React, { useMemo, useState } from 'react';
import CompareCountriesDetailPanelSection from 'modules/compare/countries/CompareCountriesDetailPanelSection';
import styles from './CompareCountriesDetailPanel.module.scss';
import {
  CompareCountryNtmCodeLevel,
  CompareIndicatorResult,
  Country,
} from 'AppTypes';
import {
  useGetFromCountriesById,
  useGetToCountriesById,
} from 'services/countries';

type Props = {
  indicator: CompareCountryNtmCodeLevel<CompareIndicatorResult>;
  measureCountByCountryCode: Record<string, number>;
};

const CompareCountriesDetailPanel = ({
  indicator,
  measureCountByCountryCode,
}: Props) => {
  const { data: toCountries } = useGetToCountriesById();
  const { data: fromCountries } = useGetFromCountriesById();

  const availableCountries = useMemo(() => {
    const countriesById = { ...fromCountries, ...toCountries };
    return indicator.measureCount
      .filter((item) => item.count > 0)
      .map((item) => countriesById[item.countryId]);
  }, [fromCountries, indicator.measureCount, toCountries]);

  const [selectedLeftCountry, setSelectedLeftCountry] =
    useState<Country | null>(availableCountries[0]);
  const [selectedRightCountry, setSelectedRightCountry] =
    useState<Country | null>(availableCountries[1]);

  return (
    <div className={styles.detailPanel}>
      <CompareCountriesDetailPanelSection
        availableCountries={availableCountries.filter(
          (country) => country.code !== selectedRightCountry?.code
        )}
        setCountry={setSelectedLeftCountry}
        selectedCountry={selectedLeftCountry}
        indicator={indicator}
        measureCountByCountryCode={measureCountByCountryCode}
      />
      <CompareCountriesDetailPanelSection
        availableCountries={availableCountries.filter(
          (country) => country.code !== selectedLeftCountry?.code
        )}
        setCountry={setSelectedRightCountry}
        selectedCountry={selectedRightCountry}
        indicator={indicator}
        measureCountByCountryCode={measureCountByCountryCode}
        isFlipped
      />
    </div>
  );
};

export default React.memo(CompareCountriesDetailPanel);
