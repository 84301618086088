import React from 'react';
import { useGetSelfAssessContent } from 'services/cms';
import SelfAssessProfileFooter from './SelfAssessProfileFooter';
import Loading from 'components/Loading';
import styles from './SelfAssessProfileInfo.module.scss';
import ComplianceRate from './ComplianceRate';
import { useGetProfileById } from 'services/selfAssess';

const SelfAssessProfileInfo = () => {
  const response = useGetSelfAssessContent();
  const { data } = response;
  const profile = useGetProfileById();

  return (
    <Loading response={response}>
      {data && (
        <div>
          <div className={styles.container}>
            <div className={styles.main}>
              {data?.usefulInfoItems?.map((item, index) => (
                <details open className={styles.accordion} key={index}>
                  <summary
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <div
                    className={styles.details}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </details>
              ))}
            </div>
            <div className={styles.complianceRate}>
              <ComplianceRate complianceRate={profile?.overallPercentage} />
            </div>
          </div>
          <SelfAssessProfileFooter data={data} />
        </div>
      )}
    </Loading>
  );
};

export default SelfAssessProfileInfo;
