import React, { useCallback } from 'react';
import clsx from 'clsx';
import styles from './InputRHF.module.scss';
import { CloseButton, FormControl, FormControlProps } from 'react-bootstrap';
import { useIntl } from 'react-intl';

type Props = FormControlProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    canClear?: boolean;
    focusableClearBtn?: boolean;
    clearBtnExternal?: boolean;
    fullBorder?: boolean;
    wrapperClassName?: string;
    error?: string;
    onValueChange: (value?: any) => void;
    onClear?: () => void;
  };

const Input = React.forwardRef(
  (
    {
      canClear,
      focusableClearBtn = true,
      fullBorder,
      wrapperClassName,
      error,
      onValueChange,
      clearBtnExternal = false,
      onClear,
      ...formControlProps
    }: Props,
    ref?: React.Ref<any>
  ) => {
    const intl = useIntl();

    const onInputChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onValueChange(event.target.value);
        formControlProps.onChange?.(event);
      },
      [formControlProps, onValueChange]
    );

    const onClearHandler = useCallback(() => {
      onValueChange('');
      onClear?.();
    }, [onClear, onValueChange]);

    return (
      <div
        ref={ref}
        className={clsx(
          styles.searchInput,
          error && styles.error,
          fullBorder && styles.fullBorder,
          wrapperClassName
        )}
      >
        <FormControl
          {...formControlProps}
          onChange={onInputChange}
          className={clsx(styles.input, formControlProps.className)}
        />
        {canClear && !!formControlProps.value && (
          <CloseButton
            tabIndex={focusableClearBtn ? 0 : -1}
            onClick={onClearHandler}
            className={clsx(
              styles.clearBtn,
              'clearInput',
              clearBtnExternal && styles.external
            )}
            title={intl.formatMessage({
              id: 'button.action.clearInput',
              defaultMessage: 'Clear input',
            })}
            disabled={formControlProps.disabled}
          />
        )}
      </div>
    );
  }
);

export default React.memo(Input);
