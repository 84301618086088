import React from 'react';
import clsx from 'clsx';
import {
  // eslint-disable-next-line no-restricted-imports
  Button as BsButton,
  ButtonProps as BsButtonProps,
} from 'react-bootstrap';

export type ButtonProps = BsButtonProps & {
  hasCornerCut?: boolean;
  uppercaseContent?: boolean;
  forwardedRef?: React.ForwardedRef<any>;
};

const Button = ({
  hasCornerCut,
  uppercaseContent = true,
  forwardedRef,
  ...props
}: ButtonProps) => {
  return (
    <BsButton
      {...props}
      ref={forwardedRef}
      className={clsx(props.className, {
        'btn-corner-cut': hasCornerCut,
        'btn-uppercase': uppercaseContent,
        'btn-outlined': props.variant?.startsWith('outline'),
      })}
    />
  );
};

export default React.forwardRef((props: ButtonProps, ref) => {
  return <Button {...props} forwardedRef={ref} />;
});
