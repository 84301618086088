import React from 'react';
import { useGetShellContent } from 'services/cms';
import styles from './SelfAssessmentPage.module.scss';
import { RemoteImage } from 'assets/remote';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import Orb from 'components/Orb';
import clsx from 'clsx';
import { Outlet } from 'react-router-dom';
import AuthCheck from 'components/AuthCheck';

const SelfAssessmentPage = () => {
  const { data } = useGetShellContent();
  const intl = useIntl();

  return (
    <>
      <Helmet
        title={intl.formatMessage({
          id: 'application.title.selfAssess',
          defaultMessage: 'Self Assess',
        })}
      />
      <section className={styles.widgetArea}>
        <RemoteImage
          url={data?.banners?.selfAssess?.url || ''}
          className={styles.background}
        />
        <div className={clsx('sub-container-xxxl', styles.wrapper)}>
          <div className={styles.title}>
            <Orb color="red" className={styles.decoration} />
            <h1
              className={styles.titleText}
              children={intl.formatMessage({
                id: 'application.title.selfAssess',
                defaultMessage: 'Self Assess',
              })}
            />
          </div>
        </div>
      </section>
      <div>
        <div className="sub-container-xxxl">
          <AuthCheck insideBody>
            <Outlet />
          </AuthCheck>
        </div>
      </div>
    </>
  );
};

export default SelfAssessmentPage;
