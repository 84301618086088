import { combineReducers } from '@reduxjs/toolkit';
import config from 'modules/app/configSlice';
import cms from 'modules/cms/cmsSlice';
import { routerReducer as router } from './history';
import { productApi } from 'services/products';
import { searchApi } from 'services/search';
import { measureApi } from 'services/measures';
import { cmsApi } from 'services/cms';
import { metadataApi } from 'services/metadata';
import { authApi } from 'services/auth';
import { selfAssessApi } from 'services/selfAssess';
import { aboutApi } from 'services/about';
import { countriesApi } from 'services/countries';
import { compareApi } from 'services/compare';

const rootReducers = combineReducers({
  config,
  cms,
  router,
  [productApi.reducerPath]: productApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [measureApi.reducerPath]: measureApi.reducer,
  [cmsApi.reducerPath]: cmsApi.reducer,
  [metadataApi.reducerPath]: metadataApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [selfAssessApi.reducerPath]: selfAssessApi.reducer,
  [aboutApi.reducerPath]: aboutApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [compareApi.reducerPath]: compareApi.reducer,
});

export type RootReducerType = ReturnType<typeof rootReducers>;

export default rootReducers;
