import { createApi, skipToken } from '@reduxjs/toolkit/query/react';
import { gql } from 'graphql-request';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import config from 'config';
import { RootState } from 'StoreTypes';
import { CMSQuery } from 'ApiServices';
import {
  CMSAsset,
  GenericPage,
  HomePage,
  SelfAssessPage,
  ShellContent,
} from 'AppTypes';
import { SERVICE_TAGS } from './constants';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useGetCurrentLocale } from 'hooks/useLocale';
import { useEffect } from 'react';
import { selectBearerToken } from 'modules/cms/selectors';
import { fetchBearerToken } from 'modules/cms/cmsSlice';
import { saveTranslationsToLocal } from 'utils/useIntl';

export const cmsApi = createApi({
  reducerPath: 'cmsApi',
  tagTypes: [SERVICE_TAGS.CMS],
  baseQuery: graphqlRequestBaseQuery({
    url: `${config.cmsApiRoot}/graphql`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const state = getState() as RootState;
      if (state.cms?.bearerToken) {
        headers.set('Authorization', `Bearer ${state.cms.bearerToken}`);
      }

      headers.set('X-Languages', state.config.locale);
      headers.set('X-NoResolveLanguages', '1');
      headers.set('X-Flatten', 'true');

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getHomePage: builder.query<HomePage, { locale: string }>({
      query: ({ locale }) => ({
        document: gql`
          query QueryHomePage {
            queryHomePageContents {
              flatData {
                widgetCompare {
                  title
                  description
                  titleIcon {
                    url
                  }
                }
                widgetSearch {
                  title
                  description
                  titleIcon {
                    url
                  }
                }
                widgetSelfAssess {
                  title
                  description
                  titleIcon {
                    url
                  }
                }
                bodyLeftSections {
                  title
                  titleImage {
                    url
                  }
                  secondaryTitle
                  description
                  isTwitterWidget
                  twitterHandle
                  youtubeVideoId
                  ctaButtonLabel
                  ctaButtonURL
                }
                bodyRightSections {
                  title
                  titleImage {
                    url
                  }
                  secondaryTitle
                  description
                  isTwitterWidget
                  twitterHandle
                  youtubeVideoId
                  ctaButtonLabel
                  ctaButtonURL
                }
              }
            }
          }
        `,
      }),
      keepUnusedDataFor: 900,
      transformResponse: (response: CMSQuery['homePage']) =>
        response?.queryHomePageContents?.[0]?.flatData,
    }),
    getShellContent: builder.query<ShellContent, { locale: string }>({
      query: ({ locale }) => ({
        document: gql`
          query QueryShellContent {
            queryFooterContents {
              flatData {
                contact
                footerCopyright
                footerDisclaimer
                footerTranslation
                socialLinks {
                  link
                  description
                  icon {
                    url
                  }
                }
              }
            }
            queryResourcesContents {
              flatData {
                items {
                  text
                  url
                }
              }
            }
            queryBannersContents {
              flatData {
                homepage {
                  url
                }
                search {
                  url
                }
                compare {
                  url
                }
                selfAssess {
                  url
                }
                generic {
                  url
                }
              }
            }
            queryTranslationsContents {
              flatData {
                content
                key
              }
            }
          }
        `,
      }),
      keepUnusedDataFor: 900,
      transformResponse: (response: CMSQuery['shellContent']) => {
        const translationObject =
          response?.queryTranslationsContents?.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.flatData?.key || '']: curr.flatData?.content,
            }),
            {}
          ) ?? {};

        saveTranslationsToLocal(translationObject);

        return {
          footer: response?.queryFooterContents?.[0]?.flatData,
          resourceMenuItems:
            response?.queryResourcesContents?.[0]?.flatData?.items,
          banners: Object.entries(
            response?.queryBannersContents?.[0]?.flatData || {}
          ).reduce(
            (acc, [key, items]) => ({
              ...acc,
              [key]: items?.[0] || {},
            }),
            {} as Record<string, CMSAsset>
          ),
          translations: translationObject,
        };
      },
    }),
    getGenericPage: builder.query<
      GenericPage | undefined,
      { key: string; locale: string }
    >({
      query: ({ key, locale }) => ({
        document: gql`
          fragment accordionDataFaq on Faq {
            flatData {
              title
              titleIcon {
                url
              }
              content
              contentBottom
              children {
                flatData {
                  title
                  titleIcon {
                    url
                  }
                  content
                  contentBottom
                  children {
                    flatData {
                      title
                      titleIcon {
                        url
                      }
                      content
                      contentBottom
                      children {
                        flatData {
                          title
                          titleIcon {
                            url
                          }
                          content
                          contentBottom
                          children {
                            flatData {
                              title
                              titleIcon {
                                url
                              }
                              content
                              contentBottom
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          fragment accordionDataClassification on Classification {
            flatData {
              title
              titleIcon {
                url
              }
              content
              contentBottom
              children {
                flatData {
                  title
                  titleIcon {
                    url
                  }
                  content
                  contentBottom
                  children {
                    flatData {
                      title
                      titleIcon {
                        url
                      }
                      content
                      contentBottom
                      children {
                        flatData {
                          title
                          titleIcon {
                            url
                          }
                          content
                          contentBottom
                          children {
                            flatData {
                              title
                              titleIcon {
                                url
                              }
                              content
                              contentBottom
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          fragment accordionDataMethodology on Methodology {
            flatData {
              title
              titleIcon {
                url
              }
              content
              contentBottom
              children {
                flatData {
                  title
                  titleIcon {
                    url
                  }
                  content
                  contentBottom
                  children {
                    flatData {
                      title
                      titleIcon {
                        url
                      }
                      content
                      contentBottom
                      children {
                        flatData {
                          title
                          titleIcon {
                            url
                          }
                          content
                          contentBottom
                          children {
                            flatData {
                              title
                              titleIcon {
                                url
                              }
                              content
                              contentBottom
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          query QueryGenericPage($filter: String!) {
            queryGenericPageContents(filter: $filter) {
              flatData {
                key
                title
                bannerImage {
                  url
                }
                content
                contentBottom
                accordion {
                  title
                  items {
                    ...accordionDataFaq
                    ...accordionDataClassification
                    ...accordionDataMethodology
                  }
                }
              }
            }
          }
        `,
        variables: {
          filter: `data/key/iv eq '${key}'`,
        },
      }),
      keepUnusedDataFor: 900,
      transformResponse: (response: CMSQuery['genericPage']) =>
        response?.queryGenericPageContents?.[0]?.flatData,
    }),
    getSelfAssessContent: builder.query<SelfAssessPage | undefined, string>({
      query: (locale) => ({
        document: gql`
          query QuerySelfAssess($filter: String!) {
            querySelfAssessContents(filter: $filter) {
              flatData {
                usefulInfoItems {
                  title
                  content
                }
                footerTitle
                footerCTALabel
                footerCTALink
              }
            }
          }
        `,
        variables: {
          filter: `data/key/iv eq 'self-assess'`,
        },
      }),
      keepUnusedDataFor: 900,
      transformResponse: (response: CMSQuery['selfAssess']) =>
        response?.querySelfAssessContents?.[0]?.flatData,
    }),
  }),
});

const {
  useGetHomePageQuery,
  useGetGenericPageQuery,
  useGetShellContentQuery,
  useGetSelfAssessContentQuery,
} = cmsApi;

export function useGetHomePage() {
  const dispatch = useDispatch();
  const bearerToken = useSelector(selectBearerToken);
  const locale = useGetCurrentLocale();
  const info = useGetHomePageQuery(bearerToken ? { locale } : skipToken);

  useEffect(() => {
    if (!bearerToken) {
      dispatch(fetchBearerToken());
    }
  }, [bearerToken, dispatch]);

  return info;
}

export function useGetShellContent() {
  const dispatch = useDispatch();
  const bearerToken = useSelector(selectBearerToken);
  const locale = useGetCurrentLocale();
  const info = useGetShellContentQuery(bearerToken ? { locale } : skipToken);

  useEffect(() => {
    if (!bearerToken) {
      dispatch(fetchBearerToken());
    }
  }, [bearerToken, dispatch]);

  return info;
}

export function useGetSelfAssessContent() {
  const dispatch = useDispatch();
  const bearerToken = useSelector(selectBearerToken);
  const locale = useGetCurrentLocale();
  const info = useGetSelfAssessContentQuery(bearerToken ? locale : skipToken);

  useEffect(() => {
    if (!bearerToken) {
      dispatch(fetchBearerToken());
    }
  }, [bearerToken, dispatch]);

  return info;
}

export function useContent(key: string) {
  const dispatch = useDispatch();
  const bearerToken = useSelector(selectBearerToken);
  const locale = useGetCurrentLocale();

  const info = useGetGenericPageQuery(
    bearerToken ? { key, locale } : skipToken
  );

  useEffect(() => {
    if (!bearerToken) {
      dispatch(fetchBearerToken());
    }
  }, [bearerToken, dispatch]);

  return info;
}
