import React from 'react';
import ProfileManagement from './ProfileManagement';
import styles from './SelfAssessFrontPage.module.scss';
import SelfAssessFrontPageCreateProfile from './SelfAssessFrontPageCreateProfile';

const SelfAssessFrontPage = () => {
  return (
    <div className={styles.wrapper}>
      <ProfileManagement className={styles.profileManagement} />
      <SelfAssessFrontPageCreateProfile className={styles.form} />
    </div>
  );
};

export default SelfAssessFrontPage;
