import {
  createApi,
  fetchBaseQuery,
  skipToken,
} from '@reduxjs/toolkit/query/react';
import { InitUserSessionResponse, InitUserSessionPayload } from 'ApiServices';
import config, { authConfig } from 'config';
import { User } from 'oidc-client-ts';
import { useAuth } from 'react-oidc-context';
import { local } from 'store/storage';

export function getUser() {
  const oidcStorage = local.getItem(
    `oidc.user:${authConfig.authority}:${authConfig.client_id}`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiPrimary}/v1/`,
    prepareHeaders: (headers) => {
      const user = getUser();
      const accessToken = user?.access_token;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
        headers.set('X-API-UserId', user.profile.sub);
      }

      return headers;
    },
  }),
  tagTypes: ['Session'],
  endpoints: (builder) => ({
    initUserSession: builder.query<
      InitUserSessionResponse,
      InitUserSessionPayload
    >({
      query: (body) => ({
        url: 'init-user-session',
        method: 'post',
        body: {
          Email: body.email,
          TmiUserId: body.tmiUserId,
          UserGuid: body.userGuid,
        },
      }),
      providesTags: ['Session'],
    }),
    endUserSession: builder.mutation<void, void>({
      query: () => ({
        url: 'end-user-session',
        responseHandler: 'text',
      }),
      invalidatesTags: ['Session'],
    }),
  }),
});

const { useInitUserSessionQuery } = authApi;

export function useInitUserSession() {
  const auth = useAuth();

  const { currentData } = useInitUserSessionQuery(
    auth.user?.access_token
      ? {
          email: auth.user.profile.email,
          userGuid: auth.user.profile.sub,
          tmiUserId: (auth.user.profile as { user_id?: string }).user_id,
        }
      : skipToken
  );

  return currentData;
}

export const { useEndUserSessionMutation } = authApi;
