import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './Header.module.scss';
import { ReactComponent as ITCLogo } from 'assets/images/logo-itc.svg';
import { ReactComponent as FunctionIndicatorIcon } from 'assets/images/function-indicator.svg';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import LocaleSwitcher from 'components/LocaleSwitcher';
import Divider from 'components/Divider';
import Button, { ButtonProps } from 'components/Button';
import useGetLocaleFromStore from 'hooks/useLocale';
import { useAuth } from 'react-oidc-context';
import { Dropdown } from 'react-bootstrap';
import { authConfig } from 'config';
import { useEndUserSessionMutation, useInitUserSession } from 'services/auth';

const CustomButton = React.forwardRef((props: ButtonProps, ref) => (
  <Button {...props} hasCornerCut />
));

const Header = () => {
  const locale = useGetLocaleFromStore();

  return (
    <>
      <header className={clsx('container-xxl px-0', styles.header)}>
        <Link to={`/${locale}`} style={{ textDecoration: 'none' }}>
          <div className={styles.logoWithText}>
            <ITCLogo height={77} className={styles.logo} />
            <div className={styles.divider} />
            <div className={styles.title}>
              <FormattedMessage
                id="header.title"
                defaultMessage="Non-tariff Measures Self Assessment Tool"
              />
              <b style={{ color: 'var(--bs-secondary)', marginInlineStart: 4 }}>
                {' '}
                BETA{' '}
              </b>
            </div>
          </div>
        </Link>
        <div className={styles.rightRegion}>
          <div>
            <span className={styles.description}>
              <FormattedMessage
                id="header.description"
                defaultMessage="Be ready to trade"
              />
            </span>
            <FunctionIndicatorIcon />
          </div>
          <div className={styles.divider} />
          <div className={styles.action}>
            <AuthDisplay />
            <LocaleSwitcher />
          </div>
        </div>
      </header>
      <Divider />
    </>
  );
};

function AuthDisplay() {
  const auth = useAuth();
  const [endSession] = useEndUserSessionMutation();
  useInitUserSession();

  // automatically sign-in
  // React.useEffect(() => {
  //   if (
  //     !hasAuthParams() &&
  //     !auth.isAuthenticated &&
  //     !auth.activeNavigator &&
  //     !auth.isLoading
  //   ) {
  //     auth.signinRedirect();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // React.useEffect(() => {
  //   // the `return` is important - addAccessTokenExpiring() returns a cleanup function
  //   return auth.events.addAccessTokenExpiring(() => {
  //     if (
  //       prompt(
  //         "You're about to be signed out due to inactivity. Press continue to stay signed in."
  //       )
  //     ) {
  //       auth.signinSilent();
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [auth.events, auth.signinSilent]);

  if (auth.error) {
    // TODO: display error toast or sth
  }

  if (auth.isAuthenticated) {
    return (
      <div className={styles.dropdown}>
        <Dropdown>
          <Dropdown.Toggle as={CustomButton} className={styles.userBtn}>
            <span>{auth.user?.profile?.given_name}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu align="end">
            <Dropdown.Header>
              <strong>{auth.user?.profile?.name}</strong>
              <div>{auth.user?.profile?.email}</div>
            </Dropdown.Header>
            <Dropdown.Divider />
            <Dropdown.Item href={authConfig.authority} target="__blank">
              <FormattedMessage
                id="button.action.myAccount"
                defaultMessage="My account"
              />
            </Dropdown.Item>
            <Dropdown.Item
              as="button"
              onClick={() => {
                auth.removeUser();
                endSession();
                auth.signoutRedirect({
                  id_token_hint: auth.user?.id_token,
                });
              }}
            >
              <FormattedMessage
                id="button.action.logOut"
                defaultMessage="Log out"
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  return (
    <Button
      variant="primary"
      hasCornerCut={true}
      className={styles.loginBtn}
      onClick={() =>
        void auth.signinRedirect({
          state: {
            title: window.document.title,
            href: window.location.href,
            pathname: window.location.pathname,
          },
        })
      }
      disabled={!!(auth.activeNavigator || auth.isLoading)}
    >
      <FormattedMessage id="button.action.logIn" defaultMessage="Log in" />
    </Button>
  );
}

export default React.memo(Header);
