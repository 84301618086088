import React from 'react';
import { MeasureGroupOverview } from 'AppTypes';
import { useGetSearchMeasureGroupDetail } from 'hooks/useMeasureGroup';
import Loading from 'components/Loading';
import MeasureGroupDetails from 'modules/app/MeasureGroupDetails';
import { FormattedMessage } from 'react-intl';

type Props = {
  measureGroup: MeasureGroupOverview;
};

const SearchMeasureGroupDetails = ({ measureGroup }: Props) => {
  const response = useGetSearchMeasureGroupDetail(measureGroup);
  const { data: indicators } = response;

  return (
    <Loading response={response}>
      {indicators?.length ? (
        <MeasureGroupDetails
          measureGroup={measureGroup}
          indicators={indicators}
        />
      ) : (
        <FormattedMessage
          id="message.noDataFound"
          defaultMessage="No data found"
        />
      )}
    </Loading>
  );
};

export default React.memo(SearchMeasureGroupDetails);
