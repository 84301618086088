import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Accordion } from 'react-bootstrap';
import styles from './HSItem.module.scss';
import HSItemLeaf, { HSItemProps } from './HSItemLeaf';
import { getFormattedProductCode } from 'utils/helpers';
import { useWatch } from 'react-hook-form';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

export const HSItem = ({
  item,
  index,
  level = 0,
  onClick,
  updateAccordionFn,
}: HSItemProps & {
  searchInput?: string;
  updateAccordionFn?: (code: string) => void;
}) => {
  const [searchValue, countryCode] = useWatch({
    name: ['search', 'countryCode'],
  });

  const formattedItemCode = useMemo(() => {
    const formattedCode = getFormattedProductCode(item.code, level);

    if (searchValue && item.code?.includes(searchValue)) {
      return <span className={styles.highlight}>{formattedCode}</span>;
    }

    return formattedCode;
  }, [item.code, level, searchValue]);

  const [activeKey, setActiveKey] = useState<string[]>([]);

  useEffect(() => {
    setActiveKey(
      searchValue && level < 2
        ? item.children?.map((child) => child.code) ?? []
        : []
    );
  }, [item.children, level, searchValue]);

  const onClickHeader = useCallback(() => {
    onClick?.(item, level);
    updateAccordionFn?.(item.code);
  }, [item, level, onClick, updateAccordionFn]);

  const onUpdateAccordion = useCallback(
    (code: string) => {
      if (activeKey.includes(code)) {
        setActiveKey((keys) => keys.filter((key) => key !== code));
      } else {
        setActiveKey((keys) => [...keys, code]);
      }
    },
    [activeKey]
  );

  const formattedName = useMemo(() => {
    const matches = match(item.name, searchValue, {
      findAllOccurrences: true,
      insideWords: true,
    });

    return parse(item.name, matches).map(({ text, highlight }, index) => {
      return highlight ? (
        <span key={index} className={styles.highlight}>
          {text}
        </span>
      ) : (
        <React.Fragment key={index}>{text}</React.Fragment>
      );
    });
  }, [item.name, searchValue]);

  if (level === 0) {
    return (
      <div className={styles.sectionContainer}>
        <div className={styles.section}>
          <div>
            <div className={styles.code}>Section {formattedItemCode}</div>
          </div>
          <div className={styles.name}>{formattedName}</div>
        </div>
        {!!item.children?.length && (
          <Accordion bsPrefix="code-finder" alwaysOpen activeKey={activeKey}>
            {item.children.map((item, index) => (
              <HSItem
                item={item}
                key={item.code}
                index={index}
                level={level + 1}
                onClick={onClick}
                updateAccordionFn={onUpdateAccordion}
              />
            ))}
          </Accordion>
        )}
      </div>
    );
  }

  return (
    <Accordion.Item
      eventKey={item.code}
      className={clsx(
        level === 1 && index % 2 === 0 && styles.oddRow,
        styles.level
      )}
    >
      <Accordion.Header
        onClick={onClickHeader}
        className={clsx(styles[`level${level}`], level > 1 && styles.deepLevel)}
      >
        <div className={styles.itemHeader}>
          <div className={styles.codeContainer}>
            <div className={styles.code}>{formattedItemCode}</div>
          </div>
          <div className={styles.name}>{formattedName}</div>
        </div>
      </Accordion.Header>
      <Accordion.Collapse eventKey={item.code} mountOnEnter={true}>
        <div>
          {!!item.children?.length && (
            <Accordion
              bsPrefix="code-finder"
              alwaysOpen
              className={styles[`level${level + 1}`]}
              activeKey={activeKey}
            >
              {item.children.map((item, index) => (
                <HSItem
                  item={item}
                  key={item.code}
                  index={index}
                  level={level + 1}
                  onClick={onClick}
                  updateAccordionFn={onUpdateAccordion}
                />
              ))}
            </Accordion>
          )}
          {level === 3 && (
            <HSItemLeaf
              item={item}
              index={index}
              level={level + 1}
              onClick={onClick}
              countryCode={countryCode}
            />
          )}
        </div>
      </Accordion.Collapse>
    </Accordion.Item>
  );
};

export default React.memo(HSItem);
