import { Column, Table } from '@tanstack/react-table';
import Button from 'components/Button';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form, InputGroup, Overlay, Popover } from 'react-bootstrap';
import { Filter, FilterSquareFill, Search } from 'react-bootstrap-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './ExcelFilter.module.scss';

type Props = {
  column: Column<any, unknown>;
  table: Table<any>;
};

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = ({
  column,
  table,
  hidePopover,
  isEnter,
}: Props & { hidePopover: () => void; isEnter: boolean }) => {
  const [tmpFilterValue, setTmpFilterValue] = useState<Record<string, boolean>>(
    () => {
      const columnFilterValue = column.getFilterValue();
      return Array.from(column.getFacetedUniqueValues().keys()).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: !!(columnFilterValue as unknown[])?.includes(curr),
        }),
        {} as Record<string, boolean>
      );
    }
  );
  const [inputValue, setInputValue] = useState<string>('');

  const sortedUniqueValues = useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column]
  );

  const valuesToDisplay = useMemo(
    () =>
      sortedUniqueValues.filter((item) => item && item.includes(inputValue)),
    [inputValue, sortedUniqueValues]
  );

  const selectAllValuesInView = useCallback(() => {
    setTmpFilterValue((tmpFilter) => {
      const newFilter = { ...tmpFilter };

      for (const value of valuesToDisplay) {
        newFilter[value] = true;
      }

      return newFilter;
    });
  }, [valuesToDisplay]);

  const clearAllValuesInView = useCallback(() => {
    setTmpFilterValue((tmpFilter) => {
      const newFilter = { ...tmpFilter };

      for (const value of valuesToDisplay) {
        newFilter[value] = false;
      }

      return newFilter;
    });
  }, [valuesToDisplay]);

  const isColumnFiltered = useMemo(() => column.getIsFiltered(), [column]);

  const onSubmitFilter = useCallback(() => {
    const filterItems = Object.entries(tmpFilterValue)
      .filter(([key, isChecked]) => isChecked)
      .map(([key]) => key);
    column.setFilterValue(filterItems);
    hidePopover();
  }, [column, hidePopover, tmpFilterValue]);

  const onCloseFilter = useCallback(() => {
    hidePopover();
  }, [hidePopover]);

  const onClearFilter = useCallback(() => {
    column.setFilterValue([]);
    hidePopover();
  }, [column, hidePopover]);

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isEnter) {
      buttonRef.current?.focus({ preventScroll: true });
    }
  }, [isEnter]);

  return (
    <div>
      <div className={styles.topActions}>
        <div>
          <Button
            ref={buttonRef}
            variant="link"
            size="sm"
            onClick={selectAllValuesInView}
          >
            <FormattedMessage
              id="button.action.selectAll"
              defaultMessage="Select all"
            />
          </Button>
          <Button variant="link" size="sm" onClick={clearAllValuesInView}>
            <FormattedMessage id="button.action.clear" defaultMessage="Clear" />
          </Button>
        </div>
        <div>
          {isColumnFiltered && (
            <Button variant="link" size="sm" onClick={onClearFilter}>
              <FormattedMessage
                id="button.action.clearFilter"
                defaultMessage="Clear filter"
              />
            </Button>
          )}
        </div>
      </div>
      <InputGroup>
        <Form.Control
          placeholder="Search"
          onChange={(e) => setInputValue(e.target.value)}
          value={inputValue}
          aria-describedby={`${column.id}-addon`}
        />
        <InputGroup.Text id={`${column.id}-addon`}>
          <Search />
        </InputGroup.Text>
      </InputGroup>
      <div className={styles.listWrapper}>
        <div className={styles.list}>
          {valuesToDisplay.map((item, index) => (
            <Form.Check
              type="checkbox"
              key={index}
              id={`${column.id}-${index}`}
              label={item}
              onChange={() => {
                setTmpFilterValue((value) => ({
                  ...value,
                  [item]: !value[item],
                }));
              }}
              checked={tmpFilterValue[item]}
              className={styles.checkbox}
            />
          ))}
        </div>
      </div>
      <div className={styles.bottomActions}>
        <Button
          type="button"
          onClick={onSubmitFilter}
          className={styles.submitBtn}
        >
          <FormattedMessage id="button.action.ok" defaultMessage="OK" />
        </Button>
        <Button variant="light" type="button" onClick={onCloseFilter}>
          <FormattedMessage id="button.action.cancel" defaultMessage="Cancel" />
        </Button>
      </div>
    </div>
  );
};

const ExcelFilter = ({ column, table }: Props) => {
  const [show, setShow] = useState(false);
  const [isEnter, setEnter] = useState(false);
  const btnTarget = useRef(null);
  const intl = useIntl();

  const hidePopover = useCallback(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    if (!show) {
      setEnter(false);
    }
  }, [show]);

  return (
    <div>
      <Button
        ref={btnTarget}
        type="button"
        onClick={() => setShow(true)}
        aria-label={intl.formatMessage({
          id: 'button.action.filter',
          defaultMessage: 'Filter',
        })}
        className={styles.filterBtnTrigger}
      >
        {column.getIsFiltered() ? (
          <FilterSquareFill fontSize={20} />
        ) : (
          <Filter fontSize={20} />
        )}
      </Button>
      <Overlay
        target={btnTarget.current}
        placement="bottom-start"
        show={show}
        rootClose
        onHide={() => setShow(false)}
        onEnter={() => setEnter(true)}
      >
        <Popover>
          <Popover.Body>
            <CustomMenu
              column={column}
              table={table}
              hidePopover={hidePopover}
              isEnter={isEnter}
            />
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default ExcelFilter;
