import { concat, of } from 'rxjs';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
// import { addError } from "modules/ui/reducer";
// import { selectCurrentLanguage } from "modules/ui/selectors";
import { selectBearerToken } from '../selectors';
import { fetchContent, setContent } from '../cmsSlice';
import * as constants from '../../../modules/constants';
import { ajax, AjaxConfig } from 'rxjs/ajax';
import { AppEpic } from 'store';
import { CMSRequestConfig } from 'StoreTypes';

const fetchContentEpic: AppEpic = (action$, state$, { config }) => {
  const request = (params: CMSRequestConfig): AjaxConfig => {
    const defaultQuery = {
      filter: {
        path: 'data.key.iv',
        op: 'eq',
        value: params.contentKey,
      },
    };

    return {
      responseType: 'json',
      method: 'GET',
      url: `${config.cmsApiRoot}/${params.contentType}?q=${JSON.stringify(
        params.query ? params.query : defaultQuery
      )}`,
      headers: {
        Authorization: `Bearer ${params.bearerToken}`,
        'X-Languages': params.language,
        'X-Flatten': true,
      },
    };
  };

  return action$.pipe(
    filter(fetchContent.match),
    mergeMap((action) =>
      concat(
        of(
          setContent({
            key: `${action.payload.contentType}/${action.payload.contentKey}`,
            status: constants.STATUS_LOADING,
          })
        ),
        state$.pipe(
          map(selectBearerToken),
          filter(Boolean),
          take(1),
          map(() => ({
            ...action.payload,
            storeKey: `${action.payload.contentType}/${action.payload.contentKey}`,
            // language: selectCurrentLanguage(state$.value),
            language: 'en',
            bearerToken: selectBearerToken(state$.value),
          })),
          mergeMap((params: CMSRequestConfig<AjaxConfig>) =>
            ajax(request(params)).pipe(
              mergeMap((data) =>
                of(
                  setContent({
                    key: params.storeKey,
                    status: constants.STATUS_OK,
                    response: data.response,
                  })
                )
              ),
              catchError(() =>
                concat(
                  of(
                    setContent({
                      key: params.storeKey,
                      status: constants.STATUS_ERROR,
                    })
                  ),
                  of()
                  // addError({
                  //   timestamp: timestamp(),
                  //   message: "cms.api-error",
                  //   details: e,
                  // })
                )
              )
            )
          )
        )
      )
    )
  );
};

export default fetchContentEpic;
