import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Country } from 'AppTypes';
import config from 'config';
import { useGetCurrentLocale } from 'hooks/useLocale';
import { useMemo } from 'react';

export const countriesApi = createApi({
  reducerPath: 'countryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiPrimary}/v1/`,
  }),
  endpoints: (builder) => ({
    fromCountries: builder.query<Country[], string>({
      query: (lang) => ({
        url: 'countries',
        params: {
          dir: 'from',
          lang,
        },
      }),
    }),
    toCountries: builder.query<Country[], string>({
      query: (lang) => ({
        url: 'countries',
        params: {
          dir: 'to',
          lang,
        },
      }),
    }),
  }),
});

const { useFromCountriesQuery, useToCountriesQuery } = countriesApi;

export function useGetFromCountriesQuery() {
  const locale = useGetCurrentLocale();
  return useFromCountriesQuery(locale);
}

export function useGetToCountriesQuery() {
  const locale = useGetCurrentLocale();
  return useToCountriesQuery(locale);
}

export function useGetFromCountriesById() {
  const { data = [], ...response } = useGetFromCountriesQuery();

  const byId = useMemo(
    () =>
      data.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.code]: curr,
        }),
        {} as Record<string, Country>
      ),
    [data]
  );

  return { data: byId, ...response };
}

export function useGetToCountriesById() {
  const { data = [], ...response } = useGetToCountriesQuery();

  const byId = useMemo(
    () =>
      data.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.code]: curr,
        }),
        {} as Record<string, Country>
      ),
    [data]
  );

  return { data: byId, ...response };
}
