import React, { useEffect, useRef, useState } from 'react';
import SectionHeader from 'components/SectionHeader';
import styles from './CommonMeasureTypeSection.module.scss';
import MeasureCount from './MeasureCount';
import clsx from 'clsx';
import CompareCommonNTMAccordion from 'modules/compare/components/CompareCommonNTMAccordion';
import { useIntersectionObserver } from 'usehooks-ts';
import { CompareResultCommonTypeDetail } from 'AppTypes';

type Props = {
  title: React.ReactNode;
  icon: React.ReactNode;
  className?: string;
  compareData: CompareResultCommonTypeDetail;
};

const CommonMeasureTypeSection = ({
  title,
  icon,
  className,
  compareData,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (entry && !entry.isIntersecting) {
      setIsSticky(true);
    }
    if (entry && entry.isIntersecting) {
      setIsSticky(false);
    }
  }, [entry]);

  return (
    <div
      className={clsx(
        styles.section,
        className,
        isSticky && 'section-has-sticky'
      )}
    >
      <div
        ref={ref}
        className={clsx('productNameSection-sentinel', styles.sentinel)}
      />
      <SectionHeader title={title} icon={icon} wrapperClassName={styles.header}>
        <MeasureCount measureCount={compareData.totalCount1 ?? 0} />
        <MeasureCount measureCount={compareData.totalCount2 ?? 0} />
      </SectionHeader>
      <CompareCommonNTMAccordion data={compareData.items} />
    </div>
  );
};

export default CommonMeasureTypeSection;
