import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/router';

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(getRoute('home'));
  };

  return (
    <div role="alert">
      <p>
        <FormattedMessage
          id="error.title"
          defaultMessage="Something went wrong, please try again."
        />
      </p>
      <button onClick={handleClick}>
        <FormattedMessage id="error.action" defaultMessage="Try again" />
      </button>
    </div>
  );
};

export default ErrorPage;
