/**
 * Containing mapping to remote CMS assets
 * Might be refactored to return a config from CMS altogether
 */

import config from 'config';
import querystring from 'query-string';
import { useCallback, useMemo } from 'react';
import { Optional } from 'utility-types';

type RemoteImageConfig = {
  format: 'webp' | 'jpeg' | 'png';
  quality?: number;
  version?: number;
  cache?: number;
  download?: number;
  width?: number;
  height?: number;
  mode?: 'Crop' | 'CropUpsize' | 'Pad' | 'BoxPad' | 'Max' | 'Min' | 'Stretch';
  bg?: string;
  focusX?: number;
  focusY?: number;
  nofocus?: boolean;
  auto?: boolean;
  force?: boolean;
};

const remoteAssetMapping = {
  // MeasureTypes
  other: 'other',
  sanitary: 'sanitary',
  technicalBarriers: 'technical-barriers',
  technicalRegulations: 'technical-regulations',
  conformityAssessment: 'conformity-assessment',
  selfAssess: 'checklist',
};

export function getRemoteAssetURL(
  assetKey: keyof typeof remoteAssetMapping,
  noPadding?: boolean,
  fileType = 'svg'
) {
  return `${config.cmsAssetRoot}/${remoteAssetMapping[assetKey]}${
    noPadding ? '-np' : ''
  }.${fileType}`;
}

export function getRemoteImageURLByConfig(
  url: string,
  config?: RemoteImageConfig
) {
  return querystring.stringifyUrl({
    url,
    query: {
      ...config,
    },
  });
}

export function getMeasureIcon(code: string) {
  const normalizedCode = code.toLowerCase();
  const assetKey =
    normalizedCode.startsWith('a') || normalizedCode.startsWith('b')
      ? normalizedCode
      : 'other';
  return `${config.cmsAssetRoot}/${assetKey}.svg`;
}

const IMAGE_SIZE_MULTIPLIERS = [0.25, 0.5, 1];

export function RemoteImage({
  url,
  className,
  originalWidth = 3840,
  ...config
}: Optional<RemoteImageConfig, 'format'> & {
  url: string;
  className?: string;
  originalWidth?: number;
}) {
  const getImgUrls = useCallback(
    (format: RemoteImageConfig['format']) =>
      IMAGE_SIZE_MULTIPLIERS.map((multiplier) => {
        return getRemoteImageURLByConfig(
          url,
          multiplier !== 1
            ? {
                ...config,
                format,
                width: multiplier * originalWidth,
              }
            : { ...config, format }
        );
      }),
    [config, originalWidth, url]
  );

  const webpImgs = useMemo(() => getImgUrls('webp'), [getImgUrls]);
  const jpegImgs = useMemo(() => getImgUrls('jpeg'), [getImgUrls]);

  return (
    <picture className={className}>
      <source
        srcSet={`${webpImgs[0]} 0.5x, ${webpImgs[1]} 1x, ${webpImgs[2]} 2x`}
        type="image/webp"
      />
      <img
        src={jpegImgs[1]}
        alt=""
        srcSet={`${jpegImgs[0]} 0.5x, ${jpegImgs[1]} 1x, ${jpegImgs[2]} 2x`}
      />
    </picture>
  );
}
