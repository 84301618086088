import React, { useCallback, useContext, useMemo, useState } from 'react';
import styles from './Search.module.scss';
import clsx from 'clsx';
import Card from 'components/Card';
import { DownloadButton } from 'components/Button';
import ResultSummary from 'modules/app/ResultSummary';
import {
  MeasureFilterAndMeasureTypeMapping,
  MeasureType,
  MeasureTypeDetails,
} from 'modules/constants';
import { SearchResult, SelfAssessProfileForm } from 'AppTypes';
import MeasureGroupList from 'modules/app/MeasureGroupList';
import { groupBy } from 'lodash';
import NoResultFound from 'components/NoResultFound';
import SearchForm from './SearchForm';
import SearchInfoMetadata from './SearchInfoMetadata';
import Loading from 'components/Loading';
import { useGetShellContent } from 'services/cms';
import { getRemoteAssetURL, RemoteImage } from 'assets/remote';
import { MeasureFilterViewContext } from 'modules/app/MeasureFilterViewContext';
import { fetchSearchDataFile } from 'services/search';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { useGetSearchParams } from 'hooks/useGetSearchParams';
import useGetLocaleFromStore from 'hooks/useLocale';
import { saveAs } from 'file-saver';
import QuickCreateSelfAssessProfileDialog from 'modules/self-assess/profile/QuickCreateSelfAssessProfileDialog';
import BaseSquareButton from 'components/Button/BaseSquareButton';

type Props = {
  results?: SearchResult;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  hasSearchParams?: boolean;
};

const Search = ({
  results,
  isLoading,
  isError,
  isSuccess,
  hasSearchParams,
}: Props) => {
  const { data } = useGetShellContent();
  const { filterView } = useContext(MeasureFilterViewContext);
  const [openingTab, setOpeningTab] = useState<MeasureType | null>(null);
  const { partner, reporterId, productId } = useGetSearchParams();
  const locale = useGetLocaleFromStore();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [selectedReporterId, setSelectedReporterId] = useState<string | null>(
    null
  );

  const defaultSelfAssessProfileParams = useMemo(
    (): SelfAssessProfileForm => ({
      partner: partner || '',
      reporterId: reporterId || '',
      productCode: productId || '',
      profileName: '',
    }),
    [partner, productId, reporterId]
  );

  const categorizedTabData = useMemo(() => {
    return groupBy(
      results?.data || [],
      (item) => `${item.groupOption}_${item.measureType}`
    );
  }, [results?.data]);

  const [loading, setLoading] = useState(false);

  const onClickDownloadReport = useCallback(async () => {
    if (partner && reporterId && productId && locale) {
      setLoading(true);
      try {
        const blobData = await fetchSearchDataFile({
          partner,
          reporterId,
          productId,
          language: locale,
        });
        saveAs(blobData, `search-results-${new Date().toLocaleString()}.xlsx`);
      } catch (e) {
        toast.error(
          <FormattedMessage
            id="message.errorOccurred"
            defaultMessage="An unexpected error occurred, please try again"
          />
        );
      } finally {
        setLoading(false);
      }
    }
  }, [locale, partner, productId, reporterId]);

  return (
    <>
      <section className={styles.widgetArea}>
        {data?.banners?.search?.url && (
          <RemoteImage
            url={data?.banners?.search?.url}
            className={styles.background}
          />
        )}
        <div className={clsx('sub-container')}>
          <Card className={styles.card}>
            <div>
              <SearchForm
                horizontalLayout
                onChangeToCountry={setSelectedReporterId}
              />
            </div>
            <div className={styles.sourceInfo}>
              <div>
                {selectedReporterId && (
                  <SearchInfoMetadata
                    className={styles.list}
                    reporterId={selectedReporterId}
                  />
                )}
              </div>
              <div className="flex-layout">
                <BaseSquareButton
                  disabled={
                    loading || !results?.data || results?.data?.length <= 0
                  }
                  onClick={() => void setOpenCreateDialog(true)}
                  icon={
                    <img
                      src={getRemoteAssetURL('selfAssess')}
                      alt=""
                      width={17}
                    />
                  }
                  message={
                    <FormattedMessage
                      id="button.action.createNewAssessmentProfile"
                      defaultMessage="Self Assess"
                    />
                  }
                />
                <QuickCreateSelfAssessProfileDialog
                  open={openCreateDialog}
                  setOpen={setOpenCreateDialog}
                  defaultValue={defaultSelfAssessProfileParams}
                />
                <DownloadButton
                  disabled={
                    loading || !results?.data || results?.data?.length <= 0
                  }
                  onClick={onClickDownloadReport}
                />
              </div>
            </div>
          </Card>
          <Loading
            isLoading={isLoading}
            isError={isError}
            isSuccess={isSuccess}
            loadingWrapperClassName={styles.loading}
          >
            {results?.summary ? (
              <ResultSummary
                openingTab={openingTab}
                setOpeningTab={setOpeningTab}
                summary={results?.summary}
              />
            ) : (
              hasSearchParams && <NoResultFound />
            )}
          </Loading>
        </div>
      </section>
      {MeasureFilterAndMeasureTypeMapping[filterView]?.map(
        (measureType, index) => {
          const categoryData =
            categorizedTabData[`${filterView}_${measureType}`];

          return (
            <React.Fragment key={index}>
              {openingTab && categoryData && (
                <div
                  className={clsx(
                    'sub-container',
                    styles.measureGroup,
                    !(openingTab === measureType && categoryData.length) &&
                      'd-none'
                  )}
                >
                  <MeasureGroupList
                    data={categoryData}
                    icon={MeasureTypeDetails[measureType]?.icon}
                    title={MeasureTypeDetails[measureType]?.title}
                  />
                </div>
              )}
            </React.Fragment>
          );
        }
      )}
    </>
  );
};

export default Search;
