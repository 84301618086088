import React from 'react';
import { Navigate } from 'react-router-dom';

const LogoutCallback = () => {
  // TODO: send logout request to BE API

  return <Navigate to="/" />;
};

export default LogoutCallback;
