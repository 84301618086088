import React, { useEffect, useRef, useState } from 'react';
import Button from 'components/Button';
import { Overlay, Popover, Spinner } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Select, { StylesConfig } from 'react-select';
import { Search } from 'react-bootstrap-icons';
import { AssessProfile } from 'AppTypes';
import { CustomOption } from 'modules/self-assess/widgets/SelfAssessWidgetBody';
import styles from './SelfAssessProfile.module.scss';
import { useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/router';

const selectStyles: StylesConfig<AssessProfile, false> = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 8,
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

const DropdownIndicator = () => <Search />;

type Props = {
  profiles: AssessProfile[];
  className?: string;
};

const QuickChangeSelfAssessProfile = ({ profiles, className }: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const [isEnter, setEnter] = useState<boolean>(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const selectRef = useRef<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (isEnter) {
      selectRef.current?.focus({ preventScroll: true });
    }

    if (!isEnter && selectRef.current) {
      selectRef.current?.focus({ preventScroll: true });
    }
  }, [isEnter]);

  return (
    <div className={className}>
      <Button
        className={styles.switchProfileBtn}
        onClick={() => setShow((prev) => !prev)}
        variant="outline-primary"
        size="sm"
        ref={btnRef}
      >
        <FormattedMessage
          id="button.action.changeAssessmentProfile"
          defaultMessage="Change"
        />
      </Button>
      <Overlay
        target={btnRef.current}
        show={show}
        rootClose
        onHide={() => setShow(false)}
        onEntered={() => setEnter(true)}
        onExited={() => {
          setEnter(false);
        }}
        container={document.body}
        placement="bottom"
      >
        <Popover>
          {isEnter ? (
            <Select
              ref={selectRef}
              autoFocus={false}
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Option: CustomOption,
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={(profile) => {
                setShow(false);
                profile &&
                  navigate(
                    getRoute(
                      'fullSelfAssessmentProfileById',
                      profile.assessmentProfileId
                    )
                  );
              }}
              options={profiles}
              styles={selectStyles}
              tabSelectsValue={false}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              className="react-select"
              classNamePrefix="react-select"
            />
          ) : (
            <div
              style={{ width: '298px' }}
              className="p-2 d-flex align-items-center justify-content-center"
            >
              <Spinner animation="border" className="me-3" />
              <strong>
                <FormattedMessage
                  id="message.loading"
                  defaultMessage="Loading, please wait..."
                />
              </strong>
            </div>
          )}
        </Popover>
      </Overlay>
    </div>
  );
};

function getOptionLabel(value: AssessProfile) {
  return value.profileName;
}

function getOptionValue(value: AssessProfile) {
  return value.assessmentProfileId;
}

export default React.memo(QuickChangeSelfAssessProfile);
