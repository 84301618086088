import React, { useContext } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { ProductByHSRev } from 'AppTypes';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { useSearchProductsQuery } from 'services/products';
import styles from './HSItem.module.scss';
import { getFormattedProductCode } from 'utils/helpers';
import Loading from 'components/Loading';
import { ProductSelectContext } from 'components/Select/ProductSelectContext';
import { useMetadataInfoQuery } from 'services/metadata';

export type HSItemProps = {
  item: ProductByHSRev;
  index: number;
  countryCode?: string;
  level?: number;
  onClick?: (item: ProductByHSRev, level: number, isLeaf?: boolean) => void;
};

const HSItemLeaf = ({
  item,
  index,
  countryCode,
  level = 0,
  onClick,
}: HSItemProps) => {
  const { isOptionDisabled } = useContext(ProductSelectContext);

  const { currentData: metadata } = useMetadataInfoQuery(countryCode ?? '', {
    skip: !countryCode,
  });

  const response = useSearchProductsQuery(
    item.code && metadata?.ntmYear && countryCode
      ? {
          countryCode,
          keyword: item.code,
          year: metadata.ntmYear,
          level: 8,
        }
      : skipToken
  );
  const { currentData: products } = response;

  return (
    <>
      {!countryCode && (
        <div className={clsx(styles.deepLevel, 'text-danger')}>
          <FormattedMessage
            id="message.error.selectCountry"
            defaultMessage="Please select a country first"
          />
        </div>
      )}
      <Loading response={response} loadingWrapperClassName={styles.deepLevel}>
        {products && products.length === 0 && (
          <div className={styles.deepLevel}>
            <FormattedMessage
              id="message.noDataFound"
              defaultMessage="No data found"
            />
          </div>
        )}
        {!!products?.length &&
          products.map((item) => (
            <h2
              key={item.code}
              className={clsx(
                'accordion-header',
                styles[`level${level}`],
                styles.deepLevel,
                styles.leafLevel
              )}
            >
              <button
                type="button"
                disabled={isOptionDisabled?.(item)}
                onClick={() => onClick?.(item, level, true)}
                className="accordion-button"
              >
                <div className={styles.itemHeader}>
                  <div className={styles.codeContainer}>
                    <div className={styles.code}>
                      {getFormattedProductCode(item.code, level)}
                    </div>
                  </div>
                  <div className={styles.name}>{item.name}</div>
                </div>
              </button>
            </h2>
          ))}
      </Loading>
    </>
  );
};

export default React.memo(HSItemLeaf);
