import { Cell, Row } from '@tanstack/react-table';
import { Input } from 'components/Input';
import { RadioGroup } from 'components/RadioGroup';
import { useGetSelfAssessParams } from 'hooks/useGetSelfAssessParams';
import { RowType } from 'hooks/useMeasureTable';
import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useUpdateComplianceMutation } from 'services/selfAssess';
import { isFetchBaseQueryErrorType } from 'store/error-handlers';
import { useDebouncedCallback } from 'use-debounce';
import * as yup from 'yup';
import styles from './AssessMeasureDetailsTableRadioByRow.module.scss';

type Props = {
  row: Row<RowType>;
  cell: Cell<RowType, unknown>;
  inputClassName?: string;
};

const MAX_VALUE = 1_000_000;
const MIN_VALUE = 0;

const Schema = yup.object({
  value: yup
    .number()
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? null : value
    )
    .min(MIN_VALUE)
    .max(MAX_VALUE)
    .nullable()
    .label('Product value'),
});

const AssessMeasureDetailsTableRadioByRow = ({
  row,
  cell,
  inputClassName,
}: Props) => {
  const { profileId } = useGetSelfAssessParams();
  const intl = useIntl();
  const [
    updateCompliance,
    {
      isLoading,
      error: updateComplianceError,
      isSuccess: updateComplianceSuccess,
      reset,
    },
  ] = useUpdateComplianceMutation();
  const [inputValue, setInputValue] = useState<string | null>(
    row.original.extraData
  );
  const [isComplied, setIsComplied] = useState<string | null>(
    String(row.original.assessmentId ? row.original.isComplied : '')
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [inputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    setIsComplied(
      String(row.original.assessmentId ? row.original.isComplied : '')
    );
  }, [row.original.assessmentId, row.original.isComplied]);

  useEffect(() => {
    if (
      updateComplianceError &&
      isFetchBaseQueryErrorType(updateComplianceError) &&
      (updateComplianceError.data as Record<string, any>)?.details?.[
        'Invalid param'
      ]
    ) {
      setError(
        (updateComplianceError.data as Record<string, any>)?.details?.[
          'Invalid param'
        ]
      );
    }
  }, [updateComplianceError]);

  const validateInput = useCallback((value?: string | null) => {
    try {
      Schema.validateSync({ value });
      setError((err) => err ?? undefined);
    } catch (e: unknown) {
      if (e instanceof yup.ValidationError) {
        setError(e.errors[0]);
      }
    }
  }, []);

  const onInputValueChange = useDebouncedCallback((value: string) => {
    validateInput(value);
  }, 1000);

  const submitChange = useCallback(
    (newValue?: string) => {
      const submitInputValue = newValue ?? inputValue;

      if (submitInputValue == null) return;

      if (
        typeof submitInputValue === 'string' &&
        submitInputValue?.trim() === ''
      ) {
        updateCompliance({
          IsComplied: isComplied === 'true',
          AssessmentId: row.original.assessmentId,
          MeasureId: row.original.measureId,
          NtmCode: row.original.ntmCode,
          IndicatorId: row.original.indicatorId,
          AssessmentRowId: row.original.rowId,
          AssessmentProfileId: profileId || '',
          ExtraData: submitInputValue,
        });
        return;
      }

      const isNewValueComplied =
        submitInputValue <= row.original.maxValue &&
        submitInputValue >= row.original.minValue;
      setIsComplied(String(isNewValueComplied));
      updateCompliance({
        IsComplied: isNewValueComplied,
        AssessmentId: row.original.assessmentId,
        MeasureId: row.original.measureId,
        NtmCode: row.original.ntmCode,
        IndicatorId: row.original.indicatorId,
        AssessmentRowId: row.original.rowId,
        AssessmentProfileId: profileId || '',
        ExtraData: String(submitInputValue ?? ''),
      });
    },
    [
      inputValue,
      isComplied,
      profileId,
      row.original.assessmentId,
      row.original.indicatorId,
      row.original.maxValue,
      row.original.measureId,
      row.original.minValue,
      row.original.ntmCode,
      row.original.rowId,
      updateCompliance,
    ]
  );

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (
      e.key === 'Enter' &&
      (!error ||
        inputValue?.trim() === '' ||
        (inputValue != null &&
          Number(inputValue) <= MAX_VALUE &&
          Number(inputValue) >= MIN_VALUE))
    ) {
      submitChange();
      e.preventDefault();
    }
  };

  const onClear = useCallback(() => {
    submitChange('');
  }, [submitChange]);

  useEffect(() => {
    if (
      !inputFocused &&
      !error &&
      inputValue?.length &&
      !updateComplianceSuccess
    ) {
      submitChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, inputFocused]);

  useEffect(() => {
    if (updateComplianceSuccess) {
      setError(undefined);
      reset();
    }
  }, [reset, updateComplianceSuccess]);

  return (
    <>
      <div className={styles.radioCell}>
        <Input
          type="number"
          onValueChange={(value) => {
            setInputValue(value);
            onInputValueChange(value);
          }}
          value={inputValue ?? ''}
          disabled={isLoading}
          canClear
          focusableClearBtn={false}
          clearBtnExternal
          onClear={onClear}
          fullBorder
          placeholder={intl.formatMessage({
            id: 'input.placeholder.enterValue',
            defaultMessage: 'Enter value',
          })}
          wrapperClassName={inputClassName}
          className="no-spinner"
          min={MIN_VALUE}
          max={MAX_VALUE}
          step="any"
          error={error}
          onKeyDown={onKeyDown}
          onFocus={() => void setInputFocused(true)}
          onBlur={() => void setInputFocused(false)}
        />
        <RadioGroup
          wrapperClassName="radioGroup"
          disabled={
            typeof inputValue === 'number' ||
            (typeof inputValue !== 'number' && !!inputValue) ||
            isLoading
          }
          name={`${cell.row.id}`}
          items={[
            {
              label: (
                <FormattedMessage
                  id="selfAssess.compliance.complied"
                  defaultMessage="Yes"
                />
              ),
              value: 'true',
            },
            {
              label: (
                <FormattedMessage
                  id="selfAssess.compliance.notComplied"
                  defaultMessage="No"
                />
              ),
              value: 'false',
            },
          ]}
          value={isComplied}
          onChange={(newValue) => {
            setIsComplied(newValue);
            updateCompliance({
              IsComplied: newValue === 'true',
              AssessmentId: row.original.assessmentId,
              MeasureId: row.original.measureId,
              NtmCode: row.original.ntmCode,
              IndicatorId: row.original.indicatorId,
              AssessmentRowId: row.original.rowId,
              AssessmentProfileId: profileId || '',
              ExtraData: null,
            });
          }}
        />
        {isLoading && (
          <Spinner animation="border" size="sm" className={styles.loading} />
        )}
      </div>
      {!!error && (
        <div className="text-error">
          <div>{error}</div>
        </div>
      )}
    </>
  );
};

export default React.memo(AssessMeasureDetailsTableRadioByRow);
