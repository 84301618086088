import { IndicatorDetail } from 'AppTypes';
import { uniqBy } from 'lodash';
import { EU_COUNTRY_CODES } from 'utils/constants';

export function getStandardMeasureCode(code: string) {
  if (code.length > 0 && code.length < 4) {
    return code.padEnd(4, '0');
  }

  return code;
}

export function isTextExceedWordLimit(str?: string, maxWords: number = 200) {
  if (!str) return false;

  return str.replaceAll('\n', ' ').split(' ').filter(Boolean).length > maxWords;
}

export function isSelectedMeasureMRL(indicators: IndicatorDetail[]) {
  return uniqBy(indicators, (indicator) => indicator.rowId).length > 1;
}

/**
 * Format product code up to HS6 level
 *
 * @param code Original code. E.g: 0011223344
 * @param level level of item in the Product Code Finder
 * @returns formatted code. E.g: 0011223344 -> 00 11 22 3344 (only the first 3 pairs of digits are separated)
 */
export function getFormattedProductCode(code: string, level: number) {
  if (level < 2) {
    return code;
  }

  return code
    ?.split('')
    .map((char, idx, arr) => (idx % 2 === 1 && idx < 6 ? `${char} ` : char))
    .join('');
}

export function formattedNumberWithPrefix(data: number) {
  return data < 10 && data > 0 ? `0${data}` : data;
}

export function isSearchingBetweenEUCountries(
  fromCountryCode: string,
  toCountryCode: string
) {
  return (
    EU_COUNTRY_CODES.includes(fromCountryCode) &&
    EU_COUNTRY_CODES.includes(toCountryCode)
  );
}
