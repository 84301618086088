import React, { useContext } from 'react';
import { IndicatorDetail } from 'AppTypes';
import { SimpleTable } from 'components/Table';
import { MeasureDetailsContext } from './MeasureDetailsContext';
import { useMeasureTable } from 'hooks/useMeasureTable';

type Props = {
  indicators: IndicatorDetail[];
  stickyHeader?: boolean;
  isSelectAll: boolean;
  tableActionContainer?: HTMLDivElement | null;
};

const MeasureDetailsTable = ({
  indicators,
  stickyHeader,
  isSelectAll,
  tableActionContainer,
}: Props) => {
  const tableActionRef = useContext(MeasureDetailsContext);
  const { tableColumns, tableData } = useMeasureTable({ indicators });
  const sorteableColumns = tableColumns.sort((a: any, b: any) => {
    return (
      a?.meta?.indicator?.indicatorOrder - b?.meta?.indicator?.indicatorOrder
    );
  });
  console.log('tableColumns', tableColumns);
  console.log('sorteableColumns', sorteableColumns);

  return (
    <SimpleTable
      data={tableData}
      columns={sorteableColumns}
      stickyHeader={stickyHeader}
      tableStyle={{
        width: tableColumns.length > 5 ? 250 * tableColumns.length : 'auto',
      }}
      hasRowTooltip={isSelectAll}
      containerForActions={tableActionContainer || tableActionRef}
    />
  );
};

export default React.memo(MeasureDetailsTable);
