import { Country } from 'AppTypes';
import React, { useEffect, useRef, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { ChevronDown, Search } from 'react-bootstrap-icons';
import Button from 'components/Button';
import {
  CustomOption,
  formatOptionLabel,
  getOptionLabel,
  getOptionValue,
} from 'components/Select/CountrySelect';
import { Overlay, Popover } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { getFlagCode } from 'utils/flags';
import styles from './CompareCountriesDetailSelect.module.scss';
import clsx from 'clsx';

const selectStyles: StylesConfig<Country, false> = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 8,
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

const DropdownIndicator = () => <Search />;

type Props = {
  isFlipped?: boolean;
  value: Country | null;
  setValue: (value: Country | null) => void;
  options: Country[];
};

const CompareCountriesDetailSelect = ({
  isFlipped,
  value,
  setValue,
  options,
}: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const [isEnter, setEnter] = useState<boolean>(false);
  const btnRef = useRef<HTMLButtonElement>(null);
  const selectRef = useRef<any>(null);

  // useEffect(() => {
  //   if (toCountries && toCountries?.length > 0) {
  //     setValue(isFlipped ? toCountries[1] : toCountries[0]);
  //   }
  // }, [isFlipped, setValue, toCountries]);

  useEffect(() => {
    if (isEnter) {
      selectRef.current?.focus({ preventScroll: true });
    }

    if (!isEnter && selectRef.current) {
      selectRef.current?.focus({ preventScroll: true });
    }
  }, [isEnter]);

  return (
    <>
      <Button
        onClick={() => setShow((prev) => !prev)}
        ref={btnRef}
        variant="outline-primary"
        className={clsx(styles.filterBtn, isFlipped && styles.flipped)}
      >
        {value ? (
          <span className={styles.selectedContent}>
            <span
              className={`fi fi-${getFlagCode(value.iso2)}`}
              aria-hidden={true}
            />
            {value.name}
            <ChevronDown />
          </span>
        ) : (
          <FormattedMessage
            id="button.action.selectAnOption"
            defaultMessage="Select an option"
          />
        )}
      </Button>
      <Overlay
        target={btnRef.current}
        show={show}
        rootClose
        onHide={() => setShow(false)}
        onEntered={() => setEnter(true)}
        onExited={() => {
          setEnter(false);
        }}
        container={document.body}
        placement="bottom"
      >
        <Popover>
          {isEnter ? (
            <Select
              ref={selectRef}
              autoFocus={false}
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Option: CustomOption,
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={(newValue) => {
                setValue(newValue);
                setShow(false);
              }}
              options={options}
              styles={selectStyles}
              tabSelectsValue={false}
              value={value}
              getOptionValue={getOptionValue}
              getOptionLabel={getOptionLabel}
              formatOptionLabel={formatOptionLabel}
              className="react-select"
              classNamePrefix="react-select"
            />
          ) : (
            <div style={{ width: '257px', display: 'block' }} />
          )}
        </Popover>
      </Overlay>
    </>
  );
};

export default React.memo(CompareCountriesDetailSelect);
