import Loading from 'components/Loading';
import React, { useEffect, useMemo } from 'react';
import { useGetProfileSummaryQuery } from 'services/selfAssess';
import SummaryAccordion from './SummaryAccordion';
import styles from './SelfAssessProfileComplianceSummary.module.scss';
import Button from 'components/Button';
import { useIntl } from 'react-intl';
import { List } from 'react-bootstrap-icons';
import MeasureFilterView from 'modules/app/MeasureFilterView';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useGetSelfAssessParams } from 'hooks/useGetSelfAssessParams';
import { getRoute } from 'utils/router';
import { useSummaryNavigate } from 'modules/self-assess/profile/useSummaryNavigate';

type Props = {
  isCollapsed: boolean;
  setIsCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};

const SelfAssessProfileComplianceSummary = ({
  isCollapsed,
  setIsCollapsed,
}: Props) => {
  const intl = useIntl();
  const response = useGetProfileSummaryQuery();
  const { data: summary } = response;

  const navigate = useNavigate();
  const { profileId, measureId, ntmCode } = useGetSelfAssessParams();
  const { firstItem } = useSummaryNavigate();

  const toggleBtn = useMemo(() => {
    return (
      <Button
        type="button"
        variant="secondary"
        onClick={() => setIsCollapsed((value) => !value)}
        title={intl.formatMessage({
          id: 'button.action.toggleSidebar',
          defaultMessage: 'Toggle sidebar',
        })}
        className={clsx(styles.toggleBtn, isCollapsed && styles.collapsed)}
      >
        <List />
      </Button>
    );
  }, [intl, isCollapsed, setIsCollapsed]);

  // Auto focus the first item of summary in the compliance view
  useEffect(() => {
    if (profileId && !measureId && !ntmCode && firstItem) {
      if (firstItem.measureCode && firstItem.ntmCode) {
        navigate(
          getRoute('fullSelfAssessmentProfileByQuery', {
            profileId,
            measureId: firstItem.measureCode,
            ntmCode: firstItem.ntmCode,
          }),
          { replace: true }
        );
      }
    }
  }, [firstItem, measureId, navigate, ntmCode, profileId]);

  return (
    <Loading response={response}>
      {summary ? (
        isCollapsed ? (
          <div className={styles.wrapperCollapsed}>{toggleBtn}</div>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.header}>
              <div className={styles.content}>
                {toggleBtn}
                <MeasureFilterView
                  useDropdown
                  inverted
                  className={styles.filterView}
                />
              </div>
            </div>
            <div className={styles.body}>
              <SummaryAccordion accordionData={summary} isComplianceView />
            </div>
          </div>
        )
      ) : null}
    </Loading>
  );
};

export default SelfAssessProfileComplianceSummary;
