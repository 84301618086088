import React, { useCallback, useContext } from 'react';
import Orb from 'components/Orb';
import { FormattedMessage } from 'react-intl';
import styles from './ResultSummary.module.scss';
import Button from 'components/Button';
import clsx from 'clsx';
import {
  MeasureFilterAndMeasureTypeMapping,
  MeasureType,
  MeasureTypeDetails,
} from 'modules/constants';
import { SearchSummary } from 'AppTypes';
import { MeasureFilterViewContext } from './MeasureFilterViewContext';
import MeasureFilterView from './MeasureFilterView';

type Props = {
  openingTab: MeasureType | null;
  setOpeningTab: React.Dispatch<React.SetStateAction<MeasureType | null>>;
  summary: SearchSummary;
};

const ResultSummary = ({ openingTab, setOpeningTab, summary }: Props) => {
  const { filterView } = useContext(MeasureFilterViewContext);

  const changeOpeningTab = useCallback(
    (value: MeasureType) => {
      setOpeningTab((currentValue) => (value !== currentValue ? value : null));
    },
    [setOpeningTab]
  );

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <FormattedMessage
          id="search.info.importRequirement"
          defaultMessage="Import requirements applied to this product"
        />
      </div>
      <div>
        <MeasureFilterView useDropdown className={styles.dropdown} />
      </div>
      <div className={styles.totalCount}>
        <div className={styles.title}>
          <Orb color="purple" className={styles.decoration} />
          <span className={styles.content}>
            <FormattedMessage
              id="search.count.totalMeasures"
              defaultMessage="Total measures"
            />
          </span>
        </div>
        <div className={styles.number}>{summary.total}</div>
      </div>
      <div className={styles.separator} />
      <div className={styles.countByTypes}>
        {MeasureFilterAndMeasureTypeMapping[filterView]?.map((measureType) => (
          <div
            key={MeasureTypeDetails[measureType].key}
            className={styles.countOfType}
          >
            <div className={styles.info}>
              <div className={styles.image}>
                {MeasureTypeDetails[measureType].iconNoPadding}
              </div>
              <span className={styles.title}>
                {MeasureTypeDetails[measureType].title}
              </span>
              <div className={styles.count}>
                <span className={styles.number}>
                  {summary.countBy[filterView]?.[measureType]}
                </span>
                <span className={styles.unit}>
                  <FormattedMessage
                    id="measures.unit"
                    defaultMessage="measures"
                  />
                </span>
              </div>
            </div>
            <div className={styles.action}>
              {!!summary.countBy[filterView]?.[measureType] && (
                <Button
                  type="button"
                  variant="outline-toggle"
                  className={clsx(openingTab === measureType && 'active')}
                  onClick={() => changeOpeningTab(measureType)}
                >
                  {openingTab === measureType ? (
                    <FormattedMessage
                      id="button.action.seeLess"
                      defaultMessage="See less"
                    />
                  ) : (
                    <FormattedMessage
                      id="button.action.seeMore"
                      defaultMessage="See more"
                    />
                  )}
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultSummary;
