import React, { useCallback } from 'react';
import styles from './AccordionSummary.module.scss';
import { ReactComponent as CollapseIcon } from 'assets/icons/collapsed-pill.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/expanded-pill.svg';
import clsx from 'clsx';

type Props = {
  isExpanded?: boolean;
  onToggle: (key: string) => void;
  children: React.ReactNode;
  keyData: string;
  className?: string;
  noBorder?: boolean;
};

const AccordionSummary = ({
  isExpanded,
  onToggle,
  children,
  keyData,
  className,
  noBorder,
}: Props) => {
  // const [isOpen, setIsOpen] = useState<boolean>(!!isExpanded);
  const onClickBtn = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // setIsOpen((value) => !value);
      onToggle(keyData);
    },
    [keyData, onToggle]
  );

  return (
    <button
      className={clsx(
        className,
        styles.summaryWrapper,
        isExpanded && styles.expanded
      )}
      onClick={onClickBtn}
      aria-expanded={isExpanded}
    >
      <div className={clsx(styles.summary, noBorder && styles.noBorder)}>
        <div className={styles.content}>{children}</div>
        <div className={styles.indicator}>
          {isExpanded ? <ExpandIcon /> : <CollapseIcon />}
        </div>
      </div>
    </button>
  );
};

export default AccordionSummary;
