import React, { useMemo } from 'react';
import { AssessProfileMeasureIndicator, IndicatorDetail } from 'AppTypes';
import AssessMeasureDetailsNavigation from './AssessMeasureDetailsNavigation';
import styles from './AssessMeasureDetailsList.module.scss';
import tableStyles from 'components/Table/SimpleTable.module.scss';
import { useMeasureTable } from 'hooks/useMeasureTable';
import {
  ColumnFiltersState,
  ColumnPinningState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { singleValueInArray } from 'components/Table/customFilterFns';
import ExcelFilter from 'components/Table/ExcelFilter';
import { FormattedMessage } from 'react-intl';
import { useGetSelfAssessParams } from 'hooks/useGetSelfAssessParams';
import AssessMeasureDetailsTableRadioByColumn from 'modules/self-assess/profile/AssessMeasureDetailsTableRadioByColumn';

const PRODUCT_SPEC_IND_ID = 'Ind_1';

function getComplianceColumnId(indicator?: IndicatorDetail) {
  return indicator ? `${indicator.measureId}_${indicator.indicatorId}` : '';
}

const AssessMeasureDetailsTable = ({
  indicators,
}: {
  indicators: AssessProfileMeasureIndicator[];
}) => {
  const { profileId } = useGetSelfAssessParams();
  const hasExtraField = useMemo(
    () =>
      indicators.some(
        (indicator) => indicator.complianceApplicable && indicator.isExtraField
      ),
    [indicators]
  );

  const complianceByColumns = useMemo(() => {
    return indicators
      .filter((indicator) => indicator.complianceApplicable)
      .reduce(
        (acc, curr) => ({
          ...acc,
          [getComplianceColumnId(curr)]:
            curr.assessmentId != null ? curr.isComplied : null,
        }),
        {} as Record<string, boolean | null>
      );
  }, [indicators]);

  const { tableColumns, tableData } = useMeasureTable({
    indicators,
    hasStickyComplianceColumn: hasExtraField,
  });

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnPinning, setColumnPinning] = React.useState<ColumnPinningState>({
    right: ['sticky'],
  });

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    enableFilters: true,
    enableColumnFilters: true,
    enablePinning: true,
    state: {
      columnFilters,
      columnPinning,
    },
    filterFns: {
      singleValueInArray: singleValueInArray,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnPinningChange: setColumnPinning,
  });

  const tableStyle = useMemo(() => {
    return hasExtraField
      ? {
          width: tableColumns.length > 5 ? 400 * tableColumns.length : 'auto',
        }
      : {
          width: tableColumns.length > 2 ? 400 * tableColumns.length : 'auto',
        };
  }, [hasExtraField, tableColumns.length]);

  return (
    <div className={styles.details}>
      <div
        className={clsx(styles.table, !hasExtraField && styles.selfContained)}
      >
        <div className={styles.container}>
          <table
            className={clsx(tableStyles.table, tableStyles.sticky)}
            style={tableStyle}
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup, index) => (
                <React.Fragment key={headerGroup.id}>
                  <tr>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className={clsx(
                          header.column.getIsPinned() &&
                            tableStyles.stickyColumn,
                          (
                            (header.column.columnDef.meta as any)
                              ?.indicator as IndicatorDetail
                          )?.indicatorId === PRODUCT_SPEC_IND_ID &&
                            styles.productSpecIndColumn
                        )}
                      >
                        <div className={tableStyles.headerCell}>
                          {header.isPlaceholder ? null : (
                            <>
                              <span>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </span>
                            </>
                          )}
                          {header.column.getCanFilter() && (
                            <ExcelFilter column={header.column} table={table} />
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                  {!hasExtraField && (
                    <tr className={tableStyles.complianceRow}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          className={clsx(
                            header.column.getIsPinned() &&
                              tableStyles.stickyColumn
                          )}
                        >
                          {!header.column.getIsPinned() &&
                            getComplianceColumnId(
                              (header.column.columnDef.meta as any)
                                ?.indicator as IndicatorDetail
                            ) in complianceByColumns && (
                              <div
                                className={clsx(
                                  tableStyles.headerCell,
                                  tableStyles.complianceCheckCell
                                )}
                              >
                                <AssessMeasureDetailsTableRadioByColumn
                                  header={header}
                                  profileId={profileId || ''}
                                />
                              </div>
                            )}
                          {!header.column.getIsPinned() &&
                            (
                              (header.column.columnDef.meta as any)
                                ?.indicator as IndicatorDetail
                            )?.indicatorId === PRODUCT_SPEC_IND_ID && (
                              <div
                                className={clsx(
                                  tableStyles.headerCell,
                                  tableStyles.complianceCheckCell
                                )}
                              >
                                <FormattedMessage
                                  id="selfAssess.message.compliantWithAllRequirementsInColumnPrompt"
                                  defaultMessage="Are you compliant with ALL the requirements in the given column?"
                                />
                              </div>
                            )}
                        </th>
                      ))}
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={clsx(
                        cell.column.getIsPinned() && tableStyles.stickyColumn
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
              {table.getRowModel().rows.length === 0 && (
                <tr>
                  <td colSpan={table.getAllColumns().length}>
                    <FormattedMessage
                      id="message.noDataFound"
                      defaultMessage="No data found"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.action}>
        <AssessMeasureDetailsNavigation indicators={indicators} />
      </div>
    </div>
  );
};

export default React.memo(AssessMeasureDetailsTable);
