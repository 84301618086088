import { CompareResultDataItem } from 'AppTypes';
import React, { useEffect, useMemo, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import styles from './CompareCommonNTMAccordion.module.scss';
import { ReactComponent as CollapseIcon } from 'assets/icons/collapsed-pill.svg';
import { ReactComponent as ExpandIcon } from 'assets/icons/expanded-pill-filled.svg';
import MeasureCount from 'modules/compare/components/MeasureCount';
import Card from 'components/Card';
import MeasureGroupDetailsCompare from 'modules/compare/components/MeasureGroupDetailsCompare';
import { useStickyHeaderSentinel } from 'modules/compare/useStickyHeaderSentinel';
import clsx from 'clsx';

type Props = {
  data: CompareResultDataItem[];
};

const StickyHeader = ({
  ntm,
  openState,
  setOpenState,
}: {
  openState: Record<string, boolean>;
  setOpenState: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  ntm: CompareResultDataItem;
}) => {
  const { isSticky, sentinel } = useStickyHeaderSentinel({
    observerArgs: {
      rootMargin: '-150px 0px 0px 0px',
    },
  });

  return (
    <>
      {sentinel}
      <Accordion.Header
        onClick={() =>
          setOpenState((state) => ({
            ...state,
            [ntm.ntmCode]: !state[ntm.ntmCode],
          }))
        }
        className={clsx(isSticky && openState[ntm.ntmCode] && 'is-sticky')}
      >
        <div className="content">
          <MeasureCount measureCount={ntm.measureCount1 ?? 0} />
          <div className="measureName">
            <span>{ntm.shortTitle}</span>
            <div className={styles.indicator}>
              {openState[ntm.ntmCode] ? <ExpandIcon /> : <CollapseIcon />}
            </div>
          </div>
          <MeasureCount measureCount={ntm.measureCount2 ?? 0} />
        </div>
      </Accordion.Header>
    </>
  );
};

const CompareCommonNTMAccordion = ({ data }: Props) => {
  const [openState, setOpenState] = useState<Record<string, boolean>>({});

  useEffect(() => {
    setOpenState(
      data.reduce<Record<string, boolean>>(
        (acc, curr) => ({
          ...acc,
          [curr.ntmCode]: false,
        }),
        {}
      )
    );
  }, [data]);

  const activeKeys = useMemo(
    () =>
      Object.entries(openState)
        .filter(([key, isExpanded]) => isExpanded)
        .map(([key]) => key),
    [openState]
  );

  return (
    <Accordion
      alwaysOpen
      bsPrefix="compare-common-accordion"
      className={styles.compareCommonAccordion}
      activeKey={activeKeys}
    >
      {data.map((ntm) => (
        <Accordion.Item eventKey={ntm.ntmCode} key={ntm.ntmCode}>
          <StickyHeader
            ntm={ntm}
            openState={openState}
            setOpenState={setOpenState}
          />
          <Accordion.Collapse eventKey={ntm.ntmCode} mountOnEnter={true}>
            <Card className={styles.compareLayout}>
              <MeasureGroupDetailsCompare measureGroup={ntm} />
            </Card>
          </Accordion.Collapse>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default CompareCommonNTMAccordion;
