import { concat, of } from 'rxjs';
import { exhaustMap, filter, mergeMap } from 'rxjs/operators';
import { fetchBearerToken, setBearerToken } from '../cmsSlice';
import { fromFetch } from 'rxjs/fetch';
import { AppEpic } from 'store';

type CMSAuthResponse = {
  access_token: string;
};

const fetchBearerTokenEpic: AppEpic = (action$, state$, { config }) => {
  return action$.pipe(
    filter(fetchBearerToken.match),
    exhaustMap(() =>
      fromFetch<CMSAuthResponse>(config.cmsIdentityServer, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'client_credentials',
          client_id: config.cmsClientId,
          client_secret: config.cmsClientSecret,
          scope: 'squidex-api',
        }),
        selector: (response) => response.json(),
      }).pipe(mergeMap((data) => concat(of(setBearerToken(data.access_token)))))
    )
  );
};

export default fetchBearerTokenEpic;
