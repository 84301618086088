import React, { useCallback, useMemo, useRef, useState } from 'react';
import { IndicatorDetail, MeasureGroupOverview } from 'AppTypes';
import styles from './MeasureGroupDetails.module.scss';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import SourceLegislation from './SourceLegislation';
import MeasureDetails from './MeasureDetails';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import NTMSelect from 'components/Select';
import { useIndicatorToggle, useMeasureGroup } from 'hooks/useMeasureGroup';
import { SingleValue } from 'react-select';
import { SelectOption } from 'AppTypes';
import { isSelectedMeasureMRL } from 'utils/helpers';
import { MeasureDetailsContext } from './MeasureDetailsContext';
import clsx from 'clsx';

type MeasureGroupDetailsProps = {
  measureGroup: MeasureGroupOverview;
  indicators?: IndicatorDetail[];
  compareLayout?: boolean;
};

export const MeasureGroupDetails = ({
  measureGroup,
  indicators,
  compareLayout,
}: MeasureGroupDetailsProps) => {
  const { measuresToSelect, indicatorsGroupedByMeasures } =
    useMeasureGroup(indicators);

  const [selectedMeasure, setSelectedMeasure] = useState<
    SingleValue<SelectOption>
  >(measuresToSelect[0]);

  const {
    isSelectedAllMeasures,
    selectedIndicators,
    toggleBodyState,
    showExpandAll,
    showCollapseAll,
    onClickExpandAll,
    onClickCollapseAll,
    updateToggleBodyState,
    isDisplaySource,
    onToggleSource,
    isShowToggleBodyActions,
  } = useIndicatorToggle(
    indicators,
    selectedMeasure,
    indicatorsGroupedByMeasures
  );

  const doesSelectedMeasureOptionHasMRL = useMemo(() => {
    if (isSelectedAllMeasures) return false;
    return isSelectedMeasureMRL(selectedIndicators ?? []);
  }, [selectedIndicators, isSelectedAllMeasures]);

  const onChangeSelectedMeasure = useCallback(
    (newValue: SingleValue<SelectOption>) => {
      setSelectedMeasure(newValue);
    },
    []
  );

  const tableActionRef = useRef<HTMLDivElement>(null);

  return (
    <MeasureDetailsContext.Provider value={tableActionRef?.current}>
      <div>
        {compareLayout && (
          <div className={styles.compareLayoutActionContainer}>
            <div id="measure-group-details" ref={tableActionRef}></div>
            {!isDisplaySource &&
              isShowToggleBodyActions &&
              !doesSelectedMeasureOptionHasMRL && (
                <>
                  {showExpandAll && !isSelectedAllMeasures && (
                    <Button
                      type="button"
                      variant="light"
                      size="sm"
                      className={styles.expandBtn}
                      onClick={onClickExpandAll}
                    >
                      <FormattedMessage
                        id="button.action.expandAll"
                        defaultMessage="Expand All"
                      />
                      <PlusIcon />
                    </Button>
                  )}
                  {showCollapseAll && !isSelectedAllMeasures && (
                    <Button
                      type="button"
                      variant="light"
                      size="sm"
                      className={styles.collapseBtn}
                      onClick={onClickCollapseAll}
                    >
                      <FormattedMessage
                        id="button.action.collapseAll"
                        defaultMessage="Collapse All"
                      />
                      <MinusIcon />
                    </Button>
                  )}
                </>
              )}
          </div>
        )}
        <div className={clsx(styles.header, 'detailsHeader')}>
          <div
            className={clsx(
              styles.wrapper,
              compareLayout && styles.compareLayout
            )}
          >
            <div className={styles.title}>
              <NTMSelect
                options={measuresToSelect}
                defaultValue={measuresToSelect?.[0]}
                value={selectedMeasure}
                onChange={onChangeSelectedMeasure}
                showNavigationButtons
              />
            </div>
            {!isSelectedAllMeasures && (
              <div className={styles.sourceLegislation}>
                <Button
                  type="button"
                  size="sm"
                  onClick={onToggleSource}
                  className={styles.toggleBtn}
                >
                  {isDisplaySource ? (
                    <FormattedMessage
                      id="button.action.viewIndicators"
                      defaultMessage="Indicators"
                    />
                  ) : (
                    <FormattedMessage
                      id="button.action.viewSourceLegislation"
                      defaultMessage="Source Legislation"
                    />
                  )}
                </Button>
              </div>
            )}
          </div>
          {!compareLayout && (
            <div>
              <div id="measure-group-details" ref={tableActionRef}></div>
              {!isDisplaySource &&
                isShowToggleBodyActions &&
                !doesSelectedMeasureOptionHasMRL && (
                  <>
                    {showExpandAll && !isSelectedAllMeasures && (
                      <Button
                        type="button"
                        variant="light"
                        size="sm"
                        className={styles.expandBtn}
                        onClick={onClickExpandAll}
                      >
                        <FormattedMessage
                          id="button.action.expandAll"
                          defaultMessage="Expand All"
                        />
                        <PlusIcon />
                      </Button>
                    )}
                    {showCollapseAll && !isSelectedAllMeasures && (
                      <Button
                        type="button"
                        variant="light"
                        size="sm"
                        className={styles.collapseBtn}
                        onClick={onClickCollapseAll}
                      >
                        <FormattedMessage
                          id="button.action.collapseAll"
                          defaultMessage="Collapse All"
                        />
                        <MinusIcon />
                      </Button>
                    )}
                  </>
                )}
            </div>
          )}
        </div>
        <div className={clsx(styles.body, 'detailsBody')}>
          {isDisplaySource ? (
            !!selectedIndicators?.[0] && (
              <SourceLegislation measure={selectedIndicators[0]} />
            )
          ) : (
            <MeasureDetails
              indicators={selectedIndicators}
              toggleState={toggleBodyState}
              updateToggleState={updateToggleBodyState}
              isSelectAll={isSelectedAllMeasures}
            />
          )}
        </div>
      </div>
    </MeasureDetailsContext.Provider>
  );
};

export default React.memo(MeasureGroupDetails);
