import clsx from 'clsx';
import { DownloadButton } from 'components/Button';
import Card from 'components/Card';
import React, { useCallback, useState } from 'react';
import styles from './Compare.module.scss';
import CompareForm from './widgets/CompareForm';
import { useGetShellContent } from 'services/cms';
import { RemoteImage } from 'assets/remote';
import CompareProducts from './products/CompareProducts';
import { useGetCompareParams } from 'hooks/useGetCompareParams';
import { CompareType } from 'modules/constants';
import CompareCountries from './countries/CompareCountries';
import { FormattedMessage } from 'react-intl';
import { saveAs } from 'file-saver';
import { fetchCompareDataFile } from 'services/compare';
import { toast } from 'react-toastify';

const Compare = () => {
  const { data } = useGetShellContent();
  const {
    type: compareType,
    partner,
    reporterId,
    productId,
    productId1,
    productId2,
  } = useGetCompareParams();

  const [loading, setLoading] = useState(false);

  const onClickDownloadReport = useCallback(async () => {
    if (compareType) {
      setLoading(true);
      try {
        const blobData = await fetchCompareDataFile(
          compareType === CompareType.PRODUCTS
            ? {
                ntmCompareInputModel: null,
                productCompareInputModel: {
                  productCodeOne: productId1 || '',
                  productCodeTwo: productId2 || '',
                  partner: partner || '',
                  reporter: reporterId || '',
                },
              }
            : {
                ntmCompareInputModel: {
                  productCode: productId || '',
                  fromCountry: partner || '',
                  toCountries: reporterId || '',
                },
                productCompareInputModel: null,
              }
        );
        saveAs(blobData, `compare-${new Date().toLocaleString()}.xlsx`);
      } catch (e) {
        toast.error(
          <FormattedMessage
            id="message.errorOccurred"
            defaultMessage="An unexpected error occurred, please try again"
          />
        );
      } finally {
        setLoading(false);
      }
    }
  }, [compareType, partner, productId, productId1, productId2, reporterId]);

  return (
    <div>
      <section className={styles.widgetArea}>
        {data?.banners?.compare?.url && (
          <RemoteImage
            url={data?.banners?.compare?.url}
            className={styles.background}
          />
        )}
        <div className={clsx('sub-container')}>
          <Card className={styles.card}>
            <div>
              <CompareForm horizontalLayout />
            </div>
            <div className={styles.sourceInfo}>
              <div>
                <DownloadButton
                  onClick={onClickDownloadReport}
                  disabled={loading || !compareType}
                />
              </div>
            </div>
          </Card>
        </div>
      </section>
      <section className="sub-container">
        {compareType === CompareType.PRODUCTS && <CompareProducts />}
        {compareType === CompareType.COUNTRIES_INTL_STANDARDS && (
          <CompareCountries />
        )}
      </section>
    </div>
  );
};

export default Compare;
