import { SelectOption } from 'AppTypes';
import { getRemoteAssetURL } from 'assets/remote';
import { FormattedMessage } from 'react-intl';

export const STATUS_OK = 'ok';
export const STATUS_LOADING = 'loading';
export const STATUS_ERROR = 'error';
export const STATUS_NO_DATA = 'no-data';

export const OPTION_ALL_MEASURES = {
  label: (
    <FormattedMessage
      id="select.option.allMeasures"
      defaultMessage="All Measures"
    />
  ),
  value: 'ALL_MEASURES',
};

export enum MeasureType {
  SPS = 'SPS',
  TBT = 'TBT',
  OTHER = 'OtherMeasures',
  TECHNICAL_REGULATIONS = 'TechnicalRegulations',
  CONFORMITY_ASSESSMENT = 'ConformityAssessment',
}

export enum MeasureFilterType {
  SPS_TBT = 1,
  TECHNICAL_CONFORMITY = 2,
}

export const MeasureFilterAndMeasureTypeMapping = {
  [MeasureFilterType.SPS_TBT]: [
    MeasureType.SPS,
    MeasureType.TBT,
    MeasureType.OTHER,
  ],
  [MeasureFilterType.TECHNICAL_CONFORMITY]: [
    MeasureType.TECHNICAL_REGULATIONS,
    MeasureType.CONFORMITY_ASSESSMENT,
    MeasureType.OTHER,
  ],
};

export const MeasureTypeDetails = {
  [MeasureType.SPS]: {
    key: MeasureType.SPS,
    title: (
      <FormattedMessage
        id="measures.type.sanitaryAndPhytosanitary"
        defaultMessage="Sanitary and Phyto&shy;sanitary measures"
        description="The &shy; is added to allow the word phytosanitary to break into two lines correctly"
      >
        {(text) => (
          <span
            dangerouslySetInnerHTML={{ __html: text as unknown as string }}
          />
        )}
      </FormattedMessage>
    ),
    icon: <svg data-src={getRemoteAssetURL('sanitary')} />,
    iconNoPadding: (
      <img alt="" src={getRemoteAssetURL('sanitary', true)} width="52" />
    ),
  },
  [MeasureType.TBT]: {
    key: MeasureType.TBT,
    title: (
      <FormattedMessage
        id="measures.type.technicalBarriersToTrade"
        defaultMessage="Technical Barriers to Trade"
      />
    ),
    icon: <svg data-src={getRemoteAssetURL('technicalBarriers')} />,
    iconNoPadding: (
      <img
        alt=""
        src={getRemoteAssetURL('technicalBarriers', true)}
        width="70"
      />
    ),
  },
  [MeasureType.OTHER]: {
    key: MeasureType.OTHER,
    title: (
      <FormattedMessage
        id="measures.type.otherImportRequirements"
        defaultMessage="Other Import Requirements"
      />
    ),
    icon: <svg data-src={getRemoteAssetURL('other')} />,
    iconNoPadding: (
      <img alt="" src={getRemoteAssetURL('other', true)} width="68" />
    ),
  },
  [MeasureType.TECHNICAL_REGULATIONS]: {
    key: MeasureType.TECHNICAL_REGULATIONS,
    title: (
      <FormattedMessage
        id="measures.type.technicalRegulations"
        defaultMessage="Technical Regulations"
      />
    ),
    icon: <svg data-src={getRemoteAssetURL('technicalRegulations')} />,
    iconNoPadding: (
      <img
        alt=""
        src={getRemoteAssetURL('technicalRegulations', false)}
        width="68"
      />
    ),
  },
  [MeasureType.CONFORMITY_ASSESSMENT]: {
    key: MeasureType.CONFORMITY_ASSESSMENT,
    title: (
      <FormattedMessage
        id="measures.type.conformityAssessment"
        defaultMessage="Conformity Assessment"
      />
    ),
    icon: <svg data-src={getRemoteAssetURL('conformityAssessment')} />,
    iconNoPadding: (
      <img
        alt=""
        src={getRemoteAssetURL('conformityAssessment', false)}
        width="68"
      />
    ),
  },
};

export enum GroupOption {
  BY_SEARCH = 1,
  BY_ASSESSMENT = 2,
}

export const FILTER_VIEW_OPTIONS: SelectOption<MeasureFilterType>[] = [
  {
    label: (
      <FormattedMessage
        id="select.option.spsAndTbt"
        defaultMessage="SPS/TBT grouping"
      />
    ),
    value: MeasureFilterType.SPS_TBT,
  },
  {
    label: (
      <FormattedMessage
        id="select.option.techRegulationsAndConformityAssessment"
        defaultMessage="Technical regulations, Conformity assessment grouping"
      />
    ),
    value: MeasureFilterType.TECHNICAL_CONFORMITY,
  },
];

export enum CompareType {
  COUNTRIES_INTL_STANDARDS = '1',
  PRODUCTS = '2',
}
