import React from 'react';
import styles from './Home.module.scss';
import clsx from 'clsx';
import WidgetSectionBgJpg from 'assets/images/widget-section-bg.jpg';
import WidgetSectionBgJpg05x from 'assets/images/widget-section-bg@0.5x.jpg';
import WidgetSectionBgJpg2x from 'assets/images/widget-section-bg@2x.jpg';
import WidgetSectionBgWebp from 'assets/images/widget-section-bg.webp';
import WidgetSectionBgWebp05x from 'assets/images/widget-section-bg@0.5x.webp';
import WidgetSectionBgWebp2x from 'assets/images/widget-section-bg@2x.webp';
import { SearchWidget } from 'modules/search';
import { CompareWidget } from 'modules/compare';
import { SelfAssessWidget } from 'modules/self-assess';
import { useGetHomePage } from 'services/cms';
import ContentSection from './ContentSection';

const Home = () => {
  const { data } = useGetHomePage();

  return (
    <>
      <section className={styles.widgetArea}>
        <picture className={styles.background}>
          <source
            srcSet={`${WidgetSectionBgWebp05x} 0.5x, ${WidgetSectionBgWebp} 1x, ${WidgetSectionBgWebp2x} 2x`}
            type="image/webp"
          />
          <img
            src={WidgetSectionBgJpg}
            alt=""
            srcSet={`${WidgetSectionBgJpg05x} 0.5x, ${WidgetSectionBgJpg} 1x, ${WidgetSectionBgJpg2x} 2x`}
          />
        </picture>
        <div className={clsx('sub-container', styles.wrapper)}>
          <div className={styles.widget}>
            <SearchWidget data={data?.widgetSearch} />
          </div>
          <div className={styles.widget}>
            <CompareWidget data={data?.widgetCompare} />
          </div>
          <div className={styles.widget}>
            <SelfAssessWidget data={data?.widgetSelfAssess} />
          </div>
        </div>
      </section>
      <div className={styles.infoArea}>
        <div className="sub-container">
          <div className="row">
            <div className="col-xl-5">
              {data?.bodyLeftSections?.map((sectionData, index) => (
                <ContentSection key={index} data={sectionData} />
              ))}
            </div>
            <div className={clsx('col-xl-5 offset-xl-2', styles.reverse)}>
              {data?.bodyRightSections?.map((sectionData, index) => (
                <ContentSection key={index} data={sectionData} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
