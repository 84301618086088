import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

function DefaultPageTitle() {
  const intl = useIntl();

  const title = intl.formatMessage({
    id: 'application.title',
    description: 'Website title on the browser tab',
    defaultMessage: 'Non-tariff Measures Self Assessment Tool',
  });

  return <Helmet defaultTitle={title} titleTemplate={`%s | ${title}`} />;
}

export default DefaultPageTitle;
