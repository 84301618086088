import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { CloseButton, FormControl, FormControlProps } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDebouncedCallback } from 'use-debounce';
import styles from './InputRHF.module.scss';

type Props = FormControlProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    name: string;
    canClear?: boolean;
    clearBtnExternal?: boolean;
    fullBorder?: boolean;
    wrapperClassName?: string;
  };

const InputRHF = ({
  name,
  canClear,
  clearBtnExternal = false,
  fullBorder,
  wrapperClassName,
  ...formControlProps
}: Props) => {
  const intl = useIntl();

  const {
    field: { onChange: onChangeRHF, onBlur: onBlurRHF, value: valueRHF, ref },
    fieldState: { error },
  } = useController({
    name,
  });

  const [inputValue, setInputValue] = useState<string>(valueRHF);

  useEffect(() => {
    setInputValue(valueRHF);
  }, [valueRHF]);

  const debouncedOnChange = useDebouncedCallback(
    (value: string | null | undefined) => {
      onChangeRHF(value);
    },
    300
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      debouncedOnChange(e.target.value);
    },
    [debouncedOnChange]
  );

  return (
    <div>
      <div
        className={clsx(
          styles.searchInput,
          error?.message && styles.error,
          fullBorder && styles.fullBorder,
          wrapperClassName
        )}
      >
        <FormControl
          className={styles.input}
          type="text"
          value={inputValue}
          onChange={onInputChange}
          ref={ref}
          onBlur={onBlurRHF}
          {...formControlProps}
        />
        {canClear && !!inputValue && (
          <CloseButton
            onClick={() => setInputValue('')}
            className={clsx(
              styles.clearBtn,
              'clearInput',
              clearBtnExternal && styles.external
            )}
            title={intl.formatMessage({
              id: 'button.action.clearInput',
              defaultMessage: 'Clear input',
            })}
            disabled={formControlProps.disabled}
          />
        )}
      </div>
      {error?.message && (
        <div className="text-error">
          <div>{error?.message}</div>
        </div>
      )}
    </div>
  );
};

export default InputRHF;
