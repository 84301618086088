import {
  createApi,
  fetchBaseQuery,
  skipToken,
} from '@reduxjs/toolkit/query/react';
import {
  AssessProfileDetailRequest,
  AssessProfileMeasureIndicatorResponse,
  AssessProfileResponse,
  AssessProfileSummary,
  AssessUpdateCompliance,
  CreateAssetProfile,
  UpdateAssetProfile,
} from 'ApiServices';
import {
  AssessProfile,
  AssessProfileMeasureIndicator,
  SelfAssessProfileForm,
} from 'AppTypes';
import config from 'config';
import { useGetSelfAssessParams } from 'hooks/useGetSelfAssessParams';
import { getStandardMeasureCode } from 'utils/helpers';
import { getUser, useInitUserSession } from './auth';

const MAX_VALUE = 1_000_000;
const MIN_VALUE = 0;

export const selfAssessApi = createApi({
  reducerPath: 'selfAssessApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiPrimary}/v1/`,
    prepareHeaders: (headers) => {
      const user = getUser();
      const accessToken = user?.access_token;
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
        headers.set('X-API-UserId', user.profile.sub);
        headers.set('Accept-Language', 'en');
      } else {
        console.log('access token not available');
      }

      return headers;
    },
    credentials: 'include',
  }),
  tagTypes: ['Profiles', 'Summary', 'MeasureList'],
  endpoints: (builder) => ({
    getProfiles: builder.query<AssessProfile[], void>({
      query: () => 'get-profile-list',
      transformResponse: (response: AssessProfileResponse[]) => {
        return response.reverse().map((profile) => ({
          ...profile,
          productDisplayName: [profile.productCode, profile.productName]
            .filter(Boolean)
            .join(' - '),
        }));
      },
      providesTags: ['Profiles'],
    }),
    deleteProfile: builder.mutation<void, string>({
      query: (profileId) => ({
        url: 'delete-profile',
        params: {
          profileId,
        },
        method: 'DELETE',
      }),
      async onQueryStarted(profileId, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            selfAssessApi.util.updateQueryData(
              'getProfiles',
              undefined,
              (draft) => {
                const index = draft.findIndex(
                  (profile) => profile.assessmentProfileId === profileId
                );
                if (index !== -1) draft.splice(index, 1);
              }
            )
          );
          setTimeout(() => {
            dispatch(selfAssessApi.util.invalidateTags(['Profiles']));
          }, 0);
        } catch (e) {
          console.error(e);
        }
      },
    }),
    updateProfileName: builder.mutation<void, UpdateAssetProfile>({
      query: (body) => ({
        url: 'save-profile',
        method: 'POST',
        body,
      }),
      onQueryStarted(profileUpdates, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          selfAssessApi.util.updateQueryData(
            'getProfiles',
            undefined,
            (draft) => {
              const index = draft.findIndex(
                (profile) =>
                  profileUpdates.assessmentProfileId ===
                  profile.assessmentProfileId
              );
              draft[index].profileName = profileUpdates.profileName;
            }
          )
        );

        queryFulfilled.catch(patchResult.undo);
      },
      // invalidatesTags: ['Profiles'],
    }),
    createProfile: builder.mutation<string, SelfAssessProfileForm>({
      query: (body) => ({
        url: 'save-profile',
        method: 'POST',
        body: {
          ...body,
          assessmentProfileId: null,
        } as CreateAssetProfile,
      }),
      invalidatesTags: ['Profiles'],
    }),
    getProfileSummary: builder.query<AssessProfileSummary, string>({
      query: (profileId) => ({
        url: 'get-summary',
        params: {
          assessmentProfileId: profileId,
        },
      }),
      transformResponse: (response: AssessProfileSummary) =>
        [...response].sort((a, b) => {
          if (a.groupOption !== b.groupOption) {
            return b.groupOption - a.groupOption;
          }

          if (a.measureType !== b.measureType) {
            return a.measureType?.localeCompare(b.measureType);
          }

          if (a.measureCount !== b.measureCount) {
            return b.measureCount - a.measureCount;
          }

          return a.shortTitle?.localeCompare(b.shortTitle);
        }),
      providesTags: (result, error, profileId) => [
        { type: 'Summary', id: profileId },
      ],
    }),
    getProfileMeasures: builder.query<
      AssessProfileMeasureIndicator[],
      AssessProfileDetailRequest
    >({
      query: (body) => ({
        url: 'self-asses-measure-list',
        method: 'POST',
        body,
      }),
      transformResponse: (response: AssessProfileMeasureIndicatorResponse[]) =>
        response
          .map((item) => {
            const {
              legislationtitle,
              weblink,
              celexno,
              indicatorExplanation,
              affectedcountry,
              minValue,
              maxValue,
              ...data
            } = item;

            return {
              ...data,
              legislationTitle: legislationtitle,
              webLink: weblink,
              celexNo: celexno,
              indicatorExplanation,
              affectedCountry: affectedcountry,
              minValue: !Number.isNaN(Number(minValue))
                ? Math.max(MIN_VALUE, Number(minValue))
                : MIN_VALUE,
              maxValue: !Number.isNaN(Number(maxValue))
                ? Math.min(MAX_VALUE, Number(maxValue))
                : MAX_VALUE,
            };
          })
          .sort((a, b) => a.measureId?.localeCompare(b.measureId)),
      providesTags: (result, error, params) => [
        {
          type: 'MeasureList',
          id: `${params.assessmentProfileId}-${getStandardMeasureCode(
            params.ntmCode || ''
          )}`,
        },
      ],
    }),
    updateCompliance: builder.mutation<void, AssessUpdateCompliance>({
      query: (body) => ({
        url: 'submit-complied',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, params) => [
        'Profiles',
        { type: 'Summary', id: params.AssessmentProfileId },
        {
          type: 'MeasureList',
          id: `${params.AssessmentProfileId}-${getStandardMeasureCode(
            params.NtmCode
          )}`,
        },
      ],
    }),
  }),
});

export const {
  useDeleteProfileMutation,
  useUpdateProfileNameMutation,
  useCreateProfileMutation,
  useUpdateComplianceMutation,
} = selfAssessApi;

export function useGetProfilesQuery() {
  const sessionData = useInitUserSession();
  return selfAssessApi.useGetProfilesQuery(sessionData ? undefined : skipToken);
}

export function useGetCategorizedProfilesQuery() {
  const sessionData = useInitUserSession();
  return selfAssessApi.useGetProfilesQuery(
    sessionData ? undefined : skipToken,
    {
      selectFromResult: ({ data, ...response }) => ({
        ...response,
        data: {
          emptyProfiles: data?.filter((profile) => profile.isEmpty) || [],
          nonEmptyProfiles: data?.filter((profile) => !profile.isEmpty) || [],
        },
      }),
    }
  );
}

export function useGetProfileById() {
  const { profileId } = useGetSelfAssessParams();
  const { data: profiles } = useGetProfilesQuery();
  return profiles?.find((profile) => profile.assessmentProfileId === profileId);
}

export function useGetProfileSummaryQuery() {
  const sessionData = useInitUserSession();
  const { profileId } = useGetSelfAssessParams();
  return selfAssessApi.useGetProfileSummaryQuery(
    sessionData && profileId ? profileId : skipToken
  );
}

export function useGetProfileMeasuresQuery() {
  const sessionData = useInitUserSession();
  const { profileId, ntmCode } = useGetSelfAssessParams();
  return selfAssessApi.useGetProfileMeasuresQuery(
    sessionData && profileId && ntmCode
      ? { assessmentProfileId: profileId, ntmCode }
      : skipToken
  );
}

export function fetchSelfAssessReportFile(profileId: string) {
  const user = getUser();
  const accessToken = user?.access_token;

  return fetch(
    `${config.apiPrimary}/v1/get-selfassess-report?assessmentProfileId=${profileId}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).then((response) => response.blob());
}
