import React, { useCallback, useEffect, useState } from 'react';
import { useIndicatorToggle, useMeasureGroup } from 'hooks/useMeasureGroup';
import { SingleValue } from 'react-select';
import { IndicatorDetail, SelectOption } from 'AppTypes';
import NTMSelect from 'components/Select';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'components/Button';
import SourceLegislation from 'modules/app/SourceLegislation';
import styles from 'modules/app/MeasureGroupDetails.module.scss';
import customStyles from './CompareCountriesDetailIndicator.module.scss';
import Card from 'components/Card';
import clsx from 'clsx';
import { sanitize } from 'dompurify';

type Props = {
  indicators: IndicatorDetail[];
};

const CompareCountriesDetailIndicator = ({ indicators }: Props) => {
  const intl = useIntl();
  const { measuresToSelect, indicatorsGroupedByMeasures } =
    useMeasureGroup(indicators);

  const [selectedMeasure, setSelectedMeasure] = useState<
    SingleValue<SelectOption>
  >(measuresToSelect[0]);

  useEffect(() => {
    if (measuresToSelect?.[0]) {
      setSelectedMeasure(measuresToSelect[0]);
    }
  }, [measuresToSelect]);

  const {
    isSelectedAllMeasures,
    selectedIndicators,
    isDisplaySource,
    onToggleSource,
  } = useIndicatorToggle(
    indicators,
    selectedMeasure,
    indicatorsGroupedByMeasures
  );

  const onChangeSelectedMeasure = useCallback(
    (newValue: SingleValue<SelectOption>) => {
      setSelectedMeasure(newValue);
    },
    []
  );

  return (
    <Card className={customStyles.card}>
      <div className={styles.header}>
        <div className={clsx(styles.wrapper, customStyles.headerWrapper)}>
          <div className={styles.title}>
            <NTMSelect
              options={measuresToSelect}
              defaultValue={measuresToSelect?.[0]}
              value={selectedMeasure}
              onChange={onChangeSelectedMeasure}
              showNavigationButtons
            />
          </div>
          {!isSelectedAllMeasures && (
            <div className={styles.sourceLegislation}>
              <Button
                type="button"
                size="sm"
                onClick={onToggleSource}
                className={styles.toggleBtn}
              >
                {isDisplaySource ? (
                  <FormattedMessage
                    id="button.action.viewMeasures"
                    defaultMessage="Measures"
                  />
                ) : (
                  <FormattedMessage
                    id="button.action.viewSourceLegislation"
                    defaultMessage="Source Legislation"
                  />
                )}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={clsx(styles.body, customStyles.body)}>
        {isDisplaySource ? (
          !!selectedIndicators?.[0] && (
            <SourceLegislation measure={selectedIndicators[0]} />
          )
        ) : (
          <dl className={customStyles.details}>
            {selectedIndicators.map((indicator, index) => (
              <React.Fragment key={index}>
                {isSelectedAllMeasures && (
                  <dt>{`${intl.formatMessage({
                    id: 'measures.prefix',
                    defaultMessage: 'Measure',
                  })} ${index + 1}`}</dt>
                )}
                <dd>{sanitize(indicator.indicatorExplanation || '')}</dd>
              </React.Fragment>
            ))}
          </dl>
        )}
      </div>
    </Card>
  );
};

export default React.memo(CompareCountriesDetailIndicator);
