import React from 'react';
import CMSGenericPage from 'modules/cms/CMSGenericPage';
import { useContent } from 'services/cms';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import Loading from 'components/Loading';

const FaqPage = () => {
  const response = useContent('faq');
  const { data } = response;
  const intl = useIntl();

  return (
    <>
      <Helmet
        title={intl.formatMessage({
          id: 'application.title.faq',
          defaultMessage: 'FAQ',
        })}
      />
      <Loading
        response={response}
        loadingWrapperClassName="justify-content-center p-3"
      >
        <CMSGenericPage data={data} highlightLevel2 />
      </Loading>
    </>
  );
};

export default FaqPage;
