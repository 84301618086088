import React from 'react';
import BaseWidget from 'components/BaseWidget';
import { HomePageWidget } from 'AppTypes';
import { sanitize } from 'dompurify';
import CompareForm from './CompareForm';

const CompareWidget = ({ data }: { data?: HomePageWidget }) => {
  return (
    <BaseWidget
      title={data?.title}
      icon={<img src={data?.titleIcon?.[0]?.url} alt="" />}
      decorColor="cyan"
    >
      <div
        dangerouslySetInnerHTML={{
          __html: sanitize(data?.description || ''),
        }}
      />
      <CompareForm className="mt-3" />
    </BaseWidget>
  );
};

export default CompareWidget;
