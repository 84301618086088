import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import config from 'config';
import { push } from 'redux-first-history';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

/**
 * Log a warning and show a toast
 */
export const rtkQueryErrorDisplay: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action) && isRequestToMainAPIUnauthorized(action)) {
      toast.error(
        <>
          <strong>
            <FormattedMessage id="message.error" defaultMessage="Error" />
          </strong>
          <div>
            <FormattedMessage
              id="message.error.unauthorized"
              defaultMessage="Unauthorized request, please log in and try again."
            />
          </div>
        </>
      );

      // setTimeout(() => {
      //   window.location.reload();
      // }, 3000);

      return next(push('/'));
    }

    if (isRejectedWithValue(action)) {
      toast.error(
        <>
          <strong>
            <FormattedMessage id="message.error" defaultMessage="Error" />
          </strong>
          <div>
            {action.error?.data?.message || action.payload?.data?.error}
          </div>
        </>
      );
    }

    return next(action);
  };

function isRequestToMainAPIUnauthorized(action: any) {
  return (
    action?.meta?.baseQueryMeta?.request?.url?.startsWith(config.apiPrimary) &&
    action?.payload?.status === 401
  );
}

export const isFetchBaseQueryErrorType = (
  error: any
): error is FetchBaseQueryError => 'status' in error;
