import { createSlice } from '@reduxjs/toolkit';
import { AssessComplianceMeasureRouteMetadata } from 'AppTypes';

export const configSlice = createSlice({
  name: 'config',
  initialState: {
    locale: 'en' as string,
    lastSelfAssessComplianceItem:
      null as AssessComplianceMeasureRouteMetadata | null,
  },
  reducers: {
    setLocale: (state, action) => {
      state.locale = ['en', 'fr', 'es'].includes(action.payload)
        ? action.payload
        : 'en';
    },
    setLastSelfAssessComplianceItem: (state, action) => {
      state.lastSelfAssessComplianceItem = action.payload;
    },
  },
});

export const { setLocale, setLastSelfAssessComplianceItem } =
  configSlice.actions;

export default configSlice.reducer;
