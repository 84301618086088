import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntersectionObserver } from 'usehooks-ts';

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

export function useStickyHeaderSentinel({
  className,
  observerArgs = {},
}: {
  className?: string;
  observerArgs?: Args;
} = {}) {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, observerArgs);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (entry && !entry.isIntersecting) {
      setIsSticky(true);
    }
    if (entry && entry.isIntersecting) {
      setIsSticky(false);
    }
  }, [entry]);

  const sentinel = useMemo(
    () => (
      <div
        ref={ref}
        className={clsx('productNameSection-sentinel', className)}
      />
    ),
    [className]
  );

  return {
    isSticky,
    sentinel,
  };
}
