import React from 'react';
import { AssessProfileMeasureIndicator } from 'AppTypes';
import styles from './AssessMeasureDetailsList.module.scss';
import { sanitize } from 'dompurify';
import AssessMeasureDetailsNavigation from './AssessMeasureDetailsNavigation';
import AssessMeasureItemRadioGroup from './AssessMeasureItemRadioGroup';

const AssessMeasureDetailsList = ({
  indicators,
}: {
  indicators: AssessProfileMeasureIndicator[];
}) => {
  return (
    <div className={styles.details}>
      <div className={styles.list}>
        {indicators.map((indicator, index) => (
          <div className={styles.indicatorDetail} key={index}>
            <div className={styles.title}>{indicator.indicatorLabel}</div>
            <div className={styles.content}>
              <div
                className={styles.indicatorContent}
                dangerouslySetInnerHTML={{
                  __html: sanitize(indicator.indicatorExplanation || ''),
                }}
              />
              {indicator.complianceApplicable && (
                <AssessMeasureItemRadioGroup
                  key={`${indicator.measureId}-${indicator.assessmentId}`}
                  indicator={indicator}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.action}>
        <AssessMeasureDetailsNavigation indicators={indicators} />
      </div>
    </div>
  );
};

export default React.memo(AssessMeasureDetailsList);
