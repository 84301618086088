const FLAG_CODE_MAPPING: Record<string, string> = {
  ab: 'aw', // Aruba
  fq: 'tf', // French southern territories
  ko: 'xk', // Kosovo
  gz: 'ps', // State of Palestine
  tp: 'tl', // Timor-Leste
  yu: 'xx', // Serbia & Montenegro - old country
  pc: 'xx', // Pacific Islands
};

export function getFlagCode(code?: string) {
  const lowercaseCode = code?.toLowerCase() ?? '';

  return FLAG_CODE_MAPPING[lowercaseCode] ?? lowercaseCode;
}
