import React, { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompareProductsModel } from 'AppTypes';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as GlobeIcon } from 'assets/icons/globe.svg';
import { ReactComponent as ProductIcon } from 'assets/icons/product.svg';
import styles from './CompareForm.module.scss';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { getRoute } from 'utils/router';
import { CountrySelectRHF } from 'components/Select';
import ProductSelect from 'components/Select/ProductSelect';
import clsx from 'clsx';
import { useGetCompareProductsParams } from 'hooks/useGetCompareParams';
import { CompareType } from 'modules/constants';
import {
  useGetFromCountriesQuery,
  useGetToCountriesQuery,
} from 'services/countries';

const compareProductsSchema = yup.object({
  partner: yup.string().required().label('From country'),
  reporterId: yup.string().required().label('To country'),
  productId1: yup.string().required().label('Product 1'),
  productId2: yup.string().required().label('Product 2'),
});

type Props = {
  horizontalLayout?: boolean;
};

const CompareProductsForm = ({ horizontalLayout }: Props) => {
  const navigate = useNavigate();
  const params = useGetCompareProductsParams();

  const formMethods = useForm<CompareProductsModel>({
    resolver: yupResolver(compareProductsSchema),
    defaultValues: params,
  });

  const { handleSubmit, watch } = formMethods;

  const [fromValue, toValue, productId1Value, productId2Value] = watch([
    'partner',
    'reporterId',
    'productId1',
    'productId2',
  ]);

  const onSubmit = useCallback(
    (data: CompareProductsModel) => {
      navigate({
        pathname: getRoute('compare'),
        search: `${createSearchParams({
          ...data,
          type: CompareType.PRODUCTS,
        })}`,
      });
    },
    [navigate]
  );

  const { data: fromCountries = [], isFetching: isFetchingFromCountries } =
    useGetFromCountriesQuery();
  const { data: toCountries = [], isFetching: isFetchingToCountries } =
    useGetToCountriesQuery();

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={clsx(styles.form, horizontalLayout && styles.horizontal)}
      >
        <div className={styles.formBody}>
          <div className={styles.formGroup}>
            <label htmlFor="compare.form.from">
              <GlobeIcon />
              <FormattedMessage
                id="compare.form.fromLabel"
                defaultMessage="From"
              />
            </label>
            <div className={styles.formField}>
              <CountrySelectRHF
                name="partner"
                id="compare.form.from"
                options={fromCountries}
                isLoading={isFetchingFromCountries}
                isOptionDisabled={(option) => option.code === toValue}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="compare.form.to">
              <GlobeIcon />
              <FormattedMessage id="compare.form.toLabel" defaultMessage="To" />
            </label>
            <div className={styles.formField}>
              <CountrySelectRHF
                name="reporterId"
                id="compare.form.to"
                options={toCountries}
                isLoading={isFetchingToCountries}
                isOptionDisabled={(option) => option.code === fromValue}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="compare.form.productCode1">
              <ProductIcon />
              <FormattedMessage
                id="compare.form.product1Label"
                defaultMessage="Product 1"
              />
            </label>
            <div className={styles.formField}>
              <ProductSelect
                name="productId1"
                id="compare.form.productCode1"
                isOptionDisabled={(option) => option.code === productId2Value}
              />
            </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="compare.form.productCode2">
              <ProductIcon />
              <FormattedMessage
                id="compare.form.product2Label"
                defaultMessage="Product 2"
              />
            </label>
            <div className={styles.formField}>
              <ProductSelect
                name="productId2"
                id="compare.form.productCode2"
                isOptionDisabled={(option) => option.code === productId1Value}
              />
            </div>
          </div>
        </div>
        <div className={styles.formAction}>
          <Button type="submit" variant="secondary" hasCornerCut={true}>
            {
              <FormattedMessage
                id="compare.form.submitBtn"
                defaultMessage="Compare"
              />
            }
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default CompareProductsForm;
