import React from 'react';
import { IndicatorDetail } from 'AppTypes';
import styles from './MeasureDetails.module.scss';
import Truncate from 'components/Truncate';
import { sanitize } from 'dompurify';

type Props = {
  indicators: IndicatorDetail[];
  toggleState: Record<string, boolean | undefined>;
  updateToggleState: (value: boolean, index?: number | string) => void;
};

const MeasureDetailsList = ({
  indicators,
  toggleState,
  updateToggleState,
}: Props) => {
  return (
    <dl className={styles.details}>
      {indicators.map((indicator, index) => (
        <React.Fragment key={index}>
          <dt>{indicator.indicatorLabel}</dt>
          <dd>
            <Truncate
              toggleKeyProp={indicator.indicatorId}
              defaultExpanded={toggleState[indicator.indicatorId]}
              onToggle={updateToggleState}
            >
              {sanitize(indicator.indicatorExplanation || '')}
            </Truncate>
          </dd>
        </React.Fragment>
      ))}
    </dl>
  );
};

export default React.memo(MeasureDetailsList);
