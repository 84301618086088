import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import template from 'just-template';
import styles from './CompareProductHeader.module.scss';
import clsx from 'clsx';
import MeasureFilterView from 'modules/app/MeasureFilterView';
import { useIntersectionObserver } from 'usehooks-ts';

type Props = {
  title1: string;
  measureCount1: number;
  flag1?: string | null;
  title2: string;
  measureCount2: number;
  flag2?: string | null;
};

const ImportRequirement = ({
  measureCount,
  className,
}: {
  measureCount: number;
  className?: string;
}) => {
  const intl = useIntl();

  return (
    <div className={clsx(styles.importRequirement, className)}>
      <p>
        <FormattedMessage
          id="compare.info.importRequirements"
          defaultMessage="Import requirements applied to this product"
        />
        :
      </p>
      <p className={styles.measures}>
        {template(
          intl.formatMessage({
            id: 'compare.info.totalMeasures',
            defaultMessage: 'Total {{data}} measure(s)',
          }),
          { data: measureCount.toString() }
        )}
      </p>
    </div>
  );
};

const CompareProductHeader = ({
  title1,
  title2,
  measureCount1,
  measureCount2,
  flag1,
  flag2,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {});
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (entry && !entry.isIntersecting) {
      setIsSticky(true);
    }
    if (entry && entry.isIntersecting) {
      setIsSticky(false);
    }
  }, [entry]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.requirements}>
          <ImportRequirement measureCount={measureCount1} />
          <MeasureFilterView useDropdown />
          <ImportRequirement
            measureCount={measureCount2}
            className={styles.end}
          />
        </div>
        <div
          ref={ref}
          className={clsx('productNameSection-sentinel', styles.sentinel)}
        />
      </div>
      <div
        className={clsx(styles.productNameSection, isSticky && styles.sticky)}
      >
        <div className={styles.titleArea} title={title1}>
          {flag1 && (
            <div>
              <span className={`fi fi-${flag1}`} aria-hidden={true} />
            </div>
          )}
          <span className={styles.titleName}>{title1}</span>
        </div>
        <div className={clsx(styles.titleArea, styles.flipped)} title={title2}>
          {flag2 && (
            <div>
              <span className={`fi fi-${flag2}`} aria-hidden={true} />
            </div>
          )}
          <span className={styles.titleName}>{title2}</span>
        </div>
      </div>
    </>
  );
};

export default CompareProductHeader;
