import React from 'react';
import { useGetMeasureTypesInFilter } from 'modules/app/MeasureFilterViewContext';
import styles from './NotCommonMeasureTypes.module.scss';
import { FormattedMessage } from 'react-intl';
import NotCommonMeasureTypeSection from './NotCommonMeasureTypeSection';
import clsx from 'clsx';
import { CompareResultNotCommonType } from 'AppTypes';

type Props = {
  title1: string;
  title2: string;
  flag1?: string | null;
  flag2?: string | null;
  data?: CompareResultNotCommonType;
};

const NotCommonMeasureTypesHeader = ({
  title,
  className,
  flag,
  isFlipped,
}: {
  title: string;
  flag?: string | null;
  className?: string;
  isFlipped?: boolean;
}) => {
  return (
    <div className={clsx(className, styles.itemContainer, 'header-container')}>
      <div className={clsx(styles.header, 'header-content')}>
        <h2>
          <FormattedMessage
            id="compare.info.notInCommonMeasureTypes"
            defaultMessage="Types of measures not in common"
          />
        </h2>
      </div>
      <div
        className={clsx(
          styles.titleArea,
          'header-product-name',
          isFlipped && styles.flipped
        )}
        title={title}
      >
        {flag && (
          <div>
            <span className={`fi fi-${flag}`} aria-hidden={true} />
          </div>
        )}
        <span className={styles.titleName}>{title}</span>
      </div>
    </div>
  );
};

const NotCommonMeasureTypes = ({
  title1,
  title2,
  flag1,
  flag2,
  data,
}: Props) => {
  const measureTypes = useGetMeasureTypesInFilter();

  return (
    <div>
      <div className={styles.headerArea}>
        <NotCommonMeasureTypesHeader
          title={title1}
          flag={flag1}
          className={styles.item}
        />
        <NotCommonMeasureTypesHeader
          title={title2}
          flag={flag2}
          className={styles.item}
          isFlipped
        />
      </div>
      {measureTypes
        .filter((measureType) => {
          if (data != null) {
            return data[measureType.key] != null;
          }

          return true;
        })
        .map((measureType, index) => (
          <NotCommonMeasureTypeSection
            key={index}
            title={measureType.title}
            icon={measureType.icon}
            measureCount1={data?.[measureType.key][0].totalCount || 0}
            measureCount2={data?.[measureType.key][1].totalCount || 0}
            data1={data?.[measureType.key][0].items || []}
            data2={data?.[measureType.key][1].items || []}
          />
        ))}
    </div>
  );
};

export default React.memo(NotCommonMeasureTypes);
