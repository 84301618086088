import React from 'react';
import { useGetMeasureTypesInFilter } from 'modules/app/MeasureFilterViewContext';
import styles from './CommonMeasureTypes.module.scss';
import CompareNTMAccordion from 'modules/compare/components/CompareNTMAccordion';
import containerStyles from './NotCommonMeasureTypeSection.module.scss';
import privateStyles from './OtherMeasureTypes.module.scss';
import MeasureCount from 'modules/compare/components/MeasureCount';
import clsx from 'clsx';
import { CompareResultOtherType } from 'AppTypes';

type Props = {
  data: CompareResultOtherType[];
};

const OtherMeasureTypes = ({ data }: Props) => {
  const measureTypes = useGetMeasureTypesInFilter(true);

  return (
    <>
      <div className={clsx(styles.header, privateStyles.header)}>
        <MeasureCount
          measureCount={data?.[0]?.totalCount || 0}
          className={privateStyles.count}
        />
        <h2>{measureTypes[2].title}</h2>
        <MeasureCount
          measureCount={data?.[1]?.totalCount || 0}
          className={privateStyles.count}
        />
      </div>
      <div className={containerStyles.container}>
        <div className={containerStyles.sectionHeader}>
          <div className={containerStyles.item}>
            <CompareNTMAccordion data={data?.[0]?.items || []} />
          </div>
          <div className={containerStyles.item}>
            <CompareNTMAccordion data={data?.[1]?.items || []} isRightSide />
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(OtherMeasureTypes);
