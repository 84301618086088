import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './EditableInput.module.scss';
import {
  CloseButton,
  FormControl,
  FormControlProps,
  Spinner,
} from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Button from 'components/Button';
import { Check, X } from 'react-bootstrap-icons';
import { useOnClickOutside } from 'usehooks-ts';

type Props = FormControlProps & {
  canClear?: boolean;
  fullBorder?: boolean;
  wrapperClassName?: string;
  error?: string;
  onSubmit?: (value?: any) => void;
  updateFn?: (value?: any) => Promise<void>;
  children?: React.ReactNode;
};

const EditableInput = ({
  canClear,
  fullBorder,
  wrapperClassName,
  error,
  onSubmit,
  updateFn,
  children,
  value,
  ...formControlProps
}: Props) => {
  const intl = useIntl();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingValue, setEditingValue] = useState<
    string | number | string[] | undefined
  >(value);
  const [displayValue, setDisplayValue] = useState<
    string | number | string[] | undefined
  >(value);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const formRef = useRef(null);

  useEffect(() => {
    setEditingValue(value);
    setDisplayValue(value);
  }, [value]);

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditingValue(event.target.value);
    },
    []
  );

  const onClear = useCallback(() => {
    setEditingValue('');
  }, []);

  const onSubmitChanges = useCallback(async () => {
    onSubmit?.(editingValue);
    setIsEditing(false);

    if (updateFn) {
      try {
        setIsUpdating(true);
        setDisplayValue(editingValue);
        await updateFn?.(editingValue);
        setEditingValue(editingValue);
      } catch (e) {
        setDisplayValue(value);
        setEditingValue(value);
      } finally {
        setIsUpdating(false);
      }
    } else {
      setDisplayValue(value);
      setEditingValue(value);
    }
  }, [editingValue, onSubmit, updateFn, value]);

  const onCancelEditing = useCallback(() => {
    setIsEditing(false);
    setEditingValue(value);
  }, [value]);

  const onInputKeydown = useCallback(
    (e: React.KeyboardEvent) => {
      // Escape key;
      if (e.code === 'Escape' || (e.code === 'Tab' && e.shiftKey)) {
        onCancelEditing();
        return;
      }

      if (e.code === 'Enter') {
        // @ts-ignore
        e.target.form?.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true })
        );
      }

      return e;
    },
    [onCancelEditing]
  );

  const triggerEditing = useCallback(() => {
    if (!isUpdating) {
      setIsEditing(true);
    }
  }, [isUpdating]);

  useOnClickOutside(formRef, onCancelEditing);

  return (
    <div className="w-100">
      {!isEditing && (
        <div className={styles.display}>
          <button
            type="button"
            aria-label={intl.formatMessage({
              id: 'button.action.edit',
              defaultMessage: 'Edit',
            })}
            onClick={triggerEditing}
            disabled={isUpdating}
          ></button>
          <div className={styles.container} onClick={triggerEditing}>
            <div
              className={clsx('editable-content', isUpdating && 'is-updating')}
              role="presentation"
            >
              {children ?? displayValue}
              {isUpdating && (
                <Spinner
                  animation="border"
                  className="d-inline-block ms-2"
                  size="sm"
                />
              )}
            </div>
          </div>
        </div>
      )}
      {isEditing && (
        <form
          className={styles.form}
          noValidate
          onSubmit={onSubmitChanges}
          ref={formRef}
        >
          <div
            className={clsx(
              styles.searchInput,
              error && styles.error,
              fullBorder && styles.fullBorder,
              wrapperClassName
            )}
          >
            <FormControl
              {...formControlProps}
              value={editingValue}
              onChange={onInputChange}
              className={clsx(styles.input, formControlProps.className)}
              autoFocus
              onKeyDown={onInputKeydown}
            />
            {canClear && !!editingValue && (
              <CloseButton
                onClick={onClear}
                className={clsx(styles.clearBtn, 'clearInput')}
                title={intl.formatMessage({
                  id: 'button.action.clearInput',
                  defaultMessage: 'Clear input',
                })}
                disabled={formControlProps.disabled}
              />
            )}
          </div>
          <div className={styles.actions}>
            <Button
              title={intl.formatMessage({
                id: 'button.action.save',
                defaultMessage: 'Save',
              })}
              size="sm"
              variant="light"
              type="submit"
              onClick={() => onSubmitChanges()}
            >
              <Check />
            </Button>
            <Button
              title={intl.formatMessage({
                id: 'button.action.cancel',
                defaultMessage: 'Cancel',
              })}
              size="sm"
              variant="light"
              type="button"
              onClick={onCancelEditing}
              onKeyDown={(e) => {
                if (e.key === 'Tab') {
                  onCancelEditing();
                  return;
                }
              }}
            >
              <X />
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default React.memo(EditableInput);
