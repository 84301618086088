export const EU_COUNTRY_CODES = [
  '040', // 'Austria'
  '056', // 'Belgium'
  '100', // 'Bulgaria'
  '191', // 'Croatia'
  '196', // 'Cyprus'
  '203', // Czech Republic'
  '208', // 'Denmark'
  '233', // 'Estonia'
  '246', // 'Finland'
  '251', // 'France'
  '276', // 'Germany'
  '300', // 'Greece'
  '348', // 'Hungary'
  '372', // 'Ireland'
  '381', // 'Italy'
  '428', // 'Latvia'
  '440', // 'Lithuania'
  '442', // 'Luxembourg'
  '470', // 'Malta'
  '528', // 'Netherlands'
  '616', // 'Poland'
  '620', // 'Portugal'
  '642', // 'Romania'
  '703', // 'Slovakia'
  '705', // 'Slovenia'
  '724', // 'Spain'
  '752', // 'Sweden'
];
