import { CMSFlatData, ExpandableItem, GenericAccordion } from 'AppTypes';
import Button from 'components/Button';
import React, { useCallback, useMemo, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import styles from './CustomAccordion.module.scss';
import CustomAccordionItem from './CustomAccordionItem';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { CustomAccordionContext } from './CustomAccordionContext';

function getEventKeysFromAccordionItem({
  item,
  results,
  index,
  level,
}: {
  item: CMSFlatData<ExpandableItem>;
  results: string[];
  index: number;
  level: number;
}) {
  results.push(`${level}-${index}`);

  if (!item.flatData?.children?.length) {
    // do nothing
    return;
  }

  for (let i = 0; i < item.flatData.children.length; i++) {
    getEventKeysFromAccordionItem({
      item: item.flatData.children[i],
      results,
      index: i,
      level: level + 1,
    });
  }
}

function getAccordionItemEventKeys(items: CMSFlatData<ExpandableItem>[]) {
  const results: string[] = [];

  for (let i = 0; i < items.length; i++) {
    getEventKeysFromAccordionItem({
      item: items[i],
      results,
      index: i,
      level: 1,
    });
  }

  return results;
}

const CustomAccordionContent = ({
  items,
  highlightLevel2,
}: {
  items: CMSFlatData<ExpandableItem>[];
  highlightLevel2?: boolean;
}) => {
  const allKeys = useMemo(() => getAccordionItemEventKeys(items), [items]);

  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  const onClickExpandAll = useCallback(() => {
    setActiveKeys(allKeys);
  }, [allKeys]);

  const onClickCollapseAll = useCallback(() => {
    setActiveKeys([]);
  }, []);

  const onClickActiveKeys = useCallback((selectedKey: string) => {
    setActiveKeys((keys: string[]) => {
      if (keys.includes(selectedKey)) {
        return keys.filter((key) => key !== selectedKey);
      }

      return keys.concat(selectedKey);
    });
  }, []);

  if (!items?.length) {
    return null;
  }

  return (
    <div>
      <div className={styles.actionBtn}>
        {activeKeys.length < allKeys.length && (
          <Button
            type="button"
            variant="light"
            size="sm"
            className={styles.expandBtn}
            onClick={onClickExpandAll}
          >
            <FormattedMessage
              id="button.action.expandAll"
              defaultMessage="Expand All"
            />
            <PlusIcon />
          </Button>
        )}
        {activeKeys.length > 0 && (
          <Button
            type="button"
            variant="light"
            size="sm"
            className={styles.collapseBtn}
            onClick={onClickCollapseAll}
          >
            <FormattedMessage
              id="button.action.collapseAll"
              defaultMessage="Collapse All"
            />
            <MinusIcon />
          </Button>
        )}
      </div>
      <CustomAccordionContext.Provider
        value={{ activeKeys, onClickItem: onClickActiveKeys, highlightLevel2 }}
      >
        <Accordion alwaysOpen activeKey={activeKeys}>
          {items.map((rootItem, index) => (
            <CustomAccordionItem data={rootItem} index={index} key={index} />
          ))}
        </Accordion>
      </CustomAccordionContext.Provider>
    </div>
  );
};

const CustomAccordion = ({
  data,
  highlightLevel2,
}: {
  data?: GenericAccordion;
  highlightLevel2?: boolean;
}) => {
  if (!data?.items?.length) {
    return null;
  }

  if (data?.title) {
    return (
      <Accordion flush bsPrefix="ntm-accordion" defaultActiveKey="root">
        <Accordion.Item eventKey="root" bsPrefix="ntm-accordion-item">
          <Accordion.Header bsPrefix="ntm-accordion-header">
            {data.title}
          </Accordion.Header>
          <Accordion.Body>
            <CustomAccordionContent
              items={data.items}
              highlightLevel2={highlightLevel2}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  }

  return (
    <CustomAccordionContent
      items={data.items}
      highlightLevel2={highlightLevel2}
    />
  );
};

export default React.memo(CustomAccordion);
