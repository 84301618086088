import Button from 'components/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'react-oidc-context';
import { ReactComponent as UserIcon } from 'assets/icons/user-filled.svg';
import styles from './AuthCheck.module.scss';
import clsx from 'clsx';

type Props = {
  children: React.ReactElement;
  insideModal?: boolean;
  insideBody?: boolean;
};

const AuthCheck = ({ children, insideModal, insideBody }: Props) => {
  const auth = useAuth();

  return auth.isAuthenticated ? (
    children
  ) : (
    <div className={styles.unauthenticated}>
      <p className="h3 mb-4">
        <FormattedMessage
          id="auth.message.requiredLogin"
          defaultMessage="Please log in to use this function"
        />
      </p>
      <div>
        <Button
          variant="white"
          className={clsx(
            styles.loginBtn,
            insideModal && styles.modal,
            insideBody && styles.body
          )}
          onClick={() =>
            void auth.signinRedirect({
              state: {
                title: window.document.title,
                href: window.location.href,
                pathname: window.location.pathname,
              },
            })
          }
          disabled={!!(auth.activeNavigator || auth.isLoading)}
        >
          <FormattedMessage
            id="selfAssess.widget.loginBtnLabel"
            defaultMessage="Login to use"
          />
          <UserIcon />
        </Button>
      </div>
    </div>
  );
};

export default AuthCheck;
