import React from 'react';
import { useGetCompareCountriesParams } from 'hooks/useGetCompareParams';
import CompareWithMultipleOptions from './CompareWithMultipleOptions';
import CompareWithTwoOptions from './CompareWithTwoOptions';
import { MeasureFilterViewContextProvider } from 'modules/app/MeasureFilterViewContext';

const CompareCountries = () => {
  const { reporterId: countries } = useGetCompareCountriesParams();

  return (
    <MeasureFilterViewContextProvider>
      {Array.isArray(countries) && countries.length === 2 && (
        <CompareWithTwoOptions />
      )}
      {Array.isArray(countries) && countries.length > 2 && (
        <CompareWithMultipleOptions />
      )}
    </MeasureFilterViewContextProvider>
  );
};

export default CompareCountries;
