import React from 'react';
import { SelfAssessPage } from 'AppTypes';
import Button from 'components/Button';
import styles from './SelfAssessProfileFooter.module.scss';

type Props = {
  data: SelfAssessPage;
};

const SelfAssessProfileFooter = ({ data }: Props) => {
  return (
    <section className={styles.footer}>
      <div
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: data.footerTitle }}
      />
      <div>
        <Button
          variant="secondary"
          href={data.footerCTALink}
          target="__blank"
          rel="noreferrer"
          className={styles.ctaBtn}
        >
          {data.footerCTALabel}
        </Button>
      </div>
    </section>
  );
};

export default SelfAssessProfileFooter;
