import React, { useEffect } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  SortingState,
} from '@tanstack/react-table';
import styles from './SimpleTable.module.scss';
import clsx from 'clsx';
import ExcelFilter from './ExcelFilter';
import { FormattedMessage, useIntl } from 'react-intl';
import { singleValueInArray } from './customFilterFns';
import { isEmpty, orderBy } from 'lodash';
import Button, { DownloadButton } from 'components/Button';
import { createPortal } from 'react-dom';

type Props<T extends Record<string, any>> = {
  data: T[];
  columns: ColumnDef<T, any>[];
  tableClass?: string;
  tableStyle?: React.CSSProperties;
  stickyHeader?: boolean;
  hasRowTooltip?: boolean;
  containerForActions?: Element | DocumentFragment | null;
  onClickDownloadBtn?: () => void;
  enableGlobalFilter?: boolean;
  enableSorting?: boolean;
  defaultSortingState?: SortingState;
};


function SimpleTable<T extends Record<string, any>>({
  data,
  columns,
  tableClass,
  tableStyle,
  stickyHeader,
  hasRowTooltip,
  containerForActions,
  onClickDownloadBtn,
  enableGlobalFilter,
  enableSorting,
  defaultSortingState,
}: Props<T>) {
  const intl = useIntl();
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState<string>('');
  const [tableColumns, setTableColumns] =
    React.useState<ColumnDef<T, any>[]>(columns);

  const table = useReactTable<T>({
    data,
    columns: tableColumns,
    enableFilters: true,
    enableColumnFilters: true,
    enableGlobalFilter,
    enableSorting,
    state: {
      columnFilters,
      globalFilter,
      ...(enableSorting ? { sorting: defaultSortingState ?? [] } : {}),
    },
    filterFns: {
      singleValueInArray: singleValueInArray,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });


  useEffect(() => {
    if (columns.length > 0) {
      const columnOrder = orderBy(
        columns.map((column: any) => ({
          id: column?.meta?.indicator?.indicatorId,
          order: column?.meta?.indicator?.indicatorOrder,
        })),
        ['order'],
        ['asc']
      );

      table.setColumnOrder(columnOrder.map((column: any) => column.id));
    }
  }, [columns, table]);

  return (
    <div className={styles.container}>
      {containerForActions &&
        createPortal(
          <div>
            {!isEmpty(columnFilters) && (
              <Button
                size="sm"
                className={styles.clearAllFilters}
                onClick={() => table.resetColumnFilters()}
              >
                <FormattedMessage
                  id="button.action.clearAllFilters"
                  defaultMessage="Clear all filters"
                />
              </Button>
            )}
          </div>,
          containerForActions
        )}
      <table
        className={clsx(
          styles.table,
          stickyHeader && styles.sticky,
          tableClass
        )}
        style={tableStyle}
      >
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <React.Fragment key={headerGroup.id}>
              <tr>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    <div className={styles.headerCell}>
                      {header.isPlaceholder ? null : (
                        <span>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </span>
                      )}
                      {header.column.getCanFilter() && (
                        <ExcelFilter column={header.column} table={table} />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              title={
                'rowTitle' in row.original && row.original.rowTitle
                  ? row.original.rowTitle
                  : ''
              }
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          {table.getRowModel().rows.length === 0 && (
            <tr>
              <td colSpan={table.getAllColumns().length}>
                <FormattedMessage
                  id="message.noDataFound"
                  defaultMessage="No data found"
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default SimpleTable;
