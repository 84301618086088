import React, { useMemo } from 'react';
import { IndicatorDetail } from 'AppTypes';
import { uniq } from 'lodash';
import styles from './CompareNonMRLMeasures.module.scss';
import { FormattedMessage } from 'react-intl';
import { sanitize } from 'dompurify';
import Truncate from 'components/Truncate';

type Props = {
  indicators1: IndicatorDetail[];
  indicators2: IndicatorDetail[];
  displaySource: boolean;
  toggleState1: Record<string, boolean | undefined>;
  toggleState2: Record<string, boolean | undefined>;
  updateToggleState1: (value: boolean, index?: number | string) => void;
  updateToggleState2: (value: boolean, index?: number | string) => void;
};

function normalizeIndicators(indicators: IndicatorDetail[]) {
  return indicators.reduce<Record<string, IndicatorDetail>>(
    (acc, curr) => ({
      ...acc,
      [curr.indicatorId]: curr,
    }),
    {}
  );
}

const CompareNonMRLMeasures = ({
  indicators1,
  indicators2,
  displaySource,
  toggleState1,
  toggleState2,
  updateToggleState1,
  updateToggleState2,
}: Props) => {
  const normalizedIndicators1 = useMemo(
    () => normalizeIndicators(indicators1),
    [indicators1]
  );

  const normalizedIndicators2 = useMemo(
    () => normalizeIndicators(indicators2),
    [indicators2]
  );

  const displayItems = useMemo(() => {
    const uniqKeys = uniq(
      indicators1
        .map((indicator) => indicator.indicatorId)
        .concat(indicators2.map((indicator) => indicator.indicatorId))
    ).sort();

    return uniqKeys.map((key) => ({
      key,
      title:
        normalizedIndicators1[key]?.indicatorLabel ||
        normalizedIndicators2[key]?.indicatorLabel,
      ind1: normalizedIndicators1[key],
      ind2: normalizedIndicators2[key],
    }));
  }, [indicators1, indicators2, normalizedIndicators1, normalizedIndicators2]);

  return displayItems?.length ? (
    <div className={styles.wrapper}>
      {displaySource ? (
        <div className={styles.details}>
          <div className={styles.item}>
            <div className={styles.title}>
              <FormattedMessage
                id="legislation.title"
                defaultMessage="Legislation title"
              />
            </div>
            <div className={styles.content}>
              <div className={styles.col}>
                {indicators1[0].legislationTitle}
              </div>
              <div className={styles.col}>
                {indicators2[0].legislationTitle}
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>
              <FormattedMessage
                id="legislation.summary"
                defaultMessage="Legislation summary"
              />
            </div>
            <div className={styles.content}>
              <div
                className={styles.col}
                dangerouslySetInnerHTML={{
                  __html: sanitize(indicators1[0].legislationTitle || ''),
                }}
              ></div>
              <div
                className={styles.col}
                dangerouslySetInnerHTML={{
                  __html: sanitize(indicators2[0].legislationTitle || ''),
                }}
              ></div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>
              <FormattedMessage
                id="legislation.validity"
                defaultMessage="Validity"
              />
            </div>
            <div className={styles.content}>
              <div className={styles.col}>
                {!indicators1[0].validityDate?.startsWith('9999') &&
                  indicators1[0].validityDate}
              </div>
              <div className={styles.col}>
                {!indicators2[0].validityDate?.startsWith('9999') &&
                  indicators2[0].validityDate}
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>
              <FormattedMessage
                id="legislation.affectedCountries"
                defaultMessage="Other countries affected"
              />
            </div>
            <div className={styles.content}>
              <div className={styles.col}>{indicators1[0].affectedCountry}</div>
              <div className={styles.col}>{indicators2[0].affectedCountry}</div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.title}>
              <FormattedMessage id="legislation.link" defaultMessage="Link" />
            </div>
            <div className={styles.content}>
              <div className={styles.col}>
                {indicators1[0].webLink && (
                  <a
                    href={indicators1[0].webLink}
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      id="legislation.link"
                      defaultMessage="Link"
                    />
                  </a>
                )}
              </div>
              <div className={styles.col}>
                {indicators2[0].webLink && (
                  <a
                    href={indicators2[0].webLink}
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      id="legislation.link"
                      defaultMessage="Link"
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        displayItems.map((item, index) => (
          <div className={styles.item} key={item.key}>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.content}>
              <div className={styles.col}>
                <Truncate
                  key={`${item.key}-${index}-1`}
                  toggleKeyProp={item.ind1?.indicatorId}
                  defaultExpanded={toggleState1[item.ind1?.indicatorId]}
                  onToggle={updateToggleState1}
                >
                  {sanitize(item.ind1?.indicatorExplanation || '')}
                </Truncate>
              </div>
              <div className={styles.col}>
                <Truncate
                  key={`${item.key}-${index}-2`}
                  toggleKeyProp={item.ind2?.indicatorId}
                  defaultExpanded={toggleState2[item.ind2?.indicatorId]}
                  onToggle={updateToggleState2}
                >
                  {sanitize(item.ind2?.indicatorExplanation || '')}
                </Truncate>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  ) : null;
};

export default React.memo(CompareNonMRLMeasures);
