import { CompareResultDataItem } from 'AppTypes';
import Loading from 'components/Loading';
import { useGetCompareParams } from 'hooks/useGetCompareParams';
import { useGetCompareProductMeasureGroupDetail } from 'hooks/useMeasureGroup';
import MeasureGroupDetailsCompareContent from 'modules/compare/components/MeasureGroupDetailsCompareContent';
import { CompareType } from 'modules/constants';
import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  measureGroup: CompareResultDataItem;
};

const MeasureGroupDetailsCompare = ({ measureGroup }: Props) => {
  const { type, partner, reporterId, productId1, productId2, productId } =
    useGetCompareParams();

  const response1 = useGetCompareProductMeasureGroupDetail({
    measureGroup: {
      ...measureGroup,
      measureCount: measureGroup.measureCount1,
    },
    partner,
    reporterId:
      type === CompareType.PRODUCTS
        ? reporterId
        : reporterId?.split(',')?.[0] || '',
    productId: type === CompareType.PRODUCTS ? productId1 : productId,
  });

  const response2 = useGetCompareProductMeasureGroupDetail({
    measureGroup: {
      ...measureGroup,
      measureCount: measureGroup.measureCount2,
    },
    partner,
    reporterId:
      type === CompareType.PRODUCTS
        ? reporterId
        : reporterId?.split(',')?.[1] || '',
    productId: type === CompareType.PRODUCTS ? productId2 : productId,
  });

  return (
    <Loading
      isLoading={response1.isLoading || response2.isLoading}
      isSuccess={response1.isSuccess && response2.isSuccess}
      isError={response1.isError && response2.isError}
      loadingWrapperClassName="p-3 w-100 justify-content-center"
    >
      {response1.data?.length && response2.data?.length ? (
        <MeasureGroupDetailsCompareContent
          indicators1={response1.data}
          indicators2={response2.data}
        />
      ) : (
        <FormattedMessage
          id="message.noDataFound"
          defaultMessage="No data found"
        />
      )}
    </Loading>
  );
};

export default React.memo(MeasureGroupDetailsCompare);
