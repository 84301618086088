import React from 'react';
import { IndicatorDetail } from 'AppTypes';
import { FormattedMessage } from 'react-intl';
import styles from './SourceLegislation.module.scss';
import * as DOMPurify from 'dompurify';
import clsx from 'clsx';

type Props = {
  measure: IndicatorDetail;
  isSelfAssessModule?: boolean;
};

const SourceLegislation = ({ measure, isSelfAssessModule }: Props) => {
  return (
    <div
      className={clsx(
        styles.measureDetail,
        isSelfAssessModule && styles.selfAssess
      )}
    >
      <dl className={styles.details}>
        <dt>
          <FormattedMessage
            id="legislation.title"
            defaultMessage="Legislation title"
          />
        </dt>
        <dd>{measure.legislationTitle}</dd>
        <dt>
          <FormattedMessage
            id="legislation.summary"
            defaultMessage="Legislation summary"
          />
        </dt>
        <dd
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(measure.legislationSummary || ''),
          }}
        ></dd>
        <dt>
          <FormattedMessage
            id="legislation.validity"
            defaultMessage="Validity"
          />
        </dt>
        <dd>
          {!measure.validityDate?.startsWith('9999') && measure.validityDate}
        </dd>
        <dt>
          <FormattedMessage
            id="legislation.affectedCountries"
            defaultMessage="Other countries affected"
          />
        </dt>
        <dd>{measure.affectedCountry}</dd>
        <dt>
          <FormattedMessage id="legislation.link" defaultMessage="Link" />
        </dt>
        <dd>
          {measure.webLink && (
            <a
              href={measure.webLink}
              target="__blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="legislation.link" defaultMessage="Link" />
            </a>
          )}
        </dd>
      </dl>
    </div>
  );
};

export default SourceLegislation;
