import React from 'react';
import clsx from 'clsx';
import styles from './ProgressBar.module.scss';
import {
  ProgressBar as BsProgressBar,
  ProgressBarProps,
} from 'react-bootstrap';

type Props = {
  wrapperClassName?: string;
  progressClassName?: string;
  labelClassName?: string;
  hasLabel?: boolean;
  label?: string;
  variant?: 'inverted' | 'info' | 'orange';
} & ProgressBarProps;

let PROGRESS_ID = 0;

const ProgressBar = ({
  wrapperClassName,
  progressClassName,
  labelClassName,
  hasLabel = true,
  label,
  variant,
  ...progressProps
}: Props) => {
  const id = `progress-${PROGRESS_ID++}`;

  return (
    <div
      className={clsx({
        [styles.progressWrapper]: true,
        wrapperClassName,
        [styles.inverted]: variant === 'inverted',
        [styles.info]: variant === 'info',
        [styles.orange]: variant === 'orange',
      })}
    >
      <BsProgressBar
        id={id}
        label={label ?? progressProps.now}
        visuallyHidden
        {...progressProps}
        className={clsx(styles.progress, progressClassName)}
      />
      {hasLabel && (
        <label htmlFor={id} className={labelClassName}>
          {label ?? progressProps.now ?? 0}
        </label>
      )}
    </div>
  );
};

export default React.memo(ProgressBar);
