import React, { useContext } from 'react';
import { MeasureFilterViewContext } from 'modules/app/MeasureFilterViewContext';
import CompareProductHeader from './CompareProductHeader';
import CommonMeasureTypes from '../components/CommonMeasureTypes';
import NotCommonMeasureTypes from '../components/NotCommonMeasureTypes';
import OtherMeasureTypes from 'modules/compare/components/OtherMeasureTypes';
import { CompareTwoItemsResult } from 'AppTypes';

type Props = {
  isCompareProducts?: boolean;
  data: CompareTwoItemsResult;
};

const CompareCore = (props: Props) => {
  const { isCompareProducts, data } = props;
  const { filterView } = useContext(MeasureFilterViewContext);

  return (
    <div>
      <CompareProductHeader
        title1={data.displayName1}
        title2={data.displayName2}
        measureCount1={data.totalMeasures1}
        measureCount2={data.totalMeasures2}
        flag1={isCompareProducts ? null : data.countryIsoCode1}
        flag2={isCompareProducts ? null : data.countryIsoCode2}
      />
      <CommonMeasureTypes
        data={props.data.compareDataByGroupingOption[filterView]?.commonType}
      />
      <NotCommonMeasureTypes
        title1={data.displayName1}
        title2={data.displayName2}
        flag1={isCompareProducts ? null : data.countryIsoCode1}
        flag2={isCompareProducts ? null : data.countryIsoCode2}
        data={props.data.compareDataByGroupingOption[filterView]?.notCommonType}
      />
      <OtherMeasureTypes
        data={
          props.data.compareDataByGroupingOption[filterView]
            ?.measureGroupsOfOtherType || []
        }
      />
    </div>
  );
};

export default CompareCore;
