import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  CompareCountryItemResponse,
  CompareIndicatorResponse,
} from 'ApiServices';
import {
  CompareCountriesModel,
  CompareProductsModel,
  CompareTwoItemsResult,
  CompareCountriesIndicatorModel,
  CompareCountryNtmCodeLevel,
  CompareIndicatorResult,
  CompareCountriesIndicatorResult,
  DownloadCompareDataModel,
} from 'AppTypes';
import config from 'config';
import { groupBy, uniqBy } from 'lodash';
import { createProductComparison } from 'services/data/compare-products';

export const compareApi = createApi({
  reducerPath: 'compareApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiPrimary}/v1/compare`,
  }),
  endpoints: (builder) => ({
    compareProducts: builder.query<CompareTwoItemsResult, CompareProductsModel>(
      {
        query: (body) => ({
          method: 'post',
          url: 'get-product-compare',
          body: {
            partner: body.partner,
            reporter: body.reporterId,
            productCodeOne: body.productId1,
            productCodeTwo: body.productId2,
          },
        }),
        transformResponse: createProductComparison,
      }
    ),
    compareCountriesOverview: builder.query<
      CompareCountryItemResponse[],
      CompareCountriesModel
    >({
      query: (body) => ({
        method: 'post',
        url: 'get-ntm-data',
        body: {
          toCountries: body.reporterId.join(','),
          fromCountry: body.partner,
          productCode: body.productId,
        },
      }),
    }),
    compareCountriesIndicator: builder.query<
      CompareCountriesIndicatorResult<CompareIndicatorResult>,
      CompareCountriesIndicatorModel
    >({
      query: (body) => ({
        method: 'post',
        url: 'get-indicator-data',
        body: {
          toCountries: body.reporterId.join(','),
          fromCountry: body.partner,
          productCode: body.productId,
          ntmCode: body.ntmCode,
        },
      }),
      transformResponse: (response: Array<CompareIndicatorResponse>) => {
        const allMeasuresGroupedByReporterId = groupBy(
          uniqBy(
            response.flatMap((item) => item.measures),
            (item) => item.measureid
          ),
          (item) => item.Reporter
        );

        return {
          qtyOfMeasuresByCountryCode: Object.entries(
            allMeasuresGroupedByReporterId
          ).reduce(
            (acc, [countryCode, indicators]) => ({
              ...acc,
              [countryCode]: indicators.length,
            }),
            {}
          ),
          indicators: response
            .map<CompareCountryNtmCodeLevel<CompareIndicatorResult>>(
              (item) => ({
                ...item,
                rowHeader: item.indicatorLabel,
                indicatorId: item.indicatorId,
                measures: item.measures.map((measure) => ({
                  rowId: measure.RowId,
                  ntmCode: measure.ntmcode,
                  partner: measure.Partner,
                  reporterId: measure.Reporter,
                  measureId: measure.measureid,
                  indicatorId: measure.indicatorid,
                  indicatorLabel: measure.IndicatorLabel,
                  indicatorExplanation: measure.indicatorexplanation,
                  legislationTitle: measure.legislationtitle,
                  legislationSummary: measure.LegislationSummary,
                  validityDate: measure.ValidityDate,
                  webLink: measure.weblink,
                  affectedCountry: measure.affectedcountry,
                  celexNo: measure.celexno,
                })),
                qtyOfCountriesWithApplicableMeasures: item.measureCount.filter(
                  (x) => x.count
                ).length,
                ...item.measureCount.reduce<Record<string, number>>(
                  (acc, curr) => ({
                    ...acc,
                    [curr.countryId]: curr.count,
                  }),
                  {}
                ),
              })
            )
            .sort((a, b) =>
              a.qtyOfCountriesWithApplicableMeasures ===
              b.qtyOfCountriesWithApplicableMeasures
                ? a.indicatorLabel?.localeCompare(b.indicatorLabel)
                : b.qtyOfCountriesWithApplicableMeasures -
                  a.qtyOfCountriesWithApplicableMeasures
            ),
        };
      },
    }),
  }),
});

export function fetchCompareDataFile(params: DownloadCompareDataModel) {
  return fetch(`${config.apiPrimary}/v1/compare/get-compare-report`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((response) => response.blob());
}

export const {
  useCompareProductsQuery,
  useCompareCountriesOverviewQuery,
  useCompareCountriesIndicatorQuery,
} = compareApi;
