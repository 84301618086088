import { CompareCountryItemResponse } from 'ApiServices';
import { BaseCompareResultDataItem, CompareCountryFirstLevel } from 'AppTypes';
import { groupBy } from 'lodash';
import {
  MeasureFilterAndMeasureTypeMapping,
  MeasureFilterType,
  MeasureType,
  MeasureTypeDetails,
} from 'modules/constants';
import { getStandardMeasureCode } from 'utils/helpers';

const baseKeys = [
  'groupOption',
  'ntmCode',
  'shortTitle',
  'measureType',
  'partner',
  'reporter',
  'isCommon',
];

function createTableRows(
  measureFilterType: MeasureFilterType,
  measureGroups: BaseCompareResultDataItem[]
) {
  const measureGroupsGroupedByMeasureType = groupBy(
    measureGroups,
    'measureType'
  );

  const measureTypesByFilterType =
    MeasureFilterAndMeasureTypeMapping[measureFilterType];

  return measureTypesByFilterType
    .filter(
      (type) =>
        type in measureGroupsGroupedByMeasureType && type !== MeasureType.OTHER
    )
    .map<CompareCountryFirstLevel>((type) => ({
      rowHeader: MeasureTypeDetails[type].title,
      subRows: measureGroupsGroupedByMeasureType[type]
        .map((item) => ({
          ...item,
          rowHeader: item.shortTitle,
          ntmCode: item.ntmCode,
          qtyOfCountriesWithApplicableMeasures: Object.entries(item)
            .filter(([key]) => !baseKeys.includes(key))
            .reduce(
              (acc, [key, value]) =>
                typeof value === 'number' && value > 0 ? acc + 1 : acc,
              0
            ),
        }))
        .sort((a, b) => {
          if (
            a.qtyOfCountriesWithApplicableMeasures ===
            b.qtyOfCountriesWithApplicableMeasures
          ) {
            return a.rowHeader
              ?.toString()
              .localeCompare(b.rowHeader?.toString());
          }

          return a.qtyOfCountriesWithApplicableMeasures <
            b.qtyOfCountriesWithApplicableMeasures
            ? 1
            : -1;
        }),
    }));
}

export function createMultipleCountriesComparison(
  items: CompareCountryItemResponse[],
  countryCodes: string[]
): Record<MeasureFilterType, CompareCountryFirstLevel[]> {
  const compareData: BaseCompareResultDataItem[] =
    items?.map((item) => {
      const {
        groupOption,
        isCommon,
        measureType,
        ntmCode,
        partner,
        shortTitle,
        countryDataList,
      } = item;

      return {
        ...countryDataList.reduce<Record<string, number>>(
          (acc, curr) => ({
            ...acc,
            [curr.countryId]: curr.count,
          }),
          {}
        ),
        countryDataList,
        groupOption: groupOption,
        ntmCode: getStandardMeasureCode(ntmCode),
        partner: partner,
        reporter: '',
        shortTitle: shortTitle,
        isCommon: isCommon,
        measureType: measureType,
      };
    }) || [];

  const measureGroupsGroupedByGroupingOption = groupBy(
    compareData,
    'groupOption'
  );

  return Object.entries(measureGroupsGroupedByGroupingOption).reduce<
    Record<MeasureFilterType, CompareCountryFirstLevel[]>
  >(
    (acc, [measureFilterType, measureGroups]) => ({
      ...acc,
      [measureFilterType]: createTableRows(
        measureFilterType as unknown as MeasureFilterType,
        measureGroups
      ),
    }),
    {} as Record<MeasureFilterType, CompareCountryFirstLevel[]>
  );
}
