import { skipToken } from '@reduxjs/toolkit/query/react';
import { SelectOption } from 'AppTypes';
import { IndicatorDetail, MeasureGroupOverview } from 'AppTypes';
import { Dictionary, groupBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { SingleValue } from 'react-select';
import { useGetMeasureDataQuery } from 'services/measures';
import { isTextExceedWordLimit } from 'utils/helpers';
import { useGetSearchParams } from './useGetSearchParams';

export const ALL_MEASURES_VALUE = 'ALL_MEASURES';

export function useGetSearchMeasureGroupDetail(
  measureGroup: MeasureGroupOverview
) {
  const { partner, reporterId, productId } = useGetSearchParams();

  const params = useMemo(() => {
    if (partner && productId && reporterId && measureGroup.ntmCode) {
      return {
        partner,
        reporterId,
        productId,
        ntmCode: measureGroup.ntmCode,
      };
    }

    return undefined;
  }, [partner, productId, reporterId, measureGroup.ntmCode]);

  const response = useGetMeasureDataQuery(params || skipToken);

  return response;
}

export function useGetCompareProductMeasureGroupDetail({
  measureGroup,
  productId,
  partner,
  reporterId,
}: {
  measureGroup: MeasureGroupOverview;
  productId?: string;
  partner?: string;
  reporterId?: string;
}) {
  const params = useMemo(() => {
    if (partner && productId && reporterId && measureGroup.ntmCode) {
      return {
        partner,
        reporterId,
        productId,
        ntmCode: measureGroup.ntmCode,
      };
    }

    return undefined;
  }, [partner, productId, reporterId, measureGroup.ntmCode]);

  const response = useGetMeasureDataQuery(params || skipToken);

  return response;
}

export function useMeasureGroup(indicators?: IndicatorDetail[]) {
  const intl = useIntl();

  const indicatorsGroupedByMeasures = useMemo(() => {
    return groupBy(indicators, (indicator) => indicator.measureId);
  }, [indicators]);

  const measuresToSelect = useMemo((): SelectOption[] => {
    const measures = Object.keys(indicatorsGroupedByMeasures)
      .sort()
      .map((measureId, index) => ({
        value: measureId,
        label: `${intl.formatMessage({
          id: 'measures.prefix',
          defaultMessage: 'Measure',
        })} ${index + 1}`,
      }));

    if (measures.length > 1) {
      measures.unshift({
        value: ALL_MEASURES_VALUE,
        label: intl.formatMessage({
          id: 'measures.selectAll',
          defaultMessage: 'All Measures',
        }),
      });
    }

    return measures;
  }, [indicatorsGroupedByMeasures, intl]);

  return {
    measuresToSelect,
    indicatorsGroupedByMeasures,
  };
}

export function useIndicatorToggle(
  indicators?: IndicatorDetail[],
  selectedMeasure?: SingleValue<SelectOption>,
  indicatorsGroupedByMeasures?: Dictionary<IndicatorDetail[]>
) {
  const isSelectedAllMeasures = useMemo(
    () => selectedMeasure?.value === ALL_MEASURES_VALUE,
    [selectedMeasure?.value]
  );

  const selectedIndicators = useMemo(() => {
    if (isSelectedAllMeasures) {
      return indicators ?? [];
    }

    return indicatorsGroupedByMeasures?.[selectedMeasure?.value] ?? [];
  }, [
    indicators,
    indicatorsGroupedByMeasures,
    isSelectedAllMeasures,
    selectedMeasure?.value,
  ]);

  const [isDisplaySource, setIsDisplaySource] = useState<boolean>(false);

  const onToggleSource = useCallback(
    (newValue?: boolean | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (newValue != null && typeof newValue === 'boolean') {
        setIsDisplaySource(newValue);
        return;
      }

      setIsDisplaySource((value) => !value);
    },
    []
  );

  useEffect(() => {
    setIsDisplaySource(false);
  }, [selectedMeasure]);

  const isShowToggleBodyActions = useMemo(() => {
    return selectedIndicators.some((indicator) =>
      isTextExceedWordLimit(indicator.indicatorExplanation || '')
    );
  }, [selectedIndicators]);

  const [toggleBodyState, setToggleBodyState] = useState<
    Record<string, boolean | undefined>
  >({});

  useEffect(() => {
    const state: Record<string, boolean | undefined> = {};

    if (isShowToggleBodyActions) {
      for (const [, indicator] of Object.entries(selectedIndicators)) {
        state[indicator.indicatorId] = isTextExceedWordLimit(
          indicator.indicatorExplanation || ''
        )
          ? false
          : undefined;
      }
    }

    setToggleBodyState(state);
  }, [isShowToggleBodyActions, selectedIndicators]);

  const updateToggleBodyState = useCallback(
    (value: boolean, index?: number | string) => {
      if (index != null) {
        setToggleBodyState((state) => ({
          ...state,
          [index]: value,
        }));
      }
    },
    []
  );

  const showExpandAll = useMemo(
    () =>
      Object.values(toggleBodyState)
        .filter((value) => value != null)
        .some((value) => !value),
    [toggleBodyState]
  );

  const showCollapseAll = useMemo(
    () =>
      Object.values(toggleBodyState)
        .filter((value) => value != null)
        .some((value) => value),
    [toggleBodyState]
  );

  const onClickExpandAll = useCallback(() => {
    setToggleBodyState((toggleState) => {
      const newToggleState = { ...toggleState };
      for (const [key, value] of Object.entries(newToggleState)) {
        if (value != null) {
          newToggleState[key] = true;
        }
      }
      return newToggleState;
    });
  }, []);

  const onClickCollapseAll = useCallback(() => {
    setToggleBodyState((toggleState) => {
      const newToggleState = { ...toggleState };
      for (const [key, value] of Object.entries(newToggleState)) {
        if (value != null) {
          newToggleState[key] = false;
        }
      }
      return newToggleState;
    });
  }, []);

  return {
    isSelectedAllMeasures,
    selectedIndicators,
    toggleBodyState,
    showExpandAll,
    showCollapseAll,
    onClickExpandAll,
    onClickCollapseAll,
    updateToggleBodyState,
    isDisplaySource,
    onToggleSource,
    isShowToggleBodyActions,
  };
}
