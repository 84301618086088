import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { CompareCountryFirstLevel } from 'AppTypes';
import { ShowMeasureNumberContext } from 'modules/compare/countries/ShowMeasureNumberContext';
import { useCallback, useContext, useMemo, useState } from 'react';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as CheckmarkThinIcon } from 'assets/icons/checkmark-thin.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import MeasureFilterView from 'modules/app/MeasureFilterView';
import Button from 'components/Button';
import styles from './CompareMultipleOptionsTable.module.scss';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { getMeasureIcon } from 'assets/remote';
import { ExpandIcon } from 'components/ExpandIcon';
import {
  useGetFromCountriesById,
  useGetToCountriesById,
} from 'services/countries';
import { getFlagCode } from 'utils/flags';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { useGetCompareCountriesParams } from 'hooks/useGetCompareParams';

const columnHelper = createColumnHelper<CompareCountryFirstLevel>();

const COLUMNS_COUNT_TO_DISPLAY = 5;
const MAX_EXPANDABLE_ROW_DEPTH = 3;

export enum COLUMN_ID {
  ROW_HEADER = 'rowHeader',
  GO_PREVIOUS = 'goPrevious',
  GO_NEXT = 'goNext',
}

export function useMultipleOptionsTable(data: CompareCountryFirstLevel[]) {
  const { reporterId: tableColumnKeys = [] } = useGetCompareCountriesParams();
  const showMeasureNumber = useContext(ShowMeasureNumberContext);
  const [beginColumnIndex, setBeginColumnIndex] = useState<number>(0);
  const { data: toCountriesById } = useGetToCountriesById();
  const { data: fromCountriesById } = useGetFromCountriesById();

  const countriesById = useMemo(
    () => ({
      ...toCountriesById,
      ...fromCountriesById,
    }),
    [fromCountriesById, toCountriesById]
  );

  const intl = useIntl();

  const maxBeginColumnIndex =
    tableColumnKeys.length - COLUMNS_COUNT_TO_DISPLAY - 1 > 0
      ? tableColumnKeys.length - COLUMNS_COUNT_TO_DISPLAY - 1
      : 0;

  const onNavigateColumnLeft = useCallback(() => {
    setBeginColumnIndex((index) => (index > 0 ? index - 1 : index));
  }, []);

  const onNavigateColumnRight = useCallback(() => {
    setBeginColumnIndex((index) =>
      index <= maxBeginColumnIndex ? index + 1 : index
    );
  }, [maxBeginColumnIndex]);

  const countryCodes = useMemo(() => {
    const startIdx = beginColumnIndex;
    const endIdx =
      beginColumnIndex + COLUMNS_COUNT_TO_DISPLAY < tableColumnKeys.length
        ? beginColumnIndex + COLUMNS_COUNT_TO_DISPLAY
        : undefined;
    return tableColumnKeys.slice(startIdx, endIdx);
  }, [beginColumnIndex, tableColumnKeys]);

  const columns: ColumnDef<
    CompareCountryFirstLevel,
    string | number | CompareCountryFirstLevel[]
  >[] = useMemo(
    () => [
      {
        header: '',
        accessorKey: 'rowHeader',
        cell: ({ row, getValue }) => {
          if (row.depth === 0) {
            return (
              <div className={styles.sectionTitle}>
                <span>{getValue() as string}</span>
                <MeasureFilterView />
              </div>
            );
          }

          if (row.depth < MAX_EXPANDABLE_ROW_DEPTH) {
            return (
              <button
                type="button"
                onClick={row.getToggleExpandedHandler()}
                className={clsx(
                  styles.expandButton,
                  row.depth === 1 && row.getIsExpanded() && styles.expanded
                )}
              >
                <>
                  <div className={styles.summary}>
                    <>
                      {row.depth === 1 && row.original.ntmCode && (
                        <div className={styles.image}>
                          <svg
                            data-src={getMeasureIcon(row.original.ntmCode)}
                          />
                        </div>
                      )}
                      {getValue()}
                    </>
                  </div>
                  <div className={styles.icon}>
                    <ExpandIcon
                      isCollapsed={!row.getIsExpanded()}
                      isInverted={row.depth === 1}
                      size="sm"
                      useFillVariantWhenExpanded={row.depth === 2}
                    />
                  </div>
                </>
              </button>
            );
          }
        },
      },
      {
        header: () => (
          <Button
            className={clsx(
              styles.navigateBtn,
              beginColumnIndex <= 0 && 'invisible'
            )}
            onClick={onNavigateColumnLeft}
            disabled={beginColumnIndex <= 0}
            variant="secondary"
            title={intl.formatMessage({
              id: 'button.action.previous',
              defaultMessage: 'Previous',
            })}
          >
            <ChevronLeft />
          </Button>
        ),
        id: 'goPrevious',
      },
      ...countryCodes
        .map((countryCode) => countriesById[countryCode])
        .filter(Boolean)
        .sort((a, b) => a?.name?.localeCompare(b?.name))
        .map((country) =>
          columnHelper.accessor(country?.code || '', {
            header: () => {
              return country ? (
                <div className={styles.columnHeader}>
                  <span
                    className={clsx(
                      `fi fi-${getFlagCode(country.iso2)}`,
                      styles.flag
                    )}
                    aria-hidden={true}
                  />
                  <div>{country.name}</div>
                </div>
              ) : null;
            },
            cell: (cell) => {
              const measureNumber = cell.getValue() as number;

              if (cell.row.depth === 0) {
                return null;
              }

              if (showMeasureNumber) {
                return (
                  <span
                    className={clsx(
                      styles.measureNumber,
                      styles[`rowLevel${cell.row.depth}`],
                      measureNumber > 0 && styles.positiveNumber
                    )}
                  >
                    {measureNumber}
                  </span>
                );
              }

              if (cell.row.depth === 1) {
                return measureNumber > 0 ? <CheckmarkIcon /> : <CloseIcon />;
              }

              return measureNumber > 0 ? (
                <CheckmarkThinIcon />
              ) : (
                <span>
                  <FormattedMessage
                    id="compare.message.NA"
                    defaultMessage="NA"
                  />
                </span>
              );
            },
          })
        ),
      {
        header: () => (
          <Button
            className={clsx(
              styles.navigateBtn,
              (beginColumnIndex > maxBeginColumnIndex ||
                (maxBeginColumnIndex === 0 &&
                  tableColumnKeys.length <= COLUMNS_COUNT_TO_DISPLAY)) &&
                'invisible'
            )}
            onClick={onNavigateColumnRight}
            variant="secondary"
            title={intl.formatMessage({
              id: 'button.action.next',
              defaultMessage: 'Next',
            })}
          >
            <ChevronRight />
          </Button>
        ),
        id: 'goNext',
      },
    ],
    [
      beginColumnIndex,
      countriesById,
      countryCodes,
      intl,
      maxBeginColumnIndex,
      onNavigateColumnLeft,
      onNavigateColumnRight,
      showMeasureNumber,
      tableColumnKeys.length,
    ]
  );

  return {
    columns,
  };
}
