import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import styles from './ProfileManagement.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import Card from 'components/Card';
import { Input } from 'components/Input';
import { useDebounce } from 'use-debounce';
import ProfileManagementItem from './ProfileManagementItem';
import { useGetCategorizedProfilesQuery } from 'services/selfAssess';
import Loading from 'components/Loading';
import { AssessProfile } from 'AppTypes';
import { Accordion } from 'react-bootstrap';

type Props = {
  className?: string;
};

const filterProfileFn = (value: string) => (item: AssessProfile) =>
  item.profileName.toLowerCase().includes(value);

const ProfileManagement = ({ className }: Props) => {
  const response = useGetCategorizedProfilesQuery();
  const { data } = response;
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearchValue] = useDebounce(searchValue, 300);

  const profiles = useMemo(() => {
    const value = debouncedSearchValue.trim().toLowerCase();
    return value
      ? {
          emptyProfiles:
            data.emptyProfiles.filter(filterProfileFn(value)) ?? [],
          nonEmptyProfiles:
            data.nonEmptyProfiles.filter(filterProfileFn(value)) ?? [],
        }
      : data;
  }, [data, debouncedSearchValue]);

  return (
    <section className={clsx(styles.wrapper, className)}>
      <Card
        hasCornerCut={false}
        className={clsx(styles.headerCard, styles.card)}
        wrapperClassName={styles.headerCardWrapper}
      >
        <h2 className={styles.title}>
          <FormattedMessage
            id="selfAssess.message.savedProfiles"
            defaultMessage="Resume a saved assessment profile"
          />
        </h2>
        <Input
          canClear
          fullBorder
          value={searchValue}
          onValueChange={setSearchValue}
          placeholder={intl.formatMessage({
            id: 'selfAssess.form.search',
            defaultMessage: 'Search',
          })}
        />
      </Card>
      <Card
        hasCornerCut={false}
        className={clsx(styles.profileCard, styles.card)}
      >
        <Loading
          response={response}
          loadingWrapperClassName="justify-content-center p-3"
        >
          {Array.isArray(profiles?.nonEmptyProfiles) &&
            profiles?.nonEmptyProfiles.length > 0 && (
              <div className={styles.profileList}>
                {profiles?.nonEmptyProfiles.map((profile, index) => (
                  <ProfileManagementItem
                    key={profile.assessmentProfileId}
                    profile={profile}
                    index={index}
                    showComplianceRate
                  />
                ))}
              </div>
            )}
        </Loading>
      </Card>
      {Array.isArray(profiles?.emptyProfiles) &&
        profiles?.emptyProfiles.length > 0 && (
          <Accordion defaultActiveKey="0" flush className="mt-5">
            <Accordion.Item eventKey="0" className="first-level">
              <Accordion.Header>
                <span className="text-uppercase">
                  <FormattedMessage
                    id="selfAssess.message.emptyProfiles"
                    defaultMessage="Empty profiles"
                  />
                </span>
              </Accordion.Header>
              <Accordion.Body className="p-0">
                <Card
                  hasCornerCut={false}
                  className={clsx(styles.profileCard, styles.card)}
                >
                  <div className={styles.profileList}>
                    {profiles?.emptyProfiles.map((profile, index) => (
                      <ProfileManagementItem
                        key={profile.assessmentProfileId}
                        profile={profile}
                        index={index}
                      />
                    ))}
                  </div>
                </Card>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
    </section>
  );
};

export default ProfileManagement;
