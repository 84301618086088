import { ItemWithMeasureCount, CompareResultDataItem } from 'AppTypes';
import clsx from 'clsx';
import SectionHeader from 'components/SectionHeader';
import CompareNTMAccordion from 'modules/compare/components/CompareNTMAccordion';
import MeasureCount from './MeasureCount';
import styles from './NotCommonMeasureTypeSection.module.scss';

type Props = {
  title: React.ReactNode;
  icon: React.ReactNode;
  measureCount1: number;
  measureCount2: number;
  data1: ItemWithMeasureCount<CompareResultDataItem>[];
  data2: ItemWithMeasureCount<CompareResultDataItem>[];
};

const NotCommonMeasureTypeSection = ({
  title,
  icon,
  measureCount1,
  measureCount2,
  data1,
  data2,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.sectionHeader}>
        <div className={styles.item}>
          <SectionHeader
            icon={icon}
            title={title}
            wrapperClassName={styles.header}
          >
            <MeasureCount measureCount={measureCount1} />
          </SectionHeader>
          <CompareNTMAccordion data={data1} />
        </div>
        <div className={styles.item}>
          <SectionHeader
            icon={icon}
            title={title}
            wrapperClassName={clsx(styles.header, styles.reverse)}
          >
            <MeasureCount measureCount={measureCount2} />
          </SectionHeader>
          <CompareNTMAccordion data={data2} isRightSide />
        </div>
      </div>
    </div>
  );
};

export default NotCommonMeasureTypeSection;
