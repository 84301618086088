import { useSearchParams } from 'react-router-dom';

export function useGetSearchParams() {
  const [searchParams] = useSearchParams();

  const data = {
    partner: searchParams.get('partner') || undefined,
    reporterId: searchParams.get('reporterId') || undefined,
    productId: searchParams.get('productId') || undefined,
  };

  return data;
}
