import React, { useEffect, useState } from 'react';
import ProgressBar from 'components/ProgressBar';
import styles from './SummaryAccordion.module.scss';
import { AssessProfileSummary } from 'ApiServices';
import { Accordion } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { getRoute } from 'utils/router';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import SectionHeader from 'components/SectionHeader';
import MeasureFilterView from 'modules/app/MeasureFilterView';
import { ReactComponent as Checkmark } from 'assets/icons/checkmark.svg';
import { useGetMeasureTypesInFilter } from 'modules/app/MeasureFilterViewContext';
import { useGetSelfAssessParams } from 'hooks/useGetSelfAssessParams';
import { groupBy } from 'lodash';
// import AssessmentStatus from 'modules/self-assess/profile/AssessmentStatus';

const SummaryByMeasure = ({
  name,
  percentage,
  isAssessed,
  className,
  variant,
  isComplianceView,
  isMeasureLevel,
}: {
  name: string;
  percentage: number;
  isAssessed: boolean;
  className?: string;
  variant?: 'inverted' | 'info' | 'orange';
  isComplianceView?: boolean;
  isMeasureLevel?: boolean;
}) => {
  return (
    <div className={clsx(styles.summaryRow, className)}>
      <div className="measureName">{name}</div>
      {!isComplianceView && (
        <ProgressBar
          now={percentage}
          label={`${Math.round(percentage)}%`}
          hasLabel
          variant={variant}
        />
      )}
      {isComplianceView && isAssessed && (
        <div className={styles.checkmark}>
          <Checkmark />
        </div>
      )}
    </div>
  );
};

const SummaryByMeasureGroup = ({
  accordionData,
  isComplianceView,
}: {
  accordionData: AssessProfileSummary;
  isComplianceView?: boolean;
}) => {
  const { profileId = '', ntmCode = '' } = useGetSelfAssessParams();
  const intl = useIntl();
  const [activeKey, setActiveKey] = useState<string[]>([ntmCode]);

  useEffect(() => {
    setActiveKey((keys) =>
      keys.includes(ntmCode) ? keys : keys.concat(ntmCode)
    );
  }, [ntmCode]);

  return (
    <Accordion
      alwaysOpen
      bsPrefix="accordion-summary"
      className={styles.accordionSummary}
      defaultActiveKey={[ntmCode]}
      activeKey={activeKey}
    >
      {accordionData?.map((measureGroup, index) => (
        <Accordion.Item eventKey={measureGroup.ntmCode} key={index}>
          <Accordion.Header
            className={clsx(
              styles.groupHeader,
              ntmCode === measureGroup.ntmCode && styles.active
            )}
            as="div"
            onClick={() => {
              setActiveKey((keys) =>
                keys.includes(measureGroup.ntmCode)
                  ? keys.filter((key) => key !== measureGroup.ntmCode)
                  : keys.concat(measureGroup.ntmCode)
              );
            }}
          >
            <SummaryByMeasure
              name={`${measureGroup.shortTitle} (${measureGroup.measureCount})`}
              percentage={measureGroup.ntmPercentage}
              isAssessed={measureGroup.isNtmAssessed}
              className={clsx(
                styles.container,
                isComplianceView && styles.collapsed
              )}
              isComplianceView={isComplianceView}
            />
          </Accordion.Header>
          <Accordion.Body>
            {measureGroup.measureSummary?.map((measure, index) => (
              <NavLink
                key={index}
                to={getRoute('fullSelfAssessmentProfileByQuery', {
                  profileId,
                  ntmCode: measureGroup.ntmCode,
                  measureId: measure.measureCode,
                })}
                replace={isComplianceView}
                className={styles.link}
              >
                <div className={styles.container}>
                  <SummaryByMeasure
                    name={`${intl.formatMessage({
                      id: 'measures.prefix',
                      defaultMessage: 'Measure',
                    })} ${index + 1}`}
                    percentage={measure.measurePercentage}
                    isAssessed={measure.isMeasureAssessed}
                    className={styles.children}
                    variant="info"
                    isComplianceView={isComplianceView}
                    isMeasureLevel
                  />
                </div>
              </NavLink>
            ))}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

const SummaryAccordion = ({
  accordionData,
  isComplianceView,
}: {
  accordionData: AssessProfileSummary;
  isComplianceView?: boolean;
}) => {
  const measureTypes = useGetMeasureTypesInFilter();
  const dataGroupedByMeasureType = groupBy(accordionData, 'measureType');

  return (
    <Accordion
      alwaysOpen
      defaultActiveKey={['0', '1', '2']}
      bsPrefix="accordion-summary"
      className={styles.rootAccordion}
    >
      {measureTypes.map((measureType, index) => {
        const data = dataGroupedByMeasureType[measureType.key];
        if (Array.isArray(data) && data.length > 0) {
          return (
            <Accordion.Item eventKey={`${index}`} key={index} className="mb-1">
              <div className={styles.rootHeader}>
                <Accordion.Button>
                  <SectionHeader
                    wrapperClassName="w-100"
                    title={measureType.title}
                    icon={measureType.icon}
                    iconClassName={styles.icon}
                  ></SectionHeader>
                </Accordion.Button>
                {!isComplianceView && (
                  <MeasureFilterView className={styles.sortingIcon} />
                )}
              </div>
              <Accordion.Body>
                <SummaryByMeasureGroup
                  accordionData={data}
                  isComplianceView={isComplianceView}
                />
              </Accordion.Body>
            </Accordion.Item>
          );
        }
        return null;
      })}
    </Accordion>
  );
};

export default SummaryAccordion;
