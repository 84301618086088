import React, { useContext } from 'react';
import { CMSFlatData, ExpandableItem } from 'AppTypes';
import clsx from 'clsx';
import { sanitize } from 'dompurify';
import { Accordion } from 'react-bootstrap';
import { CustomAccordionContext } from './CustomAccordionContext';

const CustomAccordionItem = ({
  data,
  index,
  level = 1,
}: {
  data: CMSFlatData<ExpandableItem>;
  index: number;
  level?: number;
}) => {
  const { flatData: value } = data;
  const { activeKeys, onClickItem, highlightLevel2 } = useContext(
    CustomAccordionContext
  );

  const itemKey = `${level}-${index}`;
  const hasContent =
    value.content ||
    value.contentBottom ||
    (Array.isArray(value.children) && value.children.length > 0);

  return (
    <Accordion.Item
      eventKey={itemKey}
      className={clsx({
        'first-level': level === 1,
        'no-content': !hasContent,
      })}
    >
      <Accordion.Header
        as="div"
        className={clsx({
          'first-level': level === 1,
          [`level-${level}`]: level,
          highlight: highlightLevel2 && level === 2,
        })}
        onClick={() => {
          if (hasContent) {
            onClickItem(itemKey);
          }
        }}
      >
        {value.titleIcon?.[0]?.url && (
          <div>
            <svg data-src={value.titleIcon?.[0]?.url} height="58" />
          </div>
        )}
        <span>{value.title}</span>
      </Accordion.Header>
      {hasContent && (
        <Accordion.Body
          className={clsx({
            'first-level': level === 1,
          })}
        >
          {value.content && (
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: sanitize(value.content),
              }}
            />
          )}

          {value.children && (
            <Accordion alwaysOpen activeKey={activeKeys}>
              {value.children.map((item, index) => (
                <CustomAccordionItem
                  data={item}
                  index={index}
                  key={index}
                  level={level + 1}
                />
              ))}
            </Accordion>
          )}

          {value.contentBottom && (
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: sanitize(value.contentBottom),
              }}
            />
          )}
        </Accordion.Body>
      )}
    </Accordion.Item>
  );
};

export default React.memo(CustomAccordionItem);
