import React from 'react';
import styles from './Footer.module.scss';
import clsx from 'clsx';
import { ReactComponent as ITCLogo } from 'assets/images/logo-itc.svg';
import Divider from 'components/Divider';
import { Helmet } from 'react-helmet-async';
import { useGetShellContent } from 'services/cms';
import { sanitize } from 'dompurify';

const Footer = () => {
  const { data } = useGetShellContent();
  const footerData = data?.footer;

  return (
    <>
      <Helmet>
        <script src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit" />
      </Helmet>
      <Divider />
      <footer className={styles.footer}>
        <div className={clsx('container-xxl')}>
          <div className="row">
            <div className="col-xl-6 row">
              <div className={clsx('col-4', styles.logo)}>
                <ITCLogo height={58} />
              </div>
              <div className={clsx('col-8', styles.contact)}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitize(footerData?.contact || ''),
                  }}
                />
                <div className={styles.socialIcons}>
                  {footerData?.socialLinks?.map((item, index) => (
                    <a
                      key={index}
                      href={item.link}
                      target="_blank"
                      className={styles.icon}
                      title={item.description}
                      rel="noreferrer"
                    >
                      <svg data-src={item.icon[0]?.url} />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className={clsx('col', styles.rightSection)}>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(footerData?.footerTranslation || ''),
                }}
              />
              <div id="gg-translate-element"></div>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitize(footerData?.footerDisclaimer || ''),
                }}
              ></div>
            </div>
            <div
              className={styles.copyright}
              dangerouslySetInnerHTML={{
                __html: sanitize(footerData?.footerCopyright || ''),
              }}
            />
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
