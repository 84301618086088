import { AssessProfile } from 'AppTypes';
import Button from 'components/Button';
import EditableInput from 'components/Input/EditableInput';
import ProgressBar from 'components/ProgressBar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { formattedNumberWithPrefix } from 'utils/helpers';
import { getRoute } from 'utils/router';
import styles from './ProfileManagement.module.scss';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import clsx from 'clsx';
import {
  useDeleteProfileMutation,
  useUpdateProfileNameMutation,
} from 'services/selfAssess';
import { getFlagCode } from 'utils/flags';
import {
  useGetFromCountriesById,
  useGetToCountriesById,
} from 'services/countries';
import { toast } from 'react-toastify';

type Props = {
  profile: AssessProfile;
  index: number;
  showComplianceRate?: boolean;
};

const ProfileManagementItem = ({
  profile,
  index,
  showComplianceRate,
}: Props) => {
  const { data: fromCountriesById } = useGetFromCountriesById();
  const { data: toCountriesById } = useGetToCountriesById();
  const intl = useIntl();
  const editableRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);
  const [show, setShow] = useState<boolean>(false);
  const [isEnter, setEnter] = useState<boolean>(false);

  const confirmBtnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (isEnter) {
      confirmBtnRef.current?.focus({ preventScroll: true });
    }
  }, [isEnter]);

  const hidePopover = useCallback(() => {
    setShow(false);
  }, []);

  const [deleteProfile, deleteResult] = useDeleteProfileMutation();
  const [updateProfile, { isLoading }] = useUpdateProfileNameMutation();

  useEffect(() => {
    if (
      !deleteResult.isUninitialized &&
      !deleteResult.isLoading &&
      deleteResult.isSuccess
    ) {
      toast.success(
        <>
          <strong>
            <FormattedMessage
              id="message.profileDeleteSucceed"
              defaultMessage="Profile deleted"
            />
          </strong>
        </>
      );
      deleteResult.reset();
    }
  }, [deleteResult]);

  const updateInputFn = useCallback(
    (value: any) => {
      return updateProfile({
        assessmentProfileId: profile.assessmentProfileId,
        profileName: value,
        productCode: profile.productCode,
        partner: profile.fromCountryCode,
        reporterId: profile.toCountryCode,
      }).unwrap();
    },
    [
      profile.assessmentProfileId,
      profile.fromCountryCode,
      profile.productCode,
      profile.toCountryCode,
      updateProfile,
    ]
  );

  return (
    <Link
      to={getRoute(
        'fullSelfAssessmentProfileById',
        profile.assessmentProfileId
      )}
      className={clsx(styles.profileItem)}
      onClick={(e) => {
        if (editableRef.current?.contains(e.target as Node)) {
          e.preventDefault();
        }
      }}
    >
      <div className={styles.index}>{formattedNumberWithPrefix(index + 1)}</div>
      <div className={styles.content}>
        <div className={styles.topRow}>
          <div>
            <div className={styles.profileName} ref={editableRef}>
              <EditableInput
                value={profile.profileName}
                disabled={isLoading}
                updateFn={updateInputFn}
              />
              <div className={clsx(styles.delete, isEnter && 'd-block')}>
                <Button
                  ref={btnRef}
                  type="button"
                  variant="light"
                  size="sm"
                  className={clsx(styles.deleteBtn)}
                  title={intl.formatMessage({
                    id: 'selfAssess.widget.deleteProfile',
                    defaultMessage: 'Delete profile',
                  })}
                  onClick={() => setShow(true)}
                >
                  <DeleteIcon />
                </Button>
                <Overlay
                  target={btnRef.current}
                  placement="bottom-start"
                  show={show}
                  rootClose
                  onHide={() => setShow(false)}
                  onEnter={() => setEnter(true)}
                  onExited={() => {
                    btnRef.current?.focus();
                    setEnter(false);
                  }}
                >
                  <Popover
                    className={styles.deleteConfirm}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Popover.Body className={styles.body}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: 'selfAssess.message.deleteConfirmation',
                            defaultMessage:
                              'Are your sure you want to <br /> delete this profile?',
                          }),
                        }}
                      />
                      <div className={styles.actions}>
                        <Button
                          hasCornerCut
                          uppercaseContent={false}
                          variant="light"
                          onClick={hidePopover}
                          ref={confirmBtnRef}
                        >
                          <FormattedMessage
                            id="button.action.no"
                            defaultMessage="No"
                          />
                        </Button>
                        <Button
                          hasCornerCut
                          uppercaseContent={false}
                          onClick={() => {
                            deleteProfile(profile.assessmentProfileId);
                            hidePopover();
                          }}
                        >
                          <FormattedMessage
                            id="button.action.yes"
                            defaultMessage="Yes"
                          />
                        </Button>
                      </div>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </div>
            </div>
            <div>
              <FormattedMessage
                id="selfAssess.widget.from"
                defaultMessage="From"
              />
              :{' '}
              <span className={styles.countryName}>
                {fromCountriesById[profile.fromCountryCode] && (
                  <span
                    className={`fi fi-${getFlagCode(
                      fromCountriesById[profile.fromCountryCode]?.iso2
                    )} me-1`}
                    aria-hidden={true}
                  />
                )}
                {profile.fromCountry ?? profile.fromCountryCode}
              </span>
            </div>
            <div>
              <FormattedMessage id="selfAssess.widget.to" defaultMessage="To" />
              :{' '}
              <span className={styles.countryName}>
                {toCountriesById[profile.toCountryCode] && (
                  <span
                    className={`fi fi-${getFlagCode(
                      toCountriesById[profile.toCountryCode]?.iso2
                    )} me-1`}
                    aria-hidden={true}
                  />
                )}
                {profile.toCountry ?? profile.toCountryCode}
              </span>
            </div>
            <div>
              <FormattedMessage
                id="selfAssess.widget.product"
                defaultMessage="Product"
              />
              : <span>{profile.productDisplayName}</span>
            </div>
          </div>
        </div>
        {showComplianceRate && (
          <div className={styles.complianceRate}>
            <span className={styles.complianceRateLabel}>
              <FormattedMessage
                id="selfAssess.widget.overallComplianceRate"
                defaultMessage="Overall compliance rate"
              />
            </span>
            <ProgressBar
              variant="orange"
              now={profile.overallPercentage}
              label={`${Math.round(profile.overallPercentage)}%`}
              labelClassName={styles.percentage}
            />
          </div>
        )}
      </div>
    </Link>
  );
};

export default React.memo(ProfileManagementItem);
