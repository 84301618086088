import { CompareProductsResponse } from 'ApiServices';
import {
  CompareTwoItemsResult,
  CompareResultCommonType,
  CompareResultDataItem,
  CompareResultNotCommonType,
  CompareResultNotCommonTypeDetail,
  CompareTwoItemsDataGroupedByMeasureFilterType,
} from 'AppTypes';
import { groupBy } from 'lodash';
import { MeasureFilterType, MeasureType } from 'modules/constants';
import { remapCompareResultItems } from 'services/data/helpers';
import { getStandardMeasureCode } from 'utils/helpers';

type TCreateComparison = {
  result: CompareTwoItemsDataGroupedByMeasureFilterType;
  items: CompareResultDataItem[];
};

function createProductComparisonByOtherMeasuresSection({
  result,
  items,
}: TCreateComparison) {
  // get items for Other measures section
  const measureGroupsOfOtherType = items.filter(
    (item) => item.measureType === MeasureType.OTHER
  );

  if (measureGroupsOfOtherType.length > 0) {
    const measureGroupsOfOtherMeasureForProductOne =
      measureGroupsOfOtherType.filter((item) => item.measureCount1 > 0);

    const measureGroupsOfOtherMeasureForProductTwo =
      measureGroupsOfOtherType.filter((item) => item.measureCount2 > 0);

    result.measureGroupsOfOtherType = [
      {
        totalCount: measureGroupsOfOtherMeasureForProductOne.reduce(
          (acc, curr) => acc + curr.measureCount1,
          0
        ),
        items: remapCompareResultItems(
          measureGroupsOfOtherMeasureForProductOne,
          'measureCount1'
        ),
      },
      {
        totalCount: measureGroupsOfOtherMeasureForProductTwo.reduce(
          (acc, curr) => acc + curr.measureCount2,
          0
        ),
        items: remapCompareResultItems(
          measureGroupsOfOtherMeasureForProductTwo,
          'measureCount2'
        ),
      },
    ];
  }
}

function createProductComparisonByCommonMeasureTypes({
  result,
  items,
}: TCreateComparison) {
  // get items for Common measure types section
  const measureGroupsOfCommonType = items.filter(
    (item) => item.isCommon && item.measureType !== MeasureType.OTHER
  );

  if (measureGroupsOfCommonType.length > 0) {
    const measureGroupsOfCommonTypeByMeasureType = groupBy(
      measureGroupsOfCommonType,
      'measureType'
    );

    result.commonType = Object.entries(
      measureGroupsOfCommonTypeByMeasureType
    ).reduce(
      (acc, [measureType, localItems]) => ({
        ...acc,
        [measureType]: {
          items: localItems,
          totalCount1: localItems.reduce(
            (acc, curr) => acc + curr.measureCount1,
            0
          ),
          totalCount2: localItems.reduce(
            (acc, curr) => acc + curr.measureCount2,
            0
          ),
        },
      }),
      {} as CompareResultCommonType
    );
  }
}

function createProductComparisonByNotCommonMeasureTypes({
  result,
  items,
}: TCreateComparison) {
  // get items for Not Common measure types section
  const measureGroupsOfNotCommonType = items.filter(
    (item) => !item.isCommon && item.measureType !== MeasureType.OTHER
  );

  if (measureGroupsOfNotCommonType.length > 0) {
    const measureGroupsOfNotCommonTypeByMeasureType = groupBy(
      measureGroupsOfNotCommonType,
      'measureType'
    );

    result.notCommonType = Object.entries(
      measureGroupsOfNotCommonTypeByMeasureType
    ).reduce<CompareResultNotCommonType>((acc, [measureType, localItems]) => {
      const twoSideItems: CompareResultNotCommonTypeDetail[] = [
        {
          totalCount: localItems.reduce(
            (acc, curr) => acc + curr.measureCount1,
            0
          ),
          items: remapCompareResultItems(
            localItems.filter((item) => item.measureCount1 > 0),
            'measureCount1'
          ),
        },
        {
          totalCount: localItems.reduce(
            (acc, curr) => acc + curr.measureCount2,
            0
          ),
          items: remapCompareResultItems(
            localItems.filter((item) => item.measureCount2 > 0),
            'measureCount2'
          ),
        },
      ];

      return {
        ...acc,
        [measureType as MeasureType]: twoSideItems,
      };
    }, {} as CompareResultNotCommonType);
  }
}

function createProductComparisonByGroupingOption(
  items: CompareResultDataItem[]
): CompareTwoItemsDataGroupedByMeasureFilterType {
  const sortedItems = items.sort((a, b) =>
    a.shortTitle.localeCompare(b.shortTitle)
  );

  const result: CompareTwoItemsDataGroupedByMeasureFilterType = {};

  createProductComparisonByOtherMeasuresSection({
    result,
    items: sortedItems,
  });

  createProductComparisonByCommonMeasureTypes({
    result,
    items: sortedItems,
  });

  createProductComparisonByNotCommonMeasureTypes({
    result,
    items: sortedItems,
  });

  return result;
}

export function createProductComparison(
  response: CompareProductsResponse
): CompareTwoItemsResult {
  const compareData: CompareResultDataItem[] =
    response.compareData?.map((item) => {
      const {
        groupingOption,
        productOneMeasureCount,
        productTwoMeasureCount,
        ntmCode,
        countryDataList,
        ...data
      } = item;

      return {
        ...data,
        ...countryDataList?.reduce<Record<string, number>>(
          (acc, curr) => ({
            ...acc,
            [curr.countryId]: curr.count,
          }),
          {}
        ),
        countryDataList: countryDataList || [],
        groupOption: groupingOption,
        measureCount1: productOneMeasureCount,
        measureCount2: productTwoMeasureCount,
        ntmCode: getStandardMeasureCode(item.ntmCode),
      };
    }) || [];

  const measureGroupsGroupedByGroupingOption = groupBy(
    compareData,
    'groupOption'
  );

  const {
    productOneTotalMeasures,
    productTwoTotalMeasures,
    productOneCode,
    productTwoCode,
  } = response;

  const result = {
    totalMeasures1: productOneTotalMeasures,
    totalMeasures2: productTwoTotalMeasures,
    displayName1: [response.productOneCode, response.productOneName]
      .filter(Boolean)
      .join(' - '),
    displayName2: [response.productTwoCode, response.productTwoName]
      .filter(Boolean)
      .join(' - '),
    productCode1: productOneCode,
    productCode2: productTwoCode,
    compareDataByGroupingOption: Object.entries(
      measureGroupsGroupedByGroupingOption
    ).reduce(
      (acc, [measureFilterType, items]) => ({
        ...acc,
        [measureFilterType]: createProductComparisonByGroupingOption(items),
      }),
      {} as Record<
        MeasureFilterType,
        CompareTwoItemsDataGroupedByMeasureFilterType
      >
    ),
  };

  return result;
}
