import clsx from 'clsx';
import Card from 'components/Card';
import { useGetSelfAssessSearchParams } from 'hooks/useGetSelfAssessParams';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CreateProfileForm from '../CreateProfileForm';
import styles from './SelfAssessFrontPageCreateProfile.module.scss';

type Props = {
  className?: string;
};

const SelfAssessFrontPageCreateProfile = ({ className }: Props) => {
  const searchParams = useGetSelfAssessSearchParams();

  return (
    <Card className={clsx(styles.card)} wrapperClassName={className}>
      <h2 className={styles.title}>
        <FormattedMessage
          id="selfAssess.message.createProfile"
          defaultMessage="Create a new assessment profile"
        />
      </h2>
      <CreateProfileForm canSave defaultValue={searchParams} />
    </Card>
  );
};

export default SelfAssessFrontPageCreateProfile;
