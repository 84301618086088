import Compare from 'modules/compare/Compare';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

const ComparePage = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet
        title={intl.formatMessage({
          id: 'application.title.compare',
          defaultMessage: 'Compare',
        })}
      />
      <Compare />
    </>
  );
};

export default ComparePage;
